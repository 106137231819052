import THIRD_PARTY_API from "axios";
import {
  assign,
  capitalize,
  filter,
  find,
  forEach,
  forIn, get,
  has, intersection,
  isArray,
  isEmpty as isLodashEmpty, isEqual, isNil,
  isString,
  keys,
  map,
  reduce,
  set,
  some, union,
  uniq,
  unset,
  uniqBy,
  lowerFirst,
  values,
  upperFirst,
  findKey,
  cloneDeep,
  sortBy,
  sumBy,
  remove,
  omitBy,
  replace,
  compact
} from "lodash";
import moment from "moment";
import React from "react";
import { ToastType, toast } from "react-toastify";
import { t } from "../../infrastructure/localization/localizationservice";
import { logError } from "../../infrastructure/logging/Logger";
import { API } from "../../infrastructure/network/HttpClient";
import {
  CONDITIONAL_SHOWHIDE_KEYS,
  CONTENT_TOKEN,
  DEFAULT_PROMO_CODE_MASTER_KEY,
  EntityFieldNameByCriteriaValue,
  FORM_INPUT_DEFAULT_PARAMETERS,
  FORM_INPUT_UI,
  FORM_INPUT_VALUE_FORMAT,
  FORM_LAYOUT_PARAMETERS_KEYS,
  HARD_CODED_FIELD_KEYS,
  MasterDataCollectionByCriteriaValue,
  MOBILE_VALIDATE_LAST_TEN_DIGIT,
  REQUEST_TYPE,
  STEP_NAVIGATION_TYPE,
  STORAGE_KEY,
  STORAGE_TYPE,
  VALIDATION_MESSAGE_TOKEN,
  MASTER_KEYS,
  META_DATA_TYPE,
  META_DATA_INDEXED_VALUE_TYPE,
  FEES_TYPE,
  PAYMENT_SUCCESS_STATUS,
  EXCEPTIONAL_DATACOLLECTION_MAPPING,
  DEFAULT_CURRENCY,
  PROMOCODE_DISCOUNT_TYPE,
  ENTITY_FIELD_COLLECTION,
  TOKEN_MAPPING_WITH_MULTI_SELECT_FIELD_DATA_KEY,
  TOKEN_VALUES_SEPEARTOR,
  TOKEN_MAPPING_WITH_MASKING_FIELD_DATA_KEY,
  PAYMENT_RECEIPT_PATH,
  BLOB_URL,
  TOKEN_MAPPING_WITH_SINGLE_SELECT_FIELD_DATA_KEY,
  BLOB_FOLDERS,
  DEFAULT_OPEN_APPLICATION_BTN_TEXT,
  PAGES,
  OPEN_APPLICATION_STEP_POSITION,
  REGEX,
  MOVE_LEFT_RIGHT_ANIMATION_TIME,
  SORT_ORDER,
  MANDATORY_PAYMENT_PARAM_KEYS,
  ACCESS_TOKEN_CLAIMS,
  DEFAULT_COUNTRY_CODE,
  MOBILE_REGEX_COUNTRY_WISE,
  apiKey1s,
  apiKey1Name,
  apiKey2Name,
  DB_KEY_IDS,
  DATA_COLLECTION_NAMES,
  EMAIL_AND_MOBILE_VERIFICATION_ERRORS
} from "./Constants";
import { LeadObjectManager } from "./LeadObjectManager";
import { OfflineStorage } from "./OfflineStorage";
import NotVerifiedIcon from "../../shared/icons/exclamation_red.svg";
import VerifiedIcon from "../../shared/icons/verified_green.svg";
import EditIcon from "../../shared/icons/edit_grey.svg";
import Axios from "axios";
import eeAzureLogs from '../../shared/services/eeAzureLogs'
import { updateFormConfig } from "../../pages/form/Actions";
import { updateCurrentStep } from "../services/appData/Actions";
import DefaultAvatarImage from "../../shared/icons/default-user-avatar.svg"
import CryptoJS from 'crypto-js';
window.moment = moment;
export const leadObjectManager = new LeadObjectManager();
export const eeCustomAzureLogs = new eeAzureLogs()
//leadObjectManager.getValue("application.keyValueAttributes", { keyId: 5 });
var jwtDecode = require("jwt-decode");

export function isAccessTokenValid(accessToken) {
  if (accessToken) {
    let decodedToken = jwtDecode(accessToken);
    let currentTime = Date.now();
    let expTime = parseInt(decodedToken.exp + "000");
    let diff = currentTime - expTime;
    if (diff >= -120000) return false;
    else return true;
  }
}
//get offline storage client info saved in JSON format
export function getClientInfomation(key, isPersistant) {
  let oData = JSON.parse(
    OfflineStorage.getItem(STORAGE_KEY.CLIENT_INFO, isPersistant)
  );
  return oData && oData[key] !== undefined ? oData[key] : null;
}

/**@description get client configured to store in reducer */
export function getClientConfiguration() {
  return window.extraaedge &&
    window.extraaedge.client &&
    window.extraaedge.client.Info
    ? window.extraaedge.client.Info
    : null;
}
/**
 * @param {string} source - Source string which will have token text
 * @param {Object} tokens - array of objects where each object have find and replace value
 * @example tokens [{find:"$NAME$",replace:"John"}, {find:"$EMAIL$,replace:'john@mydomain.com'}]
 * @returns {string} output - String with replace all token with actual values
 */
export function findAndReplaceInString(source, tokens) {
  if (tokens && tokens.length === 0) return source;
  tokens.map((item) => {
    return (source = source.split(item.find).join(item.replace));
  });
  return source;
}
/**
 *
 * @param {string} param {pattern:"^([a-zA-Z ]+)$",val:"Hellow"}
 */
export function isRegExValidate(param) {
  return new RegExp(param.pattern).test(param.val);
}
export function isEmpty(str) {
  return (
    isNil(str) ||
    (Array.isArray(str) && str.length === 0) ||
    (isString(str) && str.trim() === "")
  );
}
export function getStoreSettingByKey(key, masterData) {
  return masterData && (masterData[key] || masterData[lowerFirst(key)])
}
export function getMasterDataByKey(key, masterData,dataCollectionFilterOptions=null, returnAllMasterData = false, selectedValue = null) {
  let collection=masterData && (masterData[key] || masterData[lowerFirst(key)]);
  const EXCEPTIONAL_DATACOLLECTION_MAPPING_KEYS_VALUES = checkIfObject(EXCEPTIONAL_DATACOLLECTION_MAPPING) ? [...keys(EXCEPTIONAL_DATACOLLECTION_MAPPING), ...values(EXCEPTIONAL_DATACOLLECTION_MAPPING)] : [];
  if(
    !collection && checkIfArray(EXCEPTIONAL_DATACOLLECTION_MAPPING_KEYS_VALUES) && 
    (EXCEPTIONAL_DATACOLLECTION_MAPPING_KEYS_VALUES.indexOf(key)!==-1 || EXCEPTIONAL_DATACOLLECTION_MAPPING_KEYS_VALUES.indexOf(upperFirst(key))!==-1)
  ){
    const vals = values(EXCEPTIONAL_DATACOLLECTION_MAPPING);
    if(vals.indexOf(key)!==-1 || vals.indexOf(upperFirst(key))!==-1){
      const newkey = findKey(EXCEPTIONAL_DATACOLLECTION_MAPPING,(objectValue,objectKey)=>{
        return objectValue.toLowerCase()===key.toLowerCase();
      })
      if(!!newkey){
        key = newkey;
      }
    }
    collection=masterData[key] || masterData[lowerFirst(key)]; 
  }  
  if(!Array.isArray(collection)){
    return collection;
  }
  if (collection && !dataCollectionFilterOptions) {
    return returnAllMasterData ? collection : filter(collection, function (o) {
      /*return (
        ((o.isActive !== undefined && o.Active === true) || true) &&
        !o.isSystemValue
      );*/
      return (
        (isNil(o.isActive) || o.isActive === true) &&
        (isNil(o.isSystemValue) || o.isSystemValue === false)
      );
    });
  } else if(collection && !!dataCollectionFilterOptions) {
    let excludeIds=!!get(dataCollectionFilterOptions,`excludeIds`) && Array.isArray(dataCollectionFilterOptions.excludeIds) && dataCollectionFilterOptions.excludeIds.length>0?dataCollectionFilterOptions.excludeIds:null;

    const includeIds=!!get(dataCollectionFilterOptions,`includeIds`) && Array.isArray(dataCollectionFilterOptions.includeIds) && dataCollectionFilterOptions.includeIds.length>0?dataCollectionFilterOptions.includeIds:null;

    if(selectedValue && checkIfArray(excludeIds)){
      const updatedSelectedValue = Array.isArray(selectedValue) ? selectedValue : [selectedValue];
      excludeIds = excludeIds.filter(id => !updatedSelectedValue.includes(id));
    }
    /**
     * Date: 05th Jan 2022
     * Pradip Udavant
     * Case 1: If excludeIds will come [-1] as value then this function will return values only having in includeIds array
     * Case 2: If excludeIds will come with other ids then this will return values exclude mentioend ids from data collection
     */
    if(!!excludeIds && excludeIds.length===1 && excludeIds.includes(-1) && !!includeIds && includeIds.length>0){
      //case 1 logic
      return filter(collection, function (o) {
        return (
          (isNil(o.id) || includeIds.includes(o.id)) && 
          (isNil(o.isActive) || o.isActive === true) &&
          (isNil(o.isSystemValue) || o.isSystemValue === false || !isNil(selectedValue))
        );
      });
    }else if(!!excludeIds && (!excludeIds.includes(-1) || excludeIds.length>1)){
      //case 2 logic
      return filter(collection, function (o) {
        return (
          (isNil(o.id) || !excludeIds.includes(o.id)) && 
          (isNil(o.isActive) || o.isActive === true) &&
          (isNil(o.isSystemValue) || o.isSystemValue === false || !isNil(selectedValue))
        );
      });
    }
  } else {
    //logError("Master collection not found for: ", key);
    return null;
  }
}

export function getNameById(
  id,
  collection,
  keyName = "name",
  fallbackKeyName = "name",
  joinBy
) {
  let name = [];
  if (
    !!id &&
    collection &&
    Array.isArray(collection) &&
    collection.length > 0
  ) {
    if (checkIfArray(id)) {
      id.forEach((idEle) => {
        let item = find(collection, { id: idEle });
        if (item) {
          name.push(
            !!item[keyName]
              ? item[keyName]
              : !!item[fallbackKeyName]
              ? item[fallbackKeyName]
              : name
          );
        }
      });
    } else {
      let item = find(collection, { id });
      if (item) {
        name.push(
          !!item[keyName]
            ? item[keyName]
            : !!item[fallbackKeyName]
            ? item[fallbackKeyName]
            : name
        );
      }
    }
  }
  return name.join(joinBy || ",");
}
/**
 * Validate input by regex pattern
 * @param {*} val
 * @param {*} type
 * @param {*} pattern
 */
export function validateByType(val, type, pattern) {
  switch (type) {
    case FORM_INPUT_UI.DATE: {
      // let _val = val;
      return true;
    }
    case FORM_INPUT_UI.EMAIL:
      return isRegExValidate({ pattern: pattern, val: val });
    case FORM_INPUT_UI.MOBILE:
      return isRegExValidate({ pattern: pattern, val: val });
    case FORM_INPUT_UI.NUMBER: {
      // let _val = val;
      if (!isEmpty(pattern) && !isEmpty(val)) {
        return isRegExValidate({ pattern: pattern, val: val });
      }
      return true;
    }
    case FORM_INPUT_UI.PASSWORD: {
      return isRegExValidate({ pattern: pattern, val: val });
    }
    case FORM_INPUT_UI.TEL: {
      // let _val = val;
      return true;
    }
    case FORM_INPUT_UI.TEXT: {
      // let _val = val;
      if (!isEmpty(pattern) && !isEmpty(val)) {
        return isRegExValidate({ pattern: pattern, val: val });
      }
      return true;
    }
    case FORM_INPUT_UI.TEXTAREA: {
      // let _val = val;
      return true;
    }
    case FORM_INPUT_UI.URL: {
      // let _val = val;
      return isRegExValidate({ pattern: pattern, val: val });
    }
    case FORM_INPUT_UI.INPUT_INSTALLMENTAMOUNT: {
      // let _val = val;
      return isRegExValidate({ pattern: pattern, val: val });
    }
    case FORM_INPUT_UI.WEEK: {
      // let _val = val;
      return true;
    }
    default: {
      return false;
    }
  }
}
/**
 *
 * @param {*} val
 * @param {*} type
 * @param {*} validationRules
 */
export function validateByRule(val, type, validationRules) {
  switch (type) {
    case FORM_INPUT_UI.DATE:
      return true;
    case FORM_INPUT_UI.EMAIL:
      return true;
    case FORM_INPUT_UI.MOBILE:
      return true;
    case FORM_INPUT_UI.NUMBER: {
      // let _val = val;
      if (!isNil(val) && !isEmpty(val) && !isNil(validationRules)) {
        if (
          !isNil(validationRules.min) &&
          !isEmpty(validationRules.min) &&
          !isNil(validationRules.max) &&
          !isEmpty(validationRules.max)
        ) {
          return (
            parseFloat(val) <= parseFloat(validationRules.max) &&
            parseFloat(val) >= parseFloat(validationRules.min)
          );
        } else if (
          !isNil(validationRules.min) &&
          !isEmpty(validationRules.min)
        ) {
          return parseFloat(val) >= parseFloat(validationRules.min);
        } else if (
          !isNil(validationRules.max) &&
          !isEmpty(validationRules.max)
        ) {
          return parseFloat(val) <= parseFloat(validationRules.max);
        }
      }
      return true;
    }
    case FORM_INPUT_UI.PASSWORD:
      return true;
    case FORM_INPUT_UI.TEL:
      return true;
    case FORM_INPUT_UI.TEXT:
      return true;
    case FORM_INPUT_UI.TEXTAREA:
      return true;
    case FORM_INPUT_UI.URL:
      return true;
    case FORM_INPUT_UI.WEEK:
      return true;
    default:
      return false;
  }
}

/**
 * Validate input by for unique value within same input type
 * @param {*} val
 * @param {*} type
 * @param {*} pattern
 */
export function validateForUniqueValue(
  val,
  type,
  pattern,
  isUniqueValueCheckEnabled,
  formData,
  uniqueFieldByInputType,
  userDetail,
  key
) {
  let validateResult = null;
  switch (type) {
    case FORM_INPUT_UI.DATE: {
      return validateResult;
    }
    case FORM_INPUT_UI.EMAIL:
      if (
        !isNil(val) &&
        !isLodashEmpty(val) &&
        !isNil(isUniqueValueCheckEnabled) &&
        isUniqueValueCheckEnabled &&
        !isNil(formData) &&
        !isNil(uniqueFieldByInputType) &&
        !isNil(get(uniqueFieldByInputType, type)) &&
        Array.isArray(uniqueFieldByInputType[type]) &&
        uniqueFieldByInputType[type].length > 0
      ) {
        //hard code to check for primary email mobile entered at registration page
        const primaryEmail = key !== "email" && get(userDetail, `user.email`);
        let duplicateValueFieldTitle =
          !isNil(primaryEmail) && !isLodashEmpty(primaryEmail)
            ? val.toLowerCase() === primaryEmail.toLowerCase()
              ? "form.fields.primaryEmailAddress.title"
              : ""
            : "";
        !duplicateValueFieldTitle &&
          uniqueFieldByInputType[type].some((item) => {
            const { fieldKey, title } = item;
            const isSameValueFound =
              !isNil(key) &&
              !isLodashEmpty(key) &&
              key.toLowerCase() !== fieldKey.toLowerCase() &&
              !isNil(get(formData, fieldKey)) &&
              !isLodashEmpty(get(formData, fieldKey)) &&
              val.toLowerCase() === get(formData, fieldKey).toLowerCase();
            if (isSameValueFound) duplicateValueFieldTitle = title;
            return isSameValueFound;
          });
        if (!!duplicateValueFieldTitle) {
          validateResult = {
            duplicateValueFieldName: getLang(duplicateValueFieldTitle),
          };
        }
      }
      return validateResult;
    case FORM_INPUT_UI.MOBILE:
    case FORM_INPUT_UI.MOBILE2:
      let inputMobile =
        !isNil(val) && !isLodashEmpty(val)
          ? MOBILE_VALIDATE_LAST_TEN_DIGIT
            ? val.substr(-10)
            : val
          : val;
      if (
        !isNil(inputMobile) &&
        !isLodashEmpty(inputMobile) &&
        !isNil(isUniqueValueCheckEnabled) &&
        isUniqueValueCheckEnabled &&
        !isNil(formData) &&
        !isNil(uniqueFieldByInputType) &&
        !isNil(get(uniqueFieldByInputType, type)) &&
        Array.isArray(uniqueFieldByInputType[type]) &&
        uniqueFieldByInputType[type].length > 0
      ) {
        //hard code to check for primary email mobile entered at registration page
        const primaryMobileNumber = key !== "mobileNumber" && (!!get(userDetail, `user.mobileNumber`)
          ? MOBILE_VALIDATE_LAST_TEN_DIGIT
            ? get(userDetail, `user.mobileNumber`).substr(-10)
            : get(userDetail, `user.mobileNumber`)
          : get(userDetail, `user.mobileNumber`));
        let duplicateValueFieldTitle =
          !isNil(primaryMobileNumber) && !isLodashEmpty(primaryMobileNumber)
            ? inputMobile.toLowerCase() === primaryMobileNumber.toLowerCase()
              ? "form.fields.primaryMobileNumber.title"
              : ""
            : "";
        !duplicateValueFieldTitle &&
          uniqueFieldByInputType[type].some((item) => {
            const { fieldKey, title } = item;

            let fieldValue =
              !isNil(get(formData, fieldKey)) &&
              !isLodashEmpty(get(formData, fieldKey))
                ? MOBILE_VALIDATE_LAST_TEN_DIGIT
                  ? get(formData, fieldKey).substr(-10)
                  : get(formData, fieldKey)
                : get(formData, fieldKey);
            const isSameValueFound =
              !isNil(key) &&
              !isLodashEmpty(key) &&
              key.toLowerCase() !== fieldKey.toLowerCase() &&
              !isNil(fieldValue) &&
              !isLodashEmpty(fieldValue) &&
              inputMobile.toLowerCase() === fieldValue.toLowerCase();
            if (isSameValueFound) duplicateValueFieldTitle = title;
            return isSameValueFound;
          });
        if (!!duplicateValueFieldTitle) {
          validateResult = {
            duplicateValueFieldName: getLang(duplicateValueFieldTitle),
          };
        }
      }
      return validateResult;
    case FORM_INPUT_UI.NUMBER: {
      return validateResult;
    }
    case FORM_INPUT_UI.PASSWORD: {
      return validateResult;
    }
    case FORM_INPUT_UI.TEL: {
      return validateResult;
    }
    case FORM_INPUT_UI.TEXT: {
      return validateResult;
    }
    case FORM_INPUT_UI.TEXTAREA: {
      return validateResult;
    }
    case FORM_INPUT_UI.URL: {
      return validateResult;
    }
    case FORM_INPUT_UI.WEEK: {
      return validateResult;
    }
    default: {
      return validateResult;
    }
  }
}
/**
 * @description Check file size in kb is valid or not
 * @param {*} fdata
 * @param {*} maxSize
 */
export function fileSizeValidate(fdata, maxSize) {
  var fsize = fdata.size / 1024;
  if (fsize > maxSize) {
    //alert("Maximum file size exceed, This file size is: " + fsize + "KB");
    return false;
  } else {
    return true;
  }
}

/**
 * @description Check file name characters length is valid or not
 * @param {*} fileNameWithoutExtension
 * @param {*} fileNameMaxLength
 */
export function fileNameLengthValidate(
  fileNameWithoutExtension,
  fileNameMaxLength
) {
  if (!isEmpty(fileNameWithoutExtension) && !isEmpty(fileNameMaxLength)) {
    return fileNameWithoutExtension.length <= fileNameMaxLength;
  }
  return true;
}
/**
 * Check input config and add div for move input field at new row
 */
export function ConditionalLayoutToMoveInputAtNewRow(params) {
  let moveToNewRow =
    params &&
    params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES] &&
    params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
      FORM_LAYOUT_PARAMETERS_KEYS.MOVE_TO_NEW_ROW
    ]
      ? params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
          FORM_LAYOUT_PARAMETERS_KEYS.MOVE_TO_NEW_ROW
        ] !== undefined
        ? params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
            FORM_LAYOUT_PARAMETERS_KEYS.MOVE_TO_NEW_ROW
          ]
        : FORM_INPUT_DEFAULT_PARAMETERS[
            FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES
          ][FORM_LAYOUT_PARAMETERS_KEYS.MOVE_TO_NEW_ROW]
      : false;
  return (
    <div
      key={params.name + "2"}
      className={moveToNewRow ? "w-100" : "d-none"}
    />
  );
}
/**
 * Check input col width from form config
 */
export function getColWidth(params, formConfig) {
  //get block width form contants
  let blockWidth =
    FORM_INPUT_DEFAULT_PARAMETERS[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
      FORM_LAYOUT_PARAMETERS_KEYS.WIDTH
    ];
  //check and get if exist block width in provided form config object
  blockWidth =
    formConfig &&
    formConfig.defaultProps &&
    formConfig.defaultProps.fields &&
    formConfig.defaultProps.fields[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES] &&
    !!formConfig.defaultProps.fields[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
      FORM_LAYOUT_PARAMETERS_KEYS.WIDTH
    ]
      ? formConfig.defaultProps.fields[
          FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES
        ][FORM_LAYOUT_PARAMETERS_KEYS.WIDTH]
      : blockWidth;
  //check and get if exist block width in provided params object
  blockWidth =
    params &&
    params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES] &&
    !!params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
      FORM_LAYOUT_PARAMETERS_KEYS.WIDTH
    ]
      ? params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
          FORM_LAYOUT_PARAMETERS_KEYS.WIDTH
        ]
      : blockWidth;
  let offset =
    params &&
    params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES] &&
    !!params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
      FORM_LAYOUT_PARAMETERS_KEYS.OFFSET
    ]
      ? params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
          FORM_LAYOUT_PARAMETERS_KEYS.OFFSET
        ]
      : false;
  return !offset
    ? FORM_LAYOUT_PARAMETERS_KEYS[blockWidth]
    : {
        size: FORM_LAYOUT_PARAMETERS_KEYS[blockWidth],
        [FORM_LAYOUT_PARAMETERS_KEYS.OFFSET]: offset,
      };
}
/**
 * check each steps to add key to specific fields 
 */
export function updateKeyToFields(actionItem, configs, addKeys, placeKey){
  const { steps } = configs || {};
  checkIfArray(steps) && steps.map(step => {
    const { panels } = step?.config || {};
    if(checkIfObject(panels)){
      for (const [keyPanel, valuePanel] of Object.entries(panels)) {
        const { fields, visible } = valuePanel || {};
        const fieldsKey = Object.keys(fields);
        visible && checkIfArray(addKeys) && addKeys.map(addKey => {
          if(fieldsKey.includes(addKey) && fields[addKey].visible){
            if(actionItem === "add"){
              if(placeKey === "conditionShow"){
                fields[addKey][placeKey] = true;
              }else{
                !panels[keyPanel][placeKey].includes(addKey) && panels[keyPanel][placeKey].push(addKey);
              }
            }else{
              if(placeKey === "conditionShow"){
                fields[addKey][placeKey] = false;
              }else{
                if(panels[keyPanel][placeKey] && Array.isArray(panels[keyPanel][placeKey]) && panels[keyPanel][placeKey].includes(addKey)){
                  panels[keyPanel][placeKey] = panels[keyPanel][placeKey].filter((item) => {
                    return item !== addKey;
                  });
                }
              }
            }
          }
        })
      }
    }
  })
}
/**
 * update visibility component as mentined in "ref" in form config
 */
export function updateDependenciesRefComponents(
  params,
  value,
  currentStepData,
  isRecursiveFormConfigChanged,
  conditionallyRemovedFields,
  updatedConfig={}
) {
  conditionallyRemovedFields =
    !!conditionallyRemovedFields &&
    Object.keys(conditionallyRemovedFields).length > 0
      ? conditionallyRemovedFields
      : {};

  let isFormConfigChanged = false;
  let isConditionalTrue = false;
  //console.log(`params`,params);
  let globalRef = [{ ref: params.ref }];
  if (!isNil(params.trueCondition) && params.trueCondition === value) {
    isConditionalTrue = true;
    globalRef[0].isConditionalTrue = true;
  }
  if (
    isNil(params.trueCondition) &&
    (params.type === FORM_INPUT_UI.TEXT ||
      params.type === FORM_INPUT_UI.FILE ||
      params.type === FORM_INPUT_UI.HIDDEN ||
      params.type === FORM_INPUT_UI.URL
    ) &&
    !!value
  ) {
      isConditionalTrue = true;
      globalRef[0].isConditionalTrue = true;
  } else if (
    isNil(params.trueCondition) &&
    params.type === FORM_INPUT_UI.SELECT &&
    !isNil(value)
  ) {
    //condition for dropdown - to show specific field(s) on any selected value
    if (
      !isNil(get(params, `ref.conditional`)) &&
      Array.isArray(params.ref.conditional) &&
      params.ref.conditional.length > 0
    ) {
      forEach(params.ref.conditional, function (item) {
        if (
          !!item.onSelectIds &&
          Array.isArray(item.onSelectIds) &&
          item.onSelectIds.length > 0 &&
          intersection(item.onSelectIds, Array.isArray(value) ? value : [value])
            .length > 0 &&
          Object.keys(item).length > 1
        ) {
          globalRef.push({ ref: item, isConditionalTrue: true });
        } else {
          globalRef.push({ ref: item, isConditionalTrue: false });
        }
      });
    } else if (
      !isNil(get(params, `ref.onSelectIds`)) &&
      Array.isArray(params.ref.onSelectIds) &&
      params.ref.onSelectIds.length > 0 &&
      ((Array.isArray(value) && value.length > 0) || !isNil(value)) &&
      intersection(
        params.ref.onSelectIds,
        Array.isArray(value) ? value : [value]
      ).length > 0
    ) {
      //condition for dropdown - to show Other text field found true
      isConditionalTrue = true;
      globalRef[0].isConditionalTrue = true;
    } else {
      isConditionalTrue = false;
      globalRef[0].isConditionalTrue = false;
    }
    if(
      checkIfArray(get(params, `ref.conditional_gridActions`))
      ){
      params.ref.conditional_gridActions.some((calculationConfig) => {
       const {isActive, gridKey, calculation, showRows, mapping} = calculationConfig || {};
       if(isActive && checkIfArray(mapping)){
        mapping.forEach((mappingConfig) => {
          const {onSelectIds = [], ...mappingData} = mappingConfig || {};
          const gridRef = {
            ...mappingData,
            gridKey,
            calculation,
            showRows
          }
          if(intersection(
            onSelectIds,
            Array.isArray(value) ? value : value ? [value] : []).length > 0
          ){
            globalRef.push({ ref: gridRef, isConditionalTrue: true });
          }else{
            globalRef.push({ ref: gridRef, isConditionalTrue: false });
          }
        })
       }else{
        return true;
       }
      })
    }
  }
  checkIfArray(updatedConfig.steps) && updatedConfig.steps.forEach(stepConfig => {
  let stepData = stepConfig.config || {};
  stepData &&
    stepData.panels &&
    Object.keys(stepData.panels).length > 0 &&
    Object.keys(stepData.panels).map((panelKey) => {
      let panel = stepData.panels[panelKey];
      let hidePanels = filter(globalRef, (item) => {
        return (
          !isNil(get(item, `ref.hidePanels`)) &&
          Array.isArray(item.ref.hidePanels) &&
          item.ref.hidePanels.length > 0 &&
          item.ref.hidePanels.includes(panelKey)
        );
      });
      let showPanels = filter(globalRef, (item) => {
        return (
          !isNil(get(item, `ref.showPanels`)) &&
          Array.isArray(item.ref.showPanels) &&
          item.ref.showPanels.length > 0 &&
          item.ref.showPanels.includes(panelKey)
        );
      });
      if (stepData && hidePanels.length > 0) {
        panel["conditionShow"] = !some(hidePanels, { isConditionalTrue: true });
        //delete stepData.panels[panelKey];
        //stepData.panels[panelKey] = Object.assign({}, panel);
        isFormConfigChanged = true;
      }
      if (stepData && showPanels.length > 0) {
        panel["conditionShow"] = some(showPanels, { isConditionalTrue: true });
        //delete stepData.panels[panelKey];
       // stepData.panels[panelKey] = Object.assign({}, panel);
        isFormConfigChanged = true;
      }
      panel.fields &&
        Object.keys(panel.fields).length > 0 &&
        Object.keys(panel.fields).map((fieldkey) => {
          let field = panel.fields[fieldkey];
          let hideFields = filter(globalRef, (item) => {
            return (
              !isNil(get(item, `ref.hideFields`)) &&
              Array.isArray(item.ref.hideFields) &&
              item.ref.hideFields.length > 0 &&
              item.ref.hideFields.includes(fieldkey)
            );
          });
          let showFields = filter(globalRef, (item) => {
            return (
              !isNil(get(item, `ref.showFields`)) &&
              Array.isArray(item.ref.showFields) &&
              item.ref.showFields.length > 0 &&
              item.ref.showFields.includes(fieldkey)
            );
          });
          
          if (stepData && hideFields.length > 0) {
            field["conditionShow"] = !some(hideFields, {
              isConditionalTrue: true,
            });
            //delete panel.fields[fieldkey];
           // panel.fields[fieldkey] = Object.assign({}, field);
            some(hideFields, { isConditionalTrue: true }) &&
              set(conditionallyRemovedFields, fieldkey, null);
            isRecursiveFormConfigChanged =
              (some(hideFields, { isConditionalTrue: true }) &&
                !isNil(get(field, "ref")) &&
                intersection(CONDITIONAL_SHOWHIDE_KEYS, Object.keys(field.ref))
                  .length > 0 &&
                updateDependenciesRefComponents(
                  field,
                  null,
                  currentStepData,
                  isRecursiveFormConfigChanged,
                  conditionallyRemovedFields,
                  updatedConfig
                )) ||
              isRecursiveFormConfigChanged;
            isFormConfigChanged = true;
          }
          if (stepData && showFields.length > 0) {
            field["conditionShow"] = some(showFields, {
              isConditionalTrue: true,
            });
            //delete panel.fields[fieldkey];
            //panel.fields[fieldkey] = Object.assign({}, field);
            !some(showFields, { isConditionalTrue: true }) &&
              set(conditionallyRemovedFields, fieldkey, null);
            isRecursiveFormConfigChanged =
              (!some(showFields, { isConditionalTrue: true }) &&
                !isNil(get(field, "ref")) &&
                intersection(CONDITIONAL_SHOWHIDE_KEYS, Object.keys(field.ref))
                  .length > 0 &&
                updateDependenciesRefComponents(
                  field,
                  null,
                  currentStepData,
                  isRecursiveFormConfigChanged,
                  conditionallyRemovedFields,
                  updatedConfig
                )) ||
              isRecursiveFormConfigChanged;
            isFormConfigChanged = true;
          }
          if (
            stepData &&
            checkIfArray(params.dependencies)
          ) {
            if(params.dependencies.includes(fieldkey)){
              
              if (!!value) {
                if (panel["required"] === undefined)
                  panel["required"] = [fieldkey];
                else if (!panel["required"].includes(fieldkey)) {
                  panel["required"].push(fieldkey);
                  //panel = Object.assign({}, panel);
                }
              } else if (
                panel["required"] !== undefined &&
                panel["required"].includes(fieldkey)
              ) {
                panel["required"] = panel["required"].filter((item) => {
                  return item !== fieldkey;
                });
                //panel = Object.assign({}, panel);
              }
            }else{
              if(value && Array.isArray(value) ? value.length : value){
                //logic of dependencies for other steps written below
                updateKeyToFields("add", updatedConfig, params.dependencies, "required")
              }else{
                updateKeyToFields("remove", updatedConfig, params.dependencies, "required")
              }
            }
            isFormConfigChanged = true;
          }
          let conditionalGridActionsRef = checkIfArray(globalRef) && globalRef.filter((item) => {
            return (
              get(item, "ref.gridKey") === fieldkey
            );
          });
          if(checkIfArray(conditionalGridActionsRef)){
            const conditionalGridActions = checkIfArray(globalRef) && globalRef.find((item) => {
              return (
                (item.isConditionalTrue && 
                 get(item, "ref.gridKey") === fieldkey
                )
              );
            });
            const { gridLayout: { meta, pdfLayoutSetting: {printAllRow: {metaKey: printAllRowMetaKey} = {}} = {} } = {}, gridLayout } = field;
            if(checkIfObject(conditionalGridActions)){
              const conditionalGridActionsRef = conditionalGridActions.ref || {}; 
              const { calculation, calculateOnRowIds, requiredGridRowIds, showRows, showRowMetaKeys, filterOptionsOnMetaKeys } = conditionalGridActionsRef;
              if(checkIfObject(calculation) && checkIfArray(meta) && checkIfArray(calculateOnRowIds)){
                Object.keys(calculation).some((calculationMetaKey) => {
                    meta.some((gridColmunConfig, index) => {
                    const {metaKey , ref: {conditional_mapping = []} = {}} = gridColmunConfig;
                    let updatedGridColmunConfig = gridColmunConfig;
                    if(calculationMetaKey === metaKey){
                      const updatedCalculationConfig = {
                        ...(calculation[calculationMetaKey] || {}),
                        calculateOnRowIds,
                        calculationMetaKey,
                        showRowMetaKeys
                      }
                      if(checkIfArray(conditional_mapping)){
                        updatedGridColmunConfig = {
                          ...updatedGridColmunConfig,
                          ref: {
                            ...(updatedGridColmunConfig.ref || {}),
                            conditional_mapping: [
                              ...updatedGridColmunConfig.ref.conditional_mapping, updatedCalculationConfig
                            ]
                          }
                        }
                      }else{
                        updatedGridColmunConfig = {
                          ...updatedGridColmunConfig,
                          ref: {
                            ...(updatedGridColmunConfig.ref || {}),
                            conditional_mapping: [updatedCalculationConfig]
                          }
                        }
                      }
                      meta[index] = updatedGridColmunConfig;
                      return true
                    }
                  })
                })
              }
              if(checkIfObject(showRows) && checkIfArray(showRowMetaKeys)){
                showRowMetaKeys.forEach((showRowMetaKey) => {
                  const rowConfig =  get(showRows, `${showRowMetaKey}`)
                  if(checkIfObject(rowConfig)){
                    const metaConfigsWithoutPrintAllKey = meta.filter(({metaKey}) => metaKey !== printAllRowMetaKey)
                    gridLayout.conditional_rows = {
                      ...(gridLayout.conditional_rows || {}),
                      [showRowMetaKey]: [
                        rowConfig,
                        ...metaConfigsWithoutPrintAllKey
                      ]
                    }
                    if(requiredGridRowIds.includes(0) &&  rowConfig.isRowRequired){
                      gridLayout.conditional_requiredShowRowMetakeys = [
                        ...(gridLayout.conditional_requiredShowRowMetakeys || []),
                        showRowMetaKey
                      ]
                    }
                  }
                })
              }else if(checkIfObject(showRows) && checkIfObject(gridLayout?.conditional_rows)){
                delete gridLayout.conditional_rows;
                delete gridLayout.conditional_requiredShowRowMetakeys;
              }
              if(checkIfObject(filterOptionsOnMetaKeys) && checkIfObject(gridLayout)){
                gridLayout.conditionalFilterOptions = filterOptionsOnMetaKeys;
              }else{
                delete gridLayout.conditionalFilterOptions;
              }
              field.gridLayout = {
                ...field.gridLayout,
                conditionalRequiredGridRowIds: conditionalGridActions.isConditionalTrue ? requiredGridRowIds : []
              }
              field["ref"] = conditionalGridActionsRef;
              isFormConfigChanged = true;
            }else{
              const conditionalFalseGridActions = checkIfArray(globalRef) && globalRef.find((item) => {
                return (
                  (!item.isConditionalTrue && 
                   get(item, "ref.gridKey") === fieldkey
                  )
                );
              });
              const {calculation, showRows, filterOptionsOnMetaKeys} = conditionalFalseGridActions?.ref || {};
              checkIfObject(calculation) && Object.keys(calculation).some((calculationMetaKey) => {
                meta.some((gridColmunConfig, index) => {
                  let updatedGridColmunConfig = gridColmunConfig;
                  if(calculationMetaKey === gridColmunConfig.metaKey){
                    const updatedCalculationConfig = {
                      ...(calculation[calculationMetaKey] || {}),
                      calculationMetaKey
                    }
                    updatedGridColmunConfig = {
                      ...updatedGridColmunConfig,
                      ref: {
                        ...(updatedGridColmunConfig.ref || {}),
                        conditional_mapping: [updatedCalculationConfig]
                      }
                    }
                    meta[index] = updatedGridColmunConfig;
                    return true;
                  }
                })
              })
              if(checkIfObject(showRows) && checkIfObject(gridLayout?.conditional_rows)){
                delete gridLayout.conditional_rows;
              }
              if(checkIfObject(filterOptionsOnMetaKeys) && checkIfObject(gridLayout?.conditionalFilterOptions)){
                delete gridLayout.conditionalFilterOptions;
              }
              field.gridLayout = {
                ...field.gridLayout,
                conditionalRequiredGridRowIds: []
              }
              field["ref"] = {};
              isFormConfigChanged = true;
            }
          }
          return true;
        });
      return true;
    });
  });
  return { isFormConfigChanged, conditionallyRemovedFields };
}
export function updatefieldsRefConditions(steps, currentStep, formData, formConfig) {
  let updatedConfig = {}, refDependencyFields = {}, refShowFields = [], refHideFields = [], currentStepFields = [];
  if(checkIfArray(steps) && currentStep && checkIfObject(formData)){
    const currentStepData = steps[currentStep - 1];
    const panels = currentStepData?.config?.panels;
    if(checkIfObject(panels)){
      const panelValue = Object.values(panels);
      if(checkIfArray(panelValue)){
        panelValue.forEach(panel => {
          const allField = panel?.fields || {};
          for (const [fieldKey, fieldValue] of Object.entries(allField)) {
            if(fieldValue?.visible){
              currentStepFields.push(fieldKey);
            }
          }
        })
      }
    }
    steps.forEach(step => {
      const panels = step?.config?.panels;
      if(checkIfObject(panels)){
        const panelValue = Object.values(panels);
        if(checkIfArray(panelValue)){
          panelValue.forEach(panel => {
            const allField = panel?.fields || {};
            for (const [fieldKey, fieldValue] of Object.entries(allField)) {
              if(fieldValue?.visible){
                const val = formData[fieldKey] && (typeof formData[fieldKey] === 'string' || formData[fieldKey] instanceof String) ? 
                formData[fieldKey] : Number(formData[fieldKey]);
                //fetch conditional keys
                if(checkIfArray(fieldValue?.ref?.conditional)){
                  fieldValue.ref.conditional.forEach(ele => {
                    if(checkIfArray(ele?.showFields) && ele.onSelectIds.includes(val)){
                      ele.showFields.forEach(field => {
                        if(currentStepFields.includes(field)){
                          refShowFields = [...(refShowFields || []), field]
                        }
                      })
                    }
                  })
                }
                //fetch dependency keys
                if(checkIfArray(fieldValue?.dependencies)){
                  fieldValue.dependencies.forEach(dependency => {
                    const trueValue = fieldValue.trueCondition ? fieldValue.trueCondition === val : val;
                    if(currentStepFields.includes(dependency) && trueValue){
                      refDependencyFields[dependency] = [...(refDependencyFields[dependency] || []), fieldKey]
                    }
                  })
                }
                //fetch showFields keys
                if(checkIfArray(fieldValue?.ref?.showFields)){
                  if(fieldValue?.ref?.onSelectIds ? fieldValue.ref.onSelectIds.includes(val) : fieldValue.trueCondition ? fieldValue.trueCondition === val : val){
                    fieldValue.ref.showFields.forEach(field => {
                      if(currentStepFields.includes(field)){
                        refShowFields = [...(refShowFields || []), field]
                      }
                    })
                  }else{
                    fieldValue.ref.showFields.forEach(field => {
                      if(currentStepFields.includes(field)){
                        refHideFields= [...(refHideFields || []), field]
                      }
                    })
                  }
                }
              }
            }
          })
        }
      }
    })
    updatedConfig = formConfig;
    //dependencies logic
    let updatedFlag = false;
    if(checkIfObject(refDependencyFields)){
      for (const [dependentKey, dependenyValue] of Object.entries(refDependencyFields)) {
        if(checkIfArray(dependenyValue)){
          dependenyValue.forEach(dependentVal => {
            const value = formData[dependentVal];
            updatedFlag = true;
            if(value && Array.isArray(value) ? value.length : value){
              updateKeyToFields("add", updatedConfig, [dependentKey], "required");
            }else{
              updateKeyToFields("remove", updatedConfig, [dependentKey], "required")
            }
          })
        }
      }
    }
    //hideFields logic
    if(checkIfArray(refHideFields)){
      updatedFlag = true;
      updateKeyToFields("remove", updatedConfig, refHideFields, "conditionShow");
    }
    if(checkIfArray(refShowFields)){
      updatedFlag = true;
      updateKeyToFields("add", updatedConfig, refShowFields, "conditionShow");
    }
    //update final config logic
    if(checkIfObject(updatedConfig) && updatedFlag){
      updateFormConfig(updatedConfig)
    }
  }
  
}
/**
 * Check checkbox or radio list need to show inline or list format from form config
 */
export function getOptionListLayout(params, formConfig) {
  //get block width form contants
  let isIinelist =
    FORM_INPUT_DEFAULT_PARAMETERS[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
      FORM_LAYOUT_PARAMETERS_KEYS.INLINE_LIST
    ];
  //check and get if exist block width in provided form config object
  isIinelist =
    formConfig &&
    formConfig.defaultProps &&
    formConfig.defaultProps.fields &&
    formConfig.defaultProps.fields[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES] &&
    !!formConfig.defaultProps.fields[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
      FORM_LAYOUT_PARAMETERS_KEYS.INLINE_LIST
    ]
      ? formConfig.defaultProps.fields[
          FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES
        ][FORM_LAYOUT_PARAMETERS_KEYS.INLINE_LIST]
      : isIinelist;
  //check and get if exist block width in provided params object
  isIinelist =
    params &&
    params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES] &&
    !!params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
      FORM_LAYOUT_PARAMETERS_KEYS.INLINE_LIST
    ]
      ? params[FORM_LAYOUT_PARAMETERS_KEYS.LAYOUT_RULES][
          FORM_LAYOUT_PARAMETERS_KEYS.INLINE_LIST
        ]
      : isIinelist;

  return isIinelist;
}
/**
 * @description - Check language text is present for provided key and return TRUE or FALSE
 */
export function isLangExist(lnKey) {
  return !!lnKey && t(lnKey) !== lnKey;
}
/**
 * @description - get language text
 */
export function getLang(lnKey,defaultValue="", options) {
  return !!lnKey ? t(lnKey,!!defaultValue?defaultValue:lnKey, options) : defaultValue;
}
/**@description get master data collection value by id, we have maintained all master data will come with id and value format */
export function getValueById(id, collection) {
  let arr =
    collection && isArray(collection) && collection.length > 0
      ? collection
      : null;
  if (arr) {
    for (let i = 0; i < arr.length; i++) {
      if (!!arr[i].id && arr[i].id === id) {
        return !!arr[i].label ? arr[i].label : "";
      }
    }
  }
  return null;
}
/**
 * @description - Replace token with dynamic value {sourceStr: getLang(VALIDATION_ERROR.REQUIRED.errorMessage), name: getLang(fields[key].title), value: userInputvalue}
 */
export function replaceToken(params) {
  //property of params are expected sourceStr, name, value;
  if (
    params &&
    params.sourceStr &&
    VALIDATION_MESSAGE_TOKEN &&
    !isNil(params.name) &&
    params.sourceStr.indexOf(VALIDATION_MESSAGE_TOKEN.FIELD_NAME) !== -1
  ) {
    params.sourceStr = findAndReplaceInString(params.sourceStr, [
      {
        find: VALIDATION_MESSAGE_TOKEN.FIELD_NAME,
        replace: getLang(params.name),
      },
    ]);
  }
  if (
    params &&
    params.sourceStr &&
    VALIDATION_MESSAGE_TOKEN &&
    !isNil(params.value) &&
    params.sourceStr.indexOf(VALIDATION_MESSAGE_TOKEN.FIELD_VALUE) !== -1
  ) {
    params.sourceStr = findAndReplaceInString(params.sourceStr, [
      { find: VALIDATION_MESSAGE_TOKEN.FIELD_VALUE, replace: params.value },
    ]);
  }
  return params.sourceStr && params.sourceStr;
}
export function updateFormDataFromUserInfo(formConfig, leadObjectHandler = leadObjectManager) {
  let userFormData = {};
  forEach(get(formConfig, "steps"), function (step, stepKey) {
    forEach(get(step, "config.panels"), function (panel, panelKey) {
      forEach(get(panel, "fields"), function (field, fieldKey) {
        let storedValue = "";
        if (field.db_key) {
          storedValue = leadObjectHandler.getInputValue(
            fieldKey,
            field.db_key,
            field.db_key_id,
            field
          );
          if (!isEmpty(storedValue)) {
            storedValue =
              field.type === FORM_INPUT_UI.DATE
                ? ConvertToLocalUsingMoment(storedValue)
                : storedValue;
            assign(userFormData, { [fieldKey]: storedValue });
          }
        }
      });
    });
  });
  return keys(userFormData).length > 0 ? userFormData : null;
}

export function updateUserInfoFromFormData(
  formConfig,
  formData,
  initialFormData,
  isOnlyCheck,
  masterData,
  clientAndLeadDetails
) {
  let updatedKeyMetaDataKey = [];
  let updatedIndexMetaDataKey = [];
  let isAnyValueUpdate = false;
  forEach(get(formConfig, "steps"), function (step, stepKey) {
    forEach(get(step, "config.panels"), function (panel, panelKey) {
      forEach(get(panel, "fields"), function (field, fieldKey) {
        const {dataType="",db_key=null,db_key_id=null,dataCollectionName=null,enableTextValueDateString=false} = field;
        let initialValue =
          initialFormData && Object.keys(initialFormData).includes(fieldKey)
            ? initialFormData[fieldKey]
            : null;
        let formValue =
          formData && Object.keys(formData).includes(fieldKey)
            ? formData[fieldKey]
            : null;
        if (
          db_key &&
          !(initialValue === null && formValue === null) &&
          JSON.stringify(initialValue) !== JSON.stringify(formValue)
        ) {
          isAnyValueUpdate = true;
          if (!!isOnlyCheck) return false;
          let dataTypeWiseValues = {};
          if(dataType === MASTER_KEYS.TYPE.BOOLEAN && !!formData[fieldKey]){
            let textValue = (formData[fieldKey].toString()).toLowerCase();
            if(["yes", "no"].includes(textValue))
              dataTypeWiseValues = {...dataTypeWiseValues, ...{boolValue:textValue === "yes"}};
          }
          if(enableTextValueDateString && masterData && !!db_key_id && !!dataCollectionName && db_key_id === DB_KEY_IDS.LEAD_SLOT_ALLOTMENT && dataCollectionName===DATA_COLLECTION_NAMES.ALLOTMENT_SLOTS && !isNil(formData[fieldKey])){
            const dataCollection = getMasterDataByKey(dataCollectionName,masterData);
            const selectedSlotBookingValue = checkIfArray(formData[fieldKey]) ? formData[fieldKey] : [formData[fieldKey]];
            let textValue = null;
            const { clientAlias, leadId} = clientAndLeadDetails || {};
            if(dataCollection && checkIfArray(dataCollection)){
              const {name:slotName=null} = dataCollection.find(i=>{return selectedSlotBookingValue.includes(i.id);}) || {};
              if(slotName){  //"30 April 2024 03:00 PM UTC";              
                textValue = convertSlotDateInString(slotName);
                if(!!textValue){
                  dataTypeWiseValues = {...dataTypeWiseValues, ...{textValue}};
                }else{
                  clientAlias && leadId && eeCustomAzureLogs.eeTrackEvent(clientAlias, leadId, {
                    method: "leadSlotAllotment_textValue",
                    data: {
                      textValue,
                      slotName,
                      selectedSlotBookingValue
                    }
                  });
                }
              }else{
                clientAlias && leadId && eeCustomAzureLogs.eeTrackEvent(clientAlias, leadId, {
                  method: "leadSlotAllotment_slotName",
                  data: {
                    slotName,
                    selectedSlotBookingValue
                  }
                });
              }
            }else{
              clientAlias && leadId && eeCustomAzureLogs.eeTrackEvent(clientAlias, leadId, {
                method: "leadSlotAllotment_dataCollection",
                data: {
                  dataCollectionName
                }
              });
            }            
          }
          leadObjectManager.setInputValue(
            fieldKey,
            field,
            formData[fieldKey],
            updatedKeyMetaDataKey,
            updatedIndexMetaDataKey,
            checkIfObject(dataTypeWiseValues)?dataTypeWiseValues:null,
            initialValue
          );
        }
      });
    });
  });
  return isAnyValueUpdate;
  //return keys(userFormData).length > 0 ? userFormData : null;
}
export function getUpdatedLeadStatusAndReason(
  lead_status_key,
  updateLeadStatus,
  masterData
) {
  if (
    !isEmpty(lead_status_key) &&
    !isEmpty(updateLeadStatus) &&
    !isEmpty(masterData)
  ) {
    let leadStatusTitle = map(
      filter(get(updateLeadStatus, "applicationFormStatusMapping"), {
        key: lead_status_key,
      }),
      "LeadStatusTitle"
    );
    let leadStatuses = !isNil(masterData) && getMasterDataByKey("LeadStatuses", masterData);
    let leadReasons = !isNil(masterData) && getMasterDataByKey("LeadReasons", masterData);
    let LeadStatusIds = map(
      filter(leadStatuses, function (o) {
        return (
          o.name ===
          (isArray(leadStatusTitle) ? leadStatusTitle[0] : leadStatusTitle)
        );
      }),
      "id"
    );
    let LeadReasonIds = map(
      filter(leadReasons, function (o) {
        return !!o.statusId && LeadStatusIds.includes(o.statusId);
      }),
      "id"
    );
    if (isEmpty(LeadReasonIds)) {
      LeadReasonIds = map(
        filter(leadReasons, function (o) {
          return o.Name === "Not Known";
        }),
        "id"
      );
    }
    return {
      statusId: isArray(LeadStatusIds) ? LeadStatusIds[0] : LeadStatusIds,
      reasonId: LeadReasonIds,
    };
  }
}
export function checkStepStatus(
  leadStatusId,
  lead_status_key,
  updateLeadStatus,
  masterData
) {
  if (
    updateLeadStatus &&
    !!updateLeadStatus.stepNavigation &&
    updateLeadStatus.stepNavigation === STEP_NAVIGATION_TYPE.NON_LINEAR
  ) {
    return true;
  } else if (
    updateLeadStatus &&
    !!updateLeadStatus.stepNavigation &&
    updateLeadStatus.stepNavigation === STEP_NAVIGATION_TYPE.LINEAR
  ) {
  }
}

export function getCurrentStatusMappingData(
  leadStatusId,
  updateLeadStatus,
  masterData
) {
  const leadStatuses = !isNil(masterData) && getMasterDataByKey(`LeadStatuses`,masterData);
  /*let currentLeadStatusTitle = map(
    filter(leadStatuses, {
      id: leadStatusId,
    }),
    "name"
  );*/
  const leadStatusObj = find(leadStatuses,{id: leadStatusId});
  const leadStatusName = !isNil(leadStatusObj) && get(leadStatusObj,`name`) ? leadStatusObj.name : null;
  let leadStatusMapping = leadStatusName && updateLeadStatus && checkIfArray(updateLeadStatus.applicationFormStatusMapping) ? filter(updateLeadStatus.applicationFormStatusMapping,
    function (o) {
      return leadStatusName.toLowerCase() === (o.LeadStatusTitle).toLowerCase();
    }
  ) : null;
  return checkIfArray(leadStatusMapping) ? leadStatusMapping : null;
}

export function getLeadStatusMappingObject(lead_status_key, updateLeadStatus) {
  if (!isEmpty(lead_status_key) && !isEmpty(updateLeadStatus)) {
    let leadStatusMapping = map(
      filter(get(updateLeadStatus, "applicationFormStatusMapping"), {
        key: lead_status_key,
      })
    );

    if (!!leadStatusMapping) {
      return leadStatusMapping[0];
    }
    return null;
  }
}
/**@description - Generate uploaded media url  */
export function getMediaLiveURL(fileName, mappedClientAlias = STORAGE_KEY.ALIAS) {
  let clientConfig = OfflineStorage.getItem(
    STORAGE_KEY.CLIENT_INFO_REDUCER,
    STORAGE_TYPE.LOCAL,
    false
  );
  clientConfig = !!clientConfig && JSON.parse(clientConfig);
  /*let mediaStorage = clientConfig.mediaStorage
    ? clientConfig.mediaStorage
    : null;*/ 
    //Hard coded `mediaStorage` path as we missed to update path in config
  let mediaStorage={
      "blobURL": "https://eeresources-cdn.azureedge.net/",
      "blobFolder": "EmailAttachments"
  };
  let clientAlias =
    clientConfig[STORAGE_KEY.CLIENT_INFO] &&
    !!clientConfig[STORAGE_KEY.CLIENT_INFO][mappedClientAlias]
      ? clientConfig[STORAGE_KEY.CLIENT_INFO][mappedClientAlias]
      : null;
  const { blobURL, blobFolder } = mediaStorage;
  if (
    !!blobURL &&
    !!blobFolder &&
    clientAlias
  ) {
    let mediaURL = blobURL + clientAlias + "/" + blobFolder + "/" + fileName;
    //replaces non-breaking space characters with narrow no-break space characters
    mediaURL = mediaURL.replaceAll(/\u00A0/g, "\u202F");
    return mediaURL;
  } else {
    logError(
      "Required media storage blob details at client config json is not provided."
    );
  }
  return null;
}

export function getAvatarLiveURL(fileName) {
  let clientConfig = OfflineStorage.getItem(
    STORAGE_KEY.CLIENT_INFO_REDUCER,
    STORAGE_TYPE.LOCAL,
    false
  );
  clientConfig = !!clientConfig && JSON.parse(clientConfig);
  /*let mediaStorage = clientConfig.mediaStorage
    ? clientConfig.mediaStorage
    : null;*/ 
    //Hard coded `mediaStorage` path as we missed to update path in config
  let mediaStorage={
    "blobURL": "https://eeresources-cdn.azureedge.net/",
    "blobFolder": "EmailAttachments"
  };
  let clientAlias =
    clientConfig[STORAGE_KEY.CLIENT_INFO] &&
    !!clientConfig[STORAGE_KEY.CLIENT_INFO][STORAGE_KEY.ALIAS]
      ? clientConfig[STORAGE_KEY.CLIENT_INFO][STORAGE_KEY.ALIAS]
      : null;
  if (mediaStorage && !!mediaStorage.blobURL && clientAlias) {
    return (
      mediaStorage.blobURL +
      clientAlias +
      "/" +
      mediaStorage.blobFolder +
      "/" +
      fileName
    );
  } else {
    logError(
      "Required media storage blob details at client config json is not provided."
    );
  }
  return null;
}

export function isVariableEmpty(param) {
  return param !== undefined
    ? !((Array.isArray(param) && param.length > 0) || !!param)
    : true;
}
export function ConvertToLocalUsingMoment(dateTime,dateTimeFormat="YYYY-MM-DD", skipUTCConversion=false) {
  //let dateTimeFormat = "YYYY-MM-DD";
  if(skipUTCConversion){
    return moment(dateTime).format(dateTimeFormat);
  }
  let local = moment.utc(dateTime).local().format(dateTimeFormat);
  return local;
}
export function ConvertToUTCUsingMoment(dateTime) {
  let dateTimeFormat = "YYYY-MM-DD HH:mm";
  return moment(dateTime).utc().format(dateTimeFormat);
}
export function generateFormAndSubmit(params) {
  var form = document.createElement("form");
  form.setAttribute("method", params && !!params.type ? params.type : "post");
  form.setAttribute("action", params.url);
  //form.setAttribute("action", "http://localhost:33053/Payment/initialize");
  form.setAttribute("id", params.id);
  let signatureValuesArr = [];
  const {formData={}} = params || {};
  formData &&
  Object.keys(formData).length > 0 &&
  Object.keys(formData).map((key) => {
    const value = formData[key];
    let i = document.createElement("input"); //input element, text
    i.setAttribute("type", "hidden");
    i.setAttribute("name", key);
    i.setAttribute("value", value);
    form.appendChild(i);
    signatureValuesArr.push(`${key}:${value}`);
    return true;
  });
  let securityValue = "";
  try {
    if(checkIfArray(signatureValuesArr)){
      securityValue = generateSignature(`/Payment/initialize`,``,signatureValuesArr.join(``))
    }
  } catch (error) {
    securityValue = "UNABLE_TO_GENERATE";
  }

  let i = document.createElement("input"); //input element, text
  i.setAttribute("type", "hidden");
  i.setAttribute("name", `x-er-id`);
  i.setAttribute("value", securityValue);
  form.appendChild(i);

  document.getElementsByTagName("body")[0].appendChild(form);
  form.submit();
}
export function getTotalAmount(entity_keys, userDetail, masterData) {
  let pricingList = getMasterDataByKey("PricingList", masterData);
  if (!entity_keys && !userDetail && !masterData && !pricingList) {
    return false;
  }
  let prices =
    masterData &&
    map(
      filter(pricingList, (item) => {
        return entity_keys.includes(item.entityId);
      }),
      "amount"
    );
  let total = 0;
  prices &&
    prices.map((item) => {
      total += parseInt(item);
      return true;
    });
  return total;
}
export function getSelectedCourseIds(formData, masterData) {
  let pricingCriterion =
    masterData && !!masterData.pricingCriterion
      ? masterData.pricingCriterion
      : "";
  if (!pricingCriterion) return false;

  const pricingList = !isNil(masterData) && getMasterDataByKey("PricingList", masterData);
  if (
    formData &&
    !!formData[pricingCriterion + "Id"] &&
    checkIfArray(pricingList)
  ) {
    let entity_keys =
      formData[pricingCriterion + "Id"] &&
      Array.isArray(formData[pricingCriterion + "Id"])
        ? formData[pricingCriterion + "Id"]
        : [formData[pricingCriterion + "Id"]];
    return entity_keys;
  }
  return false;
}
export function getStatusIdAndReasonIds(
  LeadStatusTitle,
  masterData,
  clientInfo
) {
  if(!!LeadStatusTitle){
    let leadStatuses = !isNil(masterData) && getMasterDataByKey("LeadStatuses", masterData);
    let leadReasons = !isNil(masterData) && getMasterDataByKey("LeadReasons", masterData);
    if (checkIfArray(leadStatuses) && checkIfArray(leadReasons)) {
      let statusData = !!LeadStatusTitle
        ? find(leadStatuses, {
            name: LeadStatusTitle,
          })
        : null;
      if (!statusData || !statusData.id) {
        return false;
      }
      let mappedReason = find(leadReasons, { statusId: statusData.id });
      return (
        statusData &&
        mappedReason &&
        !!statusData.id &&
        !!mappedReason.id && {
          statusId: statusData.id,
          reasonId: mappedReason.id,
        }
      );
    }
  }
  return false;
}
export function getMappedSuccessAndFailStatus(
  paymentModeId,
  clientInfo,
  lead_status_key
) {
  if (
    !!paymentModeId &&
    clientInfo &&
    clientInfo.paymentDetails &&
    clientInfo.paymentDetails.ModesOfPayment &&
    Array.isArray(clientInfo.paymentDetails.ModesOfPayment) &&
    clientInfo.paymentDetails.ModesOfPayment.length > 0
  ) {
    let details = find(clientInfo.paymentDetails.ModesOfPayment, {
      id: parseInt(paymentModeId),
    });
    if (
      !isNil(details) &&
      !isNil(lead_status_key) &&
      !isEmpty(lead_status_key) &&
      !isNil(get(details, lead_status_key)) &&
      !isEmpty(get(details, lead_status_key))
    ) {
      return get(details, lead_status_key);
    }
    if (details) {
      return details;
    }
  }
  return false;
}
export function generatePayNowFormData(
  masterData,
  userDetail,
  clientInfo,
  formData,
  promoCodeDetails,
  paymentDetails,
  isCounselorAccessActive,
  counselorDetails
) {
  let data = {};
  let pricingCriterion =
    !isNil(get(masterData, "pricingCriterion")) &&
    !isEmpty(get(masterData, "pricingCriterion"))
      ? get(masterData, "pricingCriterion")
      : "";
  let selectedCourses = !isNil(
    get(formData, HARD_CODED_FIELD_KEYS.SELECTED_COURSE_FOR_PAYMENT)
  )
    ? get(formData, HARD_CODED_FIELD_KEYS.SELECTED_COURSE_FOR_PAYMENT)
    : getSelectedCourseIds(formData, masterData);
  let totalAmount = !isNil(
    get(formData, HARD_CODED_FIELD_KEYS.TOTAL_DISCOUNTED_AMOUNT_TO_PAY)
  )
    ? get(formData, HARD_CODED_FIELD_KEYS.TOTAL_DISCOUNTED_AMOUNT_TO_PAY)
    : !isNil(get(formData, HARD_CODED_FIELD_KEYS.TOTAL_AMOUNT_TO_PAY))
    ? get(formData, HARD_CODED_FIELD_KEYS.TOTAL_AMOUNT_TO_PAY)
    : getTotalAmount(selectedCourses, userDetail, masterData);
  let paymentModeId =
    formData && !isNil(get(formData, HARD_CODED_FIELD_KEYS.ZERO_AMOUNT_TO_PAY))
      ? !isNil(
          get(formData, HARD_CODED_FIELD_KEYS.ZERO_AMOUNT_TO_PAYMENT_METHOD_ID)
        )
        ? get(formData, HARD_CODED_FIELD_KEYS.ZERO_AMOUNT_TO_PAYMENT_METHOD_ID)
        : 1
      : formData && !!formData.paymentMethod && formData.paymentMethod;
  paymentModeId = Array.isArray(paymentModeId)
    ? paymentModeId[0]
    : paymentModeId;
  if (!totalAmount && false) {
    alert("Sorry, Zero or negative amount to pay is not allowed."); //put this message in english json
    return false;
  } else {
    data[HARD_CODED_FIELD_KEYS.FEES_TYPE] =
      !isNil(document.getElementById(HARD_CODED_FIELD_KEYS.FEES_TYPE)) &&
      !isNil(document.getElementById(HARD_CODED_FIELD_KEYS.FEES_TYPE).value) &&
      !isEmpty(document.getElementById(HARD_CODED_FIELD_KEYS.FEES_TYPE).value)
        ? document.getElementById(HARD_CODED_FIELD_KEYS.FEES_TYPE).value
        : 1;
    data[HARD_CODED_FIELD_KEYS.FEES_TYPE] = 1; // for course fees fix 1 need to send for payment
    if (clientInfo && clientInfo.Info && !!clientInfo.Info.clientId) {
      data["clientId"] = clientInfo.Info.clientId;
    }

    if (clientInfo && clientInfo.Info && !!clientInfo.Info.authToken) {
      data["authToken"] = clientInfo.Info.authToken;
    }
    set(data, "cid", "");
    set(data, "rid", ""); //role id of counselor, it will blank if payment request is from LEAD
    set(data, "lid", "");
    if (
      isCounselorAccessActive &&
      !isNil(counselorDetails) &&
      !!get(counselorDetails, "id")
    ) {
      !!get(counselorDetails, "userRoleId") &&
        set(data, "rid", get(counselorDetails, "userRoleId"));
        !!get(counselorDetails, "id") &&
        set(data, "cid", get(counselorDetails, "id"));
      !!get(userDetail, "lead.id") && set(data, "lid", userDetail.lead.id);
    }
    if (clientInfo && clientInfo.Info && !!clientInfo.Info.source) {
      data["source"] = clientInfo.Info.source;
    }
    if (clientInfo && clientInfo.Info && !!clientInfo.Info.alias) {
      data["alias"] = clientInfo.Info.alias;
    }
    data["param1"] = clientInfo && !!get(clientInfo,`paymentDetails.paymentFormRequestParametersMapping.param1`) &&!isNil(get(userDetail,`${clientInfo.paymentDetails.paymentFormRequestParametersMapping.param1}`)) ? get(userDetail,`${clientInfo.paymentDetails.paymentFormRequestParametersMapping.param1}`) : "";
    /*if (
      clientInfo &&
      clientInfo.Info &&
      !!clientInfo.Info.clientId &&
      !!selectedCourses &&
      !!totalAmount
    ) {
      data["amt"] = totalAmount;
    }*/
    if (
      clientInfo &&
      clientInfo.Info &&
      !!clientInfo.Info.clientId &&
      !!selectedCourses
    ) {
      //  data["amt"] = totalAmount;
    }
    
    if (
      clientInfo &&
      clientInfo.Info &&
      !!clientInfo.Info.clientId &&
      !!selectedCourses
    ) {
      // data["actualAmount"] = actualTotalAmount;
    }

    if (
      !isNil(paymentDetails) &&
      !isNil(get(paymentDetails, "pId")) &&
      Array.isArray(get(paymentDetails, "pId")) &&
      get(paymentDetails, "pId").length
    ) {
      data["pId"] = get(paymentDetails, "pId");
    }
    if (userDetail && userDetail.user && !!userDetail.user.prn) {
      data[STORAGE_KEY.PRN] = userDetail.user.prn;
    }
    if (userDetail && userDetail.user && !!userDetail.user.email) {
      data["email"] = userDetail.user.email;
    }
    data["fatherName"] =
      userDetail && userDetail.user && !!userDetail.user.fathersName
        ? userDetail.user.fathersName
        : "";
    if (userDetail && userDetail.user && !!userDetail.user.mobileNumber) {
      data["mobile"] = userDetail.user.mobileNumber;
    }
    if (userDetail && userDetail.lead && !!userDetail.lead.id) {
      data["leadId"] = userDetail.lead.id;
    }
    if (userDetail && userDetail.lead && !!userDetail.lead[STORAGE_KEY.APPLICATION_FORM_NUMBER]) {
      data["appFormNumber"] = userDetail.lead[STORAGE_KEY.APPLICATION_FORM_NUMBER];
    }
    if (userDetail && userDetail.user && !!userDetail.user.id) {
      data["userId"] = userDetail.user.id;
    }
    if (userDetail && masterData && !!selectedCourses) {
      data["courses"] = selectedCourses;
    }

    if (!!pricingCriterion) {
      let entityCollectionName = !isNil(masterData) && getMasterDataByKey(`${pricingCriterion.substring(0, 1).toUpperCase()}${pricingCriterion.substring(1)}`,masterData);
      if (!!userDetail && !!selectedCourses && checkIfArray(entityCollectionName)) {
        data["courseName"] = map(
            filter(entityCollectionName, (item) => {
              return selectedCourses.includes(item.id);
            }),
            "name"
          ).join();
      } else {
        data["courseName"] = "";
      }
    } else {
      data["courseName"] = "";
    }

    data["E1Name"] = "";
    
    const entity1Collection = !isNil(masterData) && getMasterDataByKey(`Entity1`,masterData);
    if (
      userDetail &&
      checkIfArray(get(userDetail, "lead.entity1Id")) &&
      checkIfArray(entity1Collection)
    ) {
        data["E1Name"] = map(
          filter(entity1Collection, (item) => {
            return (userDetail.lead.entity1Id).includes(item.id);
          }),
          "name"
        ).join();
    }

    data["E2Name"] = "";
    const entity2Collection = !isNil(masterData) && getMasterDataByKey(`Entity2`,masterData);
    if(pricingCriterion === "entity2"){
      data["E2Name"] = data["courseName"];
    }else if (
      userDetail &&
      checkIfArray(get(userDetail, "lead.entity2Id")) &&
      checkIfArray(entity2Collection)
    ) {
      if(checkIfArray(entity2Collection)){
        data["E2Name"] = map(
          filter(entity2Collection, (item) => {
            return (userDetail.lead.entity2Id).includes(item.id);
          }),
          "name"
        ).join();
      }
      
    }

    data["E3Name"] = "";
    
    const entity3Collection = !isNil(masterData) && getMasterDataByKey(`Entity3`,masterData);
    if(pricingCriterion === "entity3"){
      data["E3Name"] = data["courseName"];
    }else if (
      userDetail &&
      checkIfArray(get(userDetail, "lead.entity3Id")) &&
      checkIfArray(entity3Collection)
    ) {
      data["E3Name"] = map(
        filter(entity3Collection, (item) => {
          return (userDetail.lead.entity3Id).includes(item.id);
        }),
        "name"
      ).join();
    }

    data["E4Name"] = "";
    
    const entity4Collection = !isNil(masterData) && getMasterDataByKey(`Entity4`,masterData);
    if(pricingCriterion === "entity4"){
      data["E4Name"] = data["courseName"];
    }else if (
      userDetail &&
      checkIfArray(get(userDetail, "lead.entity4Id")) &&
      checkIfArray(entity4Collection)
    ) {
      data["E4Name"] = map(
        filter(entity4Collection, (item) => {
          return (userDetail.lead.entity4Id).includes(item.id);
        }),
        "name"
      ).join();
    }
    if (paymentModeId) {
      data["paymentModeId"] = paymentModeId;
    }
    data["promoCode"] =
      promoCodeDetails && !!promoCodeDetails["promoCode"]
        ? promoCodeDetails["promoCode"]
        : "";
    let mappedSuccessAndFailStatus = getMappedSuccessAndFailStatus(
      paymentModeId,
      clientInfo
    );
    if (
      !mappedSuccessAndFailStatus ||
      !mappedSuccessAndFailStatus.fStatus ||
      !mappedSuccessAndFailStatus.sStatus
    ) {
      logError(
        "Mapping of selected payment mode and it's Success and Fail status is not configured in clientconfig json."
      );
    } else {
      //mappedSuccessAndFailStatus;
      let successStatusDetails =
        masterData &&
        clientInfo &&
        getStatusIdAndReasonIds(
          mappedSuccessAndFailStatus.sStatus,
          masterData,
          clientInfo
        );
      if (
        masterData &&
        clientInfo &&
        successStatusDetails &&
        !!successStatusDetails.statusId &&
        !!successStatusDetails.reasonId
      ) {
        data["sStatus"] = successStatusDetails.statusId;
        data["sReason"] = successStatusDetails.reasonId;
      }
      let failStatusDetails =
        masterData &&
        clientInfo &&
        getStatusIdAndReasonIds(
          mappedSuccessAndFailStatus.fStatus,
          masterData,
          clientInfo
        );
      if (
        masterData &&
        clientInfo &&
        failStatusDetails &&
        !!failStatusDetails.statusId &&
        !!failStatusDetails.reasonId
      ) {
        data["fStatus"] = failStatusDetails.statusId;
        data["fReason"] = failStatusDetails.reasonId;
      }
      let pendingStatusDetails =
        masterData &&
        clientInfo &&
        getStatusIdAndReasonIds(
          mappedSuccessAndFailStatus.pStatus,
          masterData,
          clientInfo
        );
      if (
        masterData &&
        clientInfo &&
        pendingStatusDetails &&
        !!pendingStatusDetails.statusId &&
        !!pendingStatusDetails.reasonId
      ) {
        data["pStatus"] = pendingStatusDetails.statusId;
        data["pReason"] = pendingStatusDetails.reasonId;
      }else{
        data["pStatus"] = "";
        data["pReason"] = "";
      }
      
      let partPaymentStatusDetails =
      masterData &&
      clientInfo &&
      getStatusIdAndReasonIds(
        mappedSuccessAndFailStatus.ppStatus,
        masterData,
        clientInfo
      );
      const {statusId, reasonId} = partPaymentStatusDetails || {}
      if (
        masterData &&
        clientInfo &&
        statusId &&
        reasonId
      ) {
        data["ppStatus"] = statusId;
        data["ppReason"] = reasonId;
      }else{
        data["ppStatus"] = "";
        data["ppReason"] = "";
      }
    }
    let existingINST = !!OfflineStorage.getItem(
      STORAGE_KEY.INST,
      STORAGE_TYPE.LOCAL
    )
      ? OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)
      : null;
    if (
      masterData &&
      !!masterData.applicationFormCriteria &&
      !!existingINST &&
      !isEmpty(existingINST)
    ) {
      data[STORAGE_KEY.APP_CRITERION] = parseInt(existingINST);
    } else {
      data[STORAGE_KEY.APP_CRITERION] = "";
    }
    if(masterData && pricingCriterion){
      const collection = getMasterDataByKey(capitalize(pricingCriterion),masterData);
      if(checkIfArray(collection)){
        const entityObj = collection.find(ele => data.courses.includes(ele.id));
        if(checkIfObject(entityObj)){
          data[STORAGE_KEY.MAPPED_CODE] = entityObj.mappedCode;
        }
      }
    }
    /*UKC code - COMMENTED FOR FUTHER USE  */
    /**
     * UKC details added for multi lead journey
     */
    let UKC_Details = getUKCDetails(masterData, userDetail);
    UKC_Details = UKC_Details
      ? UKC_Details
      : {
          [STORAGE_KEY.UKC_URL_PARAMETER_KEY]: "",
          [STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY]: "",
        };
    Object.assign(data, UKC_Details);
    /* END of UKC update */
    if (
      userDetail &&
      userDetail.user &&
      (!!userDetail.user.firstName || !!userDetail.user.lastName)
    ) {
      let names = [];
      !!userDetail.user.firstName && names.push(userDetail.user.firstName);
      !!userDetail.user.lastName && names.push(userDetail.user.lastName);
      data["name"] = names.join(" ");
    }
    let prefCourseInfo = get(paymentDetails, HARD_CODED_FIELD_KEYS.PREF_COURSES_INFO);
    const allPaidPaymentDetails = getPaidEntitiesIds(userDetail, clientInfo);
    if(checkIfArray(prefCourseInfo)){
      let preferredCourseDetails = [];
      const prefCourseInfoLen = prefCourseInfo.length;
      for(let i=0; i<prefCourseInfoLen; i++){
        const ele = prefCourseInfo[i];
        if(checkIfArray(allPaidPaymentDetails)){
          let paidEntries = allPaidPaymentDetails.find(entry => entry.entityId == ele.id);
          if(checkIfObject(paidEntries)){
            continue;
          }
        }
        if(!(data["pId"].includes(ele.id))){
          continue;
        }
        preferredCourseDetails.push({
          PrefId: ele.prefId,
          Id: ele.id,
          MasterKey: ele.masterKey ? ele.masterKey : capitalize(pricingCriterion)
        })
      }
      data[HARD_CODED_FIELD_KEYS.PREF_COURSES_INFO] = JSON.stringify(preferredCourseDetails);
    }
    /* Partial Payment Amount And Partial Payment Key */
    const installmentAmountToPay = get(
      formData,
      HARD_CODED_FIELD_KEYS.INSTALLMENT_AMOUNT_TO_PAY
    );
    if (!!installmentAmountToPay) {
      const payment_txn_success_status = getPaymentTransactionSuccessStatus(clientInfo);
      data[STORAGE_KEY.PART_PAYMENT_AMOUNT_KEY] = base64Encode(installmentAmountToPay);
      const installmentPaymentDetails = getInstallmentPaymentDetails(
        get(userDetail, "paymentDetails"),
        FEES_TYPE.APPLICATION_FORM_FEES,
        payment_txn_success_status
      );
      data[STORAGE_KEY.PART_PAYMENT_KEY] = "";
      if (checkIfArray(installmentPaymentDetails)) {
        const {balanceAmount=0,partPaymentKey=null} = installmentPaymentDetails[0] || {}
        data[STORAGE_KEY.PART_PAYMENT_KEY] = balanceAmount>0 ?  partPaymentKey : "";
      }
    }

    if(checkIfObject(formData) && paymentDetails){
      let updatedVal = formData[HARD_CODED_FIELD_KEYS.CURRENCY_FIELD] || "";
      if(checkIfObject(updatedVal) && !Array.isArray(updatedVal)){
        updatedVal = updatedVal.ISOCode
      }
      updatedVal = updatedVal && updatedVal.toString();
      if(updatedVal === "INR"){
        updatedVal = "";
      }
      data["currency"] = updatedVal;
    }
    return data;
  }
}
export function getTodayDataForPaymentDetails() {
  var today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "/" + mm + "/" + yyyy;
}
export function generateListFromObject(
  params,
  className,
  excludeKeys,
  masterData
) {
  if(!params["paymentDate"])
  params["paymentDate"] = getTodayDataForPaymentDetails();

  if (params && Object.keys(params).length > 0) {
    const modesOfPaymentCollection = !isNil(masterData) && getMasterDataByKey(`ModesOfPayment`,masterData);
    let modeOfPaymentDetail = checkIfArray(modesOfPaymentCollection) && params && !isEmpty(params["paymentModeId"])
        ? find(modesOfPaymentCollection, {
            id: parseInt(params["paymentModeId"]),
          })
        : null;
    params["paymentModeId"] =
      !isEmpty(modeOfPaymentDetail) && !isEmpty(modeOfPaymentDetail["name"])
        ? modeOfPaymentDetail["name"]
        : params["paymentModeId"];
    params["isSuccess"] =
      !isEmpty(params["isSuccess"]) &&
      (params["isSuccess"] === "True" ||
        params["isSuccess"] === "true" ||
        params["isSuccess"] === "1" ||
        params["isSuccess"] === 1)
        ? getLang("PaymentSuccessStatus","Success") 
        : getLang("PaymentFailStatus","Failed");
    /*let allowedKeys =
      excludeKeys && Array.isArray(excludeKeys) && excludeKeys.length > 0
        ? difference(Object.keys(params), excludeKeys)
        : Object.keys(params);*/
    /**
         *
         *
Transaction id: 56c9bb9148f27b5b
Mode of Payment: 1
         Amount: 518
global.payments.isSuccess: Success
Date of Payment: 22/10/2019
         */
    let listItems = [];
    if (!isEmpty(params)) {
      if (!isEmpty(params["transactionId"])) {
        listItems.push(
          <li key={listItems.length + 1}>
            <strong>{getLang("global.payments.transactionId")}</strong>
            {": "}
            {params["transactionId"]}
          </li>
        );
      }
      if (!isEmpty(params["paymentModeId"])) {
        listItems.push(
          <li key={listItems.length + 1}>
            <strong>{getLang("global.payments.paymentModeId")}</strong>
            {": "}
            {params["paymentModeId"]}
          </li>
        );
      }
      if (!isEmpty(params["amt"])) {
        listItems.push(
          <li key={listItems.length + 1}>
            <strong>{getLang("global.payments.amt")}</strong>
            {": "}
            {params["amt"]}
          </li>
        );
      }
      if (!isEmpty(params["isSuccess"])) {
        let lang_text = params["isSuccess"];
        if (
          lang_text === "Success" &&
          !!getLang("PaymentSuccessStatus","")
        ) {
          lang_text = getLang("PaymentSuccessStatus");
        } else if (
          lang_text === "Failed" &&
          !!getLang("PaymentFailStatus","")
        ) {
          lang_text = getLang("PaymentFailStatus");
        }
        listItems.push(
          <li key={listItems.length + 1}>
            <strong>{getLang("global.payments.isSuccess")}</strong>
            {": "}
            <span
              className={"payment-" + params["isSuccess"]}
              dangerouslySetInnerHTML={{
                __html: lang_text,
              }}
            />
          </li>
        );
      }
      if (!isEmpty(params["paymentDate"])) {
        listItems.push(
          <li key={listItems.length + 1}>
            <strong>{getLang("global.payments.paymentDate")}</strong>
            {": "}
            {params["paymentDate"]}            
          </li>
        );
      }
    }    
    return listItems ? (
      <ul className={!!className ? className : undefined}>{listItems}</ul>
    ) : null;
  }
  return null;
}
export function generatePaymentDetailList(params, masterData) {
  let modesOfPayment = !isNil(masterData) && getMasterDataByKey("ModesOfPayment", masterData);
  if (params && Object.keys(params).length > 0) {
    let obj = {};
    obj = Object.keys(params).map((key) => {
      switch (key) {
        case "paymentModeId": {
          let name = checkIfArray(modesOfPayment) && getNameById(params[key], modesOfPayment);
          return {
            value: !!name ? name : params[key],
            label: getLang("form.fields.paymentMethod.title"),
          };
        }
        default: {
          return null;
        }
      }
    });
    return obj && Object.keys(obj).length;
  }
  return null;
}
export function validateMappedLeadStatus(clientInfo, masterData) {
  if (clientInfo && masterData) {
    let leadStatuses = getMasterDataByKey("LeadStatuses", masterData);
    let leadReasons = getMasterDataByKey("LeadReasons", masterData);
    let modesOfPayment = get(clientInfo, "paymentDetails.ModesOfPayment");
    let mappedStatusToStep = get(clientInfo,"LeadstatusDetails.applicationFormStatusMapping");

    if (!checkIfArray(leadStatuses)) {
      logError(new Error("LeadStatuses in masterdata is blank"));
    }
    if (!checkIfArray(leadReasons)) {
      logError(new Error("LeadReasons in masterdata is blank"));
    }
    if (!checkIfArray(modesOfPayment)) {
      logError(
        new Error(
          "ModeOfPayment and lead status mapping is missing in client configuration json. This is mandatory because it require update status and reason on any mode of payment success/fail"
        )
      );
    }
    if (!checkIfArray(mappedStatusToStep)) {
      logError(
        new Error(
          "mappedStatusToStep for lead status mapping with each step completion is missing in client configuration json. This is mandatory because it require for update lead status while change/navigate step"
        )
      );
    }
    if (leadStatuses && leadReasons && mappedStatusToStep) {
      mappedStatusToStep.map((item) => {
        let foundStatus =
          !!item.LeadStatusTitle &&
          find(leadStatuses, { name: item.LeadStatusTitle });
        if (!foundStatus) {
          if (!!item.LeadStatusTitle) {
            logError(
              new Error(
                "Status: '" +
                  item.LeadStatusTitle +
                  "' not found in master data."
              )
            );
          } else if (!!item.key) {
            logError(
              new Error(
                "Status not mapped to '" + item.key + "' in client config json."
              )
            );
          } else {
            logError(
              new Error(
                "Status not mapped to '" + item.key + "' in client config json."
              )
            );
          }
        } else {
          let foundReason = find(leadReasons, { statusId: foundStatus.id });
          if (!foundReason) {
            logError(
              new Error(
                "Mapped `Reason` for `" +
                  item.LeadStatusTitle +
                  "` not found in master data"
              )
            );
          } else {
            /*logInfo(
              "Mapped `Reason` `" +
                foundReason.name +
                "` for `" +
                item.LeadStatusTitle +
                "` found in master data"
            );*/
          }
        }
        return false;
      });
    }
    if (leadStatuses && leadReasons && modesOfPayment) {
      let successStatuses = map(modesOfPayment, "sStatus");
      let failStatuses = map(modesOfPayment, "fStatus");
      let status = union(successStatuses, failStatuses);

      status.map((item) => {
        let foundStatus = find(leadStatuses, { name: item });
        if (!foundStatus) {
          logError(
            new Error("Status: '" + item + "' not found in master data")
          );
        } else {
          let foundReason = find(leadReasons, { statusId: foundStatus.id });
          if (!foundReason) {
            logError(
              new Error(
                "Mapped `Reason` for `" + item + "` not found in master data"
              )
            );
          } else {
            /*logInfo(
              "Mapped `Reason` `" +
                foundReason.name +
                "` for `" +
                item +
                "` found in master data"
            );*/
          }
        }
        return true;
      });
    } else {
      //logInfo("ALL MAPPING WORKING CONFIG CORRECTLY");
    }

    /*logInfo(
      "***********    Checking mapped lead status and there reason are exists in master data  ***********"
    );*/
  }
}
/** Notification */
export function notify(message, config) {
  config && Object.keys(config).length
    ? toast(message, config)
    : toast(message);
}

/**Replace token used in JSON with value */
export function replaceTokenWithValue(token, userDetail, masterData, clientInfo, isCounselorAccessActive) {
  const { emailMobileVerification, enableMasking } = clientInfo ? clientInfo : {};
  const verificationData = get(userDetail, "lead.verificationData") ? get(userDetail, "lead.verificationData") : [];
  let isPrimaryEmailVerified = false;
  let isPrimaryMobileVerified = false;
  checkIfArray(verificationData) && verificationData.forEach(ele => {
    const { keyId, isVerified, value } = ele;
    if(keyId === 1 && isVerified && userDetail && userDetail.user && userDetail.user.email === value){
      isPrimaryEmailVerified = true
    }
    if(keyId === 5 && isVerified && userDetail && userDetail.user && userDetail.user.mobileNumber === value){
      isPrimaryMobileVerified = true
    }
  })
  const { verifyEmail, verifyMobile, editEmail, editMobile } = emailMobileVerification ? emailMobileVerification : {};
  switch (token) {
    case CONTENT_TOKEN.APPLICATION_NUMBER: {
      return userDetail &&
        userDetail.lead &&
        !isEmpty(userDetail.lead.applicationFormNumber)
        ? userDetail.lead.applicationFormNumber
        : null;
    }
    case CONTENT_TOKEN.GENDER: {
      const genderCollection = !isNil(masterData) && getMasterDataByKey(`Gender`,masterData);
      const mappedGender = userDetail && checkIfArray(genderCollection) && !!(get(userDetail,`user.gender`))?.trim() &&
      find(genderCollection, {alias: userDetail.user.gender});
      return !!mappedGender && !!mappedGender.name ? mappedGender.name: "";
    }
    case CONTENT_TOKEN.APPLICATION_STATUS: {
      const leadStatuses = !isNil(masterData) && getMasterDataByKey(`LeadStatuses`,masterData);
      const mappedStatus = userDetail &&
      userDetail.lead &&
      checkIfArray(leadStatuses) &&
      !isEmpty(userDetail.lead.statusId) &&
      find(leadStatuses, {
        id: parseInt(userDetail.lead.statusId),
      });
      return !!mappedStatus && !!mappedStatus.name ? mappedStatus.name : "";
    }
    case CONTENT_TOKEN.COURSE_APPLIED:
      let courseIds =
        userDetail &&
        masterData &&
        !isEmpty(masterData.pricingCriterion) &&
        !isEmpty(userDetail.lead) &&
        !isEmpty(
          userDetail.lead[
            EntityFieldNameByCriteriaValue[masterData.pricingCriterion]
          ]
        )
          ? userDetail.lead[
              EntityFieldNameByCriteriaValue[masterData.pricingCriterion]
            ]
          : null;
      if (!isEmpty(courseIds)) {
        const pricingList = !isNil(masterData) && getMasterDataByKey(`PricingList`,masterData);
        let pricingListIds = checkIfArray(pricingList) &&
          map(pricingList, "entityId");
        let coursesWithPricing = intersection(courseIds, pricingListIds);
        let courseNames =
          masterData &&
          masterData.pricingCriterion &&
          map(
            filter(
              getMasterDataByKey(
                MasterDataCollectionByCriteriaValue[
                  masterData.pricingCriterion
                ],
                masterData
              ),
              function (o) {
                return coursesWithPricing.includes(o.id);
              }
            ),
            "name"
          );
        return !isEmpty(courseNames)
          ? "<ul class='course-list'><li>" +
              courseNames.join("</li><li>") +
              "</li></ul>"
          : null;
      }
      return null;

    case CONTENT_TOKEN.LOGGED_USER_ENTITY1:
      return getEntityNamesByKey("entity1", userDetail, masterData);

    case CONTENT_TOKEN.LOGGED_USER_ENTITY2:
      return getEntityNamesByKey("entity2", userDetail, masterData);

    case CONTENT_TOKEN.LOGGED_USER_ENTITY3:
      return getEntityNamesByKey("entity3", userDetail, masterData);

    case CONTENT_TOKEN.LOGGED_USER_ENTITY4:
      return getEntityNamesByKey("entity4", userDetail, masterData);

    case CONTENT_TOKEN.LOGGED_USER_EMAIL: {
      if(userDetail && userDetail.user && !isEmpty(userDetail.user.email)){
        let updatedEmail = "";
        const { email } = enableMasking || {};
        if(email && isCounselorAccessActive){
          updatedEmail = replaceEmailWithStars(userDetail.user.email);
        }else{
          updatedEmail = userDetail.user.email
        }
        const verifyEmailIcon = verifyEmail ? `<img id="verifyEmail" class="${isPrimaryEmailVerified && "pe-none"}" alt="" src="${isPrimaryEmailVerified ? VerifiedIcon : NotVerifiedIcon}" title="${verifyEmail && (isPrimaryEmailVerified ? getLang("dashborad.myProfile.emailIdVerified") : getLang("dashborad.myProfile.emailIdNotVerified"))}"/>` : '';

        const editEmailIcon = (editEmail && EditIcon) ? `<img id="editEmail" alt="" src="${EditIcon}" title="${editEmail && getLang("dashboard.myProfile.editYourEmailId")}"/>` :  ``;

        return `<div class="d-flex align-items-center"><div class="email-text" title="${updatedEmail}">${updatedEmail}</div><div class="verify-change-container">${verifyEmailIcon}${editEmailIcon}</div></div>`;
      }
      return null;
    }

    case CONTENT_TOKEN.LOGGED_USER_MOBILE: {
      if(userDetail && userDetail.user && !isEmpty(userDetail.user.mobileNumber)){
        let updatedMobileNumber = "";
        const { mobile } = enableMasking || {};
        if(mobile && isCounselorAccessActive){
          updatedMobileNumber = replaceMobileWithStars(userDetail.user.mobileNumber);
        }else{
          updatedMobileNumber = userDetail.user.mobileNumber
        }
        const verifyMobileNumberIcon = verifyMobile ? `<img id="verifyMobile" class="${isPrimaryMobileVerified && "pe-none"}" alt="" src="${isPrimaryMobileVerified ? VerifiedIcon : NotVerifiedIcon}" title="${verifyMobile && (isPrimaryMobileVerified ? getLang("dashboard.myProfile.mobileNumberVerified") : getLang("dashboard.myProfile.mobileNumberNotVerified"))}"/>` : "";

        const editMobileNumberIcon = (editMobile && EditIcon) ? `<img id="editMobile" alt="" src="${EditIcon}" title="${editMobile && getLang("dashboard.myProfile.editYourMobileNumber")}"/>` : "";

        return `<div class="d-flex align-items-center"><div class="mobile-text" title="${updatedMobileNumber}">${updatedMobileNumber}</div><div class="verify-change-container">${verifyMobileNumberIcon}${editMobileNumberIcon}</div></div>`;
      }
      return null;
    }

    case CONTENT_TOKEN.LOGGED_USER_FULL_NAME: {
      var names = [];
      if(userDetail && userDetail.user){
        userDetail.user.firstName && names.push(userDetail.user.firstName);        
        userDetail.user.lastName && names.push(userDetail.user.lastName);
      }
      return names.length>0 ? `${names.join(" ")}` : null;
    }

    case CONTENT_TOKEN.LOGGED_USER_FIRST_NAME: {
      return userDetail &&
        userDetail.user &&
        !isEmpty(userDetail.user.firstName) ?
        `${userDetail.user.firstName}` 
        : null;
    }

    case CONTENT_TOKEN.TIME_STAMP: {
      let date = new Date();
      date = date.toUTCString()
      return moment(date).format("YYYYMMDDhhmmss");
    }

    default: {
      return token;
    }
  }
}

export function getEntityNamesByKey(key, userDetail, masterData) {
  let ids =
    userDetail &&
    masterData &&
    !isEmpty(key) &&
    !isEmpty(userDetail.lead) &&
    !isEmpty(userDetail.lead[EntityFieldNameByCriteriaValue[key]])
      ? userDetail.lead[EntityFieldNameByCriteriaValue[key]]
      : null;

  if (!isEmpty(ids)) {
    let entityNames =
      masterData &&
      key &&
      map(
        filter(
          getMasterDataByKey(
            MasterDataCollectionByCriteriaValue[key],
            masterData
          ),
          function (o) {
            return ids.includes(o.id);
          }
        ),
        "name"
      );
    return !isEmpty(entityNames)
      ? "<ul class='course-list'><li>" +
          entityNames.join("</li><li>") +
          "</li></ul>"
      : "";
  }
  return "";
}
export function replaceAllContentTokens(content, userDetail, masterData, clientInfo=null, isCounselorAccessActive=false) {
  let arr = [];
  Object.keys(CONTENT_TOKEN).map((key) => {
    arr = content.split(CONTENT_TOKEN[key]);
    if (arr.length > 1) {
      let val = replaceTokenWithValue(
        CONTENT_TOKEN[key],
        userDetail,
        masterData,
        clientInfo,
        isCounselorAccessActive
      );
      content = arr.join(val);
    }
    return true;
  });
  return content;
}

export function generatePayNowCourseFeeFormData(
  masterData,
  userDetail,
  clientInfo,
  formData,
  promoCodeDetails,
  lead_status_key,
  paymentDetails,
  isCounselorAccessActive,
  counselorDetails
) {
  let data = {};
  const {pricingCriterion="",pricingList=[]} = masterData; 
  let selectedPidsForPayment_cf = !isNil(get(formData, HARD_CODED_FIELD_KEYS.SELECTED_COURSE_FOR_PAYMENT_CF))
    ? formData[HARD_CODED_FIELD_KEYS.SELECTED_COURSE_FOR_PAYMENT_CF]
    : null;
  const selectedCourseIds = checkIfArray(selectedPidsForPayment_cf) ?  pricingList.reduce((result,i)=>{
      if(selectedPidsForPayment_cf.includes(i.id)){
        result.push(i.entityId);
      }
    return result;
  },[]) : [];
  let selectedCourses = checkIfArray(selectedCourseIds) ? selectedCourseIds : getSelectedCourseIds(formData, masterData);
  let totalAmount = !isNil(
    get(formData, HARD_CODED_FIELD_KEYS.TOTAL_DISCOUNTED_AMOUNT_TO_PAY_CF)
  )
    ? get(formData, HARD_CODED_FIELD_KEYS.TOTAL_DISCOUNTED_AMOUNT_TO_PAY_CF)
    : !isNil(get(formData, HARD_CODED_FIELD_KEYS.TOTAL_AMOUNT_TO_PAY_CF))
    ? get(formData, HARD_CODED_FIELD_KEYS.TOTAL_AMOUNT_TO_PAY_CF)
    : getTotalAmount(selectedCourses, userDetail, masterData);
  let paymentModeId =
    formData &&
    !isNil(get(formData, HARD_CODED_FIELD_KEYS.ZERO_AMOUNT_TO_PAY_CF))
      ? !isNil(
          get(
            formData,
            HARD_CODED_FIELD_KEYS.ZERO_AMOUNT_TO_PAYMENT_METHOD_ID_CF
          )
        )
        ? get(
            formData,
            HARD_CODED_FIELD_KEYS.ZERO_AMOUNT_TO_PAYMENT_METHOD_ID_CF
          )
        : 1
      : formData &&
        !!formData[HARD_CODED_FIELD_KEYS.PAYMENT_MODE_CF] &&
        formData[HARD_CODED_FIELD_KEYS.PAYMENT_MODE_CF];
  paymentModeId = Array.isArray(paymentModeId)
    ? paymentModeId[0]
    : paymentModeId;
  if (!totalAmount && false) {
    alert("Sorry, Zero or negative amount to pay is not allowed."); //put this message in english json
    return false;
  } else {
    data[HARD_CODED_FIELD_KEYS.FEES_TYPE] =
      !isNil(document.getElementById(HARD_CODED_FIELD_KEYS.FEES_TYPE_CF)) &&
      !isNil(
        document.getElementById(HARD_CODED_FIELD_KEYS.FEES_TYPE_CF).value
      ) &&
      !isEmpty(
        document.getElementById(HARD_CODED_FIELD_KEYS.FEES_TYPE_CF).value
      )
        ? document.getElementById(HARD_CODED_FIELD_KEYS.FEES_TYPE_CF).value
        : 2;
    if (clientInfo && clientInfo.Info && !!clientInfo.Info.clientId) {
      data["clientId"] = clientInfo.Info.clientId;
    }

    if (clientInfo && clientInfo.Info && !!clientInfo.Info.authToken) {
      data["authToken"] = clientInfo.Info.authToken;
    }
    set(data, "cid", "");
    set(data, "rid", ""); //role id of counselor, it will blank if payment request is from LEAD
    set(data, "lid", "");
    if (
      isCounselorAccessActive &&
      !isNil(counselorDetails) &&
      !!get(counselorDetails, "id")
    ) {
      !!get(counselorDetails, "userRoleId") &&
        set(data, "rid", get(counselorDetails, "userRoleId"));
      !!get(counselorDetails, "id") &&
        set(data, "cid", get(counselorDetails, "id"));
      !!get(userDetail, "lead.id") && set(data, "lid", userDetail.lead.id);
    }
    if (clientInfo && clientInfo.Info && !!clientInfo.Info.source) {
      data["source"] = clientInfo.Info.source;
    }
    if (clientInfo && clientInfo.Info && !!clientInfo.Info.alias) {
      data["alias"] = clientInfo.Info.alias;
    }
    data["param1"] = clientInfo && !!get(clientInfo,`paymentDetails.paymentFormRequestParametersMapping.param1`) &&!isNil(get(userDetail,`${clientInfo.paymentDetails.paymentFormRequestParametersMapping.param1}`)) ? get(userDetail,`${clientInfo.paymentDetails.paymentFormRequestParametersMapping.param1}`) : "";
    /*if (
      clientInfo &&
      clientInfo.Info &&
      !!clientInfo.Info.clientId &&
      !!selectedCourses &&
      !!totalAmount
    ) {
      data["amt"] = totalAmount;
    }*/
    if (
      clientInfo &&
      clientInfo.Info &&
      !!clientInfo.Info.clientId &&
      !!selectedCourses
    ) {
      // data["amt"] = totalAmount;
    }

    if (
      clientInfo &&
      clientInfo.Info &&
      !!clientInfo.Info.clientId &&
      !!selectedCourses
    ) {
      //  data["actualAmount"] = actualTotalAmount;
    }
    if (
      !isNil(paymentDetails) &&
      !isNil(get(paymentDetails, "pId")) &&
      Array.isArray(get(paymentDetails, "pId")) &&
      get(paymentDetails, "pId").length
    ) {
      data["pId"] = get(paymentDetails, "pId");
    }
    if (userDetail && userDetail.user && !!userDetail.user.prn) {
      data[STORAGE_KEY.PRN] = userDetail.user.prn;
    }
    if (userDetail && userDetail.user && !!userDetail.user.email) {
      data["email"] = userDetail.user.email;
    }
    if (userDetail && userDetail.lead && !!userDetail.lead[STORAGE_KEY.APPLICATION_FORM_NUMBER]) {
      data["appFormNumber"] = userDetail.lead[STORAGE_KEY.APPLICATION_FORM_NUMBER];
    }
    data["fatherName"] =
      userDetail && userDetail.user && !!userDetail.user.fathersName
        ? userDetail.user.fathersName
        : "";
    if (userDetail && userDetail.user && !!userDetail.user.mobileNumber) {
      data["mobile"] = userDetail.user.mobileNumber;
    }
    if (userDetail && userDetail.lead && !!userDetail.lead.id) {
      data["leadId"] = userDetail.lead.id;
    }
    if (userDetail && userDetail.user && !!userDetail.user.id) {
      data["userId"] = userDetail.user.id;
    }
    if (userDetail && masterData && !!selectedCourses) {
      data["courses"] = selectedCourses;
    }
    if (!!pricingCriterion) {
      let entityCollectionName = !isNil(masterData) && getMasterDataByKey(`${pricingCriterion.substring(0, 1).toUpperCase()}${pricingCriterion.substring(1)}`,masterData);
      if (userDetail && checkIfArray(selectedCourses) && checkIfArray(entityCollectionName)) {
        data["courseName"] =
          map(
            filter(entityCollectionName, (item) => {
              return selectedCourses.includes(item.id);
            }),
            "name"
          ).join();
      } else {
        data["courseName"] = "";
      }
    } else {
      data["courseName"] = "";
    }
    data["E1Name"] = "";
    const entity1Collection = !isNil(masterData) && getMasterDataByKey(`Entity1`,masterData);
    if (
      userDetail &&
      checkIfArray(get(userDetail, "lead.entity1Id")) &&
      checkIfArray(entity1Collection)
    ) {
      data["E1Name"] = map(
        filter(entity1Collection, (item) => {
          return (userDetail.lead.entity1Id).includes(item.id);
        }),
        "name"
      ).join();
    }

    data["E2Name"] = "";
    
    const entity2Collection = !isNil(masterData) && getMasterDataByKey(`Entity2`,masterData);
    if(pricingCriterion === "entity2"){
      data["E2Name"] = data["courseName"];
    }else if (
      userDetail &&
      checkIfArray(get(userDetail, "lead.entity2Id")) &&
      checkIfArray(entity2Collection)
    ) {
      data["E2Name"] = map(
        filter(entity2Collection, (item) => {
          return (userDetail.lead.entity2Id).includes(item.id);
        }),
        "name"
      ).join();
    }

    data["E3Name"] = "";
    const entity3Collection = !isNil(masterData) && getMasterDataByKey(`Entity3`,masterData);
    if(pricingCriterion === "entity3"){
      data["E3Name"] = data["courseName"];
    }else if (
      userDetail &&
      checkIfArray(get(userDetail, "lead.entity3Id")) &&
      checkIfArray(entity3Collection)
    ) {
      data["E3Name"] = map(
        filter(entity3Collection, (item) => {
          return (userDetail.lead.entity3Id).includes(item.id);
        }),
        "name"
      ).join();
    } 

    data["E4Name"] = "";
    const entity4Collection = !isNil(masterData) && getMasterDataByKey(`Entity4`,masterData);
    if(pricingCriterion === "entity4"){
      data["E4Name"] = data["courseName"];
    }else if (
      userDetail &&
      checkIfArray(get(userDetail, "lead.entity4Id")) &&
      checkIfArray(entity4Collection)
    ) {
      data["E4Name"] = map(
        filter(entity4Collection, (item) => {
          return (userDetail.lead.entity4Id).includes(item.id);
        }),
        "name"
      ).join();
    }
    if (paymentModeId) {
      data["paymentModeId"] = paymentModeId;
    }
    data["promoCode"] =
      promoCodeDetails && !!promoCodeDetails["promoCode"]
        ? promoCodeDetails["promoCode"]
        : "";
    let mappedSuccessAndFailStatus = getMappedSuccessAndFailStatus(
      paymentModeId,
      clientInfo,
      lead_status_key
    );
    if (
      !mappedSuccessAndFailStatus ||
      !mappedSuccessAndFailStatus.fStatus ||
      !mappedSuccessAndFailStatus.sStatus
    ) {
      logError(
        "Mapping of selected payment mode and it's Success and Fail status is not configured in clientconfig json."
      );
    } else {
      //mappedSuccessAndFailStatus;
      let successStatusDetails =
        masterData &&
        clientInfo &&
        getStatusIdAndReasonIds(
          mappedSuccessAndFailStatus.sStatus,
          masterData,
          clientInfo
        );
      if (
        masterData &&
        clientInfo &&
        successStatusDetails &&
        !!successStatusDetails.statusId &&
        !!successStatusDetails.reasonId
      ) {
        data["sStatus"] = successStatusDetails.statusId;
        data["sReason"] = successStatusDetails.reasonId;
      }
      let failStatusDetails =
        masterData &&
        clientInfo &&
        getStatusIdAndReasonIds(
          mappedSuccessAndFailStatus.fStatus,
          masterData,
          clientInfo
        );
      if (
        masterData &&
        clientInfo &&
        failStatusDetails &&
        !!failStatusDetails.statusId &&
        !!failStatusDetails.reasonId
      ) {
        data["fStatus"] = failStatusDetails.statusId;
        data["fReason"] = failStatusDetails.reasonId;
      }
      
      let pendingStatusDetails =
        masterData &&
        clientInfo &&
        getStatusIdAndReasonIds(
          mappedSuccessAndFailStatus.pStatus,
          masterData,
          clientInfo
        );
      if (
        masterData &&
        clientInfo &&
        pendingStatusDetails &&
        !!pendingStatusDetails.statusId &&
        !!pendingStatusDetails.reasonId
      ) {
        data["pStatus"] = pendingStatusDetails.statusId;
        data["pReason"] = pendingStatusDetails.reasonId;
      }else{
        data["pStatus"] = "";
        data["pReason"] = "";
      }

      let partPaymentStatusDetails =
      masterData &&
      clientInfo &&
      getStatusIdAndReasonIds(
        mappedSuccessAndFailStatus.ppStatus,
        masterData,
        clientInfo
      );
      const {statusId, reasonId} = partPaymentStatusDetails || {}
      if (
        masterData &&
        clientInfo &&
        statusId &&
        reasonId
      ) {
        data["ppStatus"] = statusId;
        data["ppReason"] = reasonId;
      }else{
        data["ppStatus"] = "";
        data["ppReason"] = "";
      }
    }

    /* Partial Payment Amount And Partial Payment Key */
    const installmentAmountToPay_cf = get(
      formData,
      HARD_CODED_FIELD_KEYS.INSTALLMENT_AMOUNT_TO_PAY_CF
    );
    if (!!installmentAmountToPay_cf) {
      const payment_txn_success_status = getPaymentTransactionSuccessStatus(clientInfo);
      data[STORAGE_KEY.PART_PAYMENT_AMOUNT_KEY] = base64Encode(installmentAmountToPay_cf);
      const installmentPaymentDetails = getInstallmentPaymentDetails(
        get(userDetail, "paymentDetails"),
        FEES_TYPE.COURSE_FEES,
        payment_txn_success_status
      );
      data[STORAGE_KEY.PART_PAYMENT_KEY] = "";
      if (checkIfArray(installmentPaymentDetails)) {
        const {balanceAmount=0,partPaymentKey=null} = installmentPaymentDetails[0] || {}
        data[STORAGE_KEY.PART_PAYMENT_KEY] = balanceAmount>0 ?  partPaymentKey : "";
      }
    }

    let existingINST = !!OfflineStorage.getItem(
      STORAGE_KEY.INST,
      STORAGE_TYPE.LOCAL
    )
      ? OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)
      : null;
    if (
      masterData &&
      !!masterData.applicationFormCriteria &&
      !!existingINST &&
      !isEmpty(existingINST)
    ) {
      data[STORAGE_KEY.APP_CRITERION] = parseInt(existingINST);
    } else {
      data[STORAGE_KEY.APP_CRITERION] = "";
    }
    if(masterData && pricingCriterion){
      const collection = getMasterDataByKey(capitalize(pricingCriterion),masterData); 
      if(checkIfArray(collection)){
        const entityObj = collection.find(ele => data.courses.includes(ele.id));
        if(checkIfObject(entityObj)){
          data[STORAGE_KEY.MAPPED_CODE] = entityObj.mappedCode;
        }
      }
    }
    /**
     * UKC details added for multi lead journey
     */
    let UKC_Details = getUKCDetails(masterData, userDetail);
    UKC_Details = UKC_Details
      ? UKC_Details
      : {
          [STORAGE_KEY.UKC_URL_PARAMETER_KEY]: "",
          [STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY]: "",
        };
    Object.assign(data, UKC_Details);
    /* END of UKC update */
    if (
      userDetail &&
      userDetail.user &&
      (!!userDetail.user.firstName || !!userDetail.user.lastName)
    ) {
      let names = [];
      !!userDetail.user.firstName && names.push(userDetail.user.firstName);
      !!userDetail.user.lastName && names.push(userDetail.user.lastName);
      data["name"] = names.join(" ");
    }
    if(checkIfObject(formData) && paymentDetails){
      let updatedVal = formData[HARD_CODED_FIELD_KEYS.CURRENCY_FIELD_CF] || "";
      if(checkIfObject(updatedVal) && !Array.isArray(updatedVal)){
        updatedVal = updatedVal.ISOCode
      }
      updatedVal = updatedVal && updatedVal.toString();
      if(updatedVal === "INR"){
        updatedVal = "";
      }
      data["currency"] = updatedVal;
    }
    return data;
  }
}
export function renameAllProprtyNamebyFirstLetterLowerCase(JSON_Data) {
  Object.keys(JSON_Data).forEach((item) => {
    checkIfArray(JSON_Data[item]) && JSON_Data[item].forEach((citem) => {
      Object.keys(citem).forEach((cItemKey) => {
        if (cItemKey !== cItemKey.charAt(0).toLowerCase() + cItemKey.slice(1)) {
          citem[cItemKey.charAt(0).toLowerCase() + cItemKey.slice(1)] =
            citem[cItemKey];
          delete citem[cItemKey];
        }
      });
    });
  });
  return JSON_Data;
}

export function getUKCDetails(masterData, userDetail) {
  let outputObj = {};
  if (
    !isNil(masterData) &&
    !isNil(get(masterData, STORAGE_KEY.UNIQUE_KEY_CRITERIA)) &&
    !isEmpty(get(masterData, STORAGE_KEY.UNIQUE_KEY_CRITERIA))
  ) {
    set(
      outputObj,
      STORAGE_KEY.UKC_URL_PARAMETER_KEY,
      get(masterData, STORAGE_KEY.UNIQUE_KEY_CRITERIA)
    );
    if (!isNil(userDetail)) {
      let uniqueKeyFieldName =
        EntityFieldNameByCriteriaValue[
          get(outputObj, STORAGE_KEY.UKC_URL_PARAMETER_KEY)
        ];
      if (
        !isNil(get(userDetail, `lead.${uniqueKeyFieldName}`)) &&
        Array.isArray(get(userDetail, `lead.${uniqueKeyFieldName}`)) &&
        get(userDetail, `lead.${uniqueKeyFieldName}`).length &&
        get(userDetail, `lead.${uniqueKeyFieldName}`)[0]
      ) {
        set(
          outputObj,
          STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
          get(userDetail, `lead.${uniqueKeyFieldName}`)[0]
        );
      }
    }
  }
  return Object.keys(outputObj).length ? outputObj : null;
  //uniqueKeyCriteriaValue=238&uniqueKeyCriteria=entity2
}

/**
 * Convert object to search string paramters
 */
export function convertObjectToSearchParamString(data) {
  if (data && Object.keys(data).length && !isAccessTokenTableKeyEnabled())
    return Object.keys(data)
      .map((key) => `${key}=${encodeURIComponent(data[key])}`)
      .join("&");
  return "";
}

export function generateUniqueFieldByInputType(formConfig) {
  let collectionByInputType = {};
  forEach(get(formConfig, "steps"), function (step, stepKey) {
    forEach(get(step, "config.panels"), function (panel, panelKey) {
      forEach(get(panel, "fields"), function (field, fieldKey) {
        const fieldType =
          isNil(get(field, "type")) || isLodashEmpty(get(field, "type"))
            ? "text"
            : get(field, "type");
        const visible =
          isNil(get(field, "visible")) || !!get(field, "visible")
            ? true
            : false;
        if (
          !!fieldType &&
          // /^[a-zA-Z]+$/.test(fieldType) &&
          visible &&
          fieldType !== FORM_INPUT_UI.HIDDEN &&
          !isNil(get(field, `title`)) &&
          !isLodashEmpty(get(field, `title`))
        ) {
          //visible field found then add in collection
          if (
            !has(collectionByInputType, fieldType) ||
            isNil(has(collectionByInputType, fieldType))
          ) {
            set(collectionByInputType, fieldType, [
              { fieldKey, title: get(field, `title`) },
            ]);
          } else {
            if (
              Array.isArray(collectionByInputType[fieldType]) &&
              isNil(
                find(collectionByInputType[fieldType], { fieldKey: fieldKey })
              )
            ) {
              set(
                collectionByInputType,
                fieldType,
                collectionByInputType[fieldType].concat({
                  fieldKey,
                  title: get(field, `title`),
                })
              );
            }
          }
        }
      });
    });
  });
  return keys(collectionByInputType).length > 0 ? collectionByInputType : null;
}

export const renameFileByToken = (filename, userDetail, masterData) => {
  filename = replaceAllContentTokens(filename, userDetail, masterData);
  return filename;
};
export function replaceTokenOfPDFAPIRequest(
  requestObject,
  leadId,
  clientInfo,
  IsPaymentDone,
  IsEmailSend=false,
  feesType,
  templateHTMLFileName,
  receiptNumber,
  isDebugMode=false,
  userDetails=null,
  masterData=null
) {
  forEach(Object.keys(requestObject), (key) => {
    switch (requestObject[key]) {
      case "$SOURCE$":
        set(requestObject, key, get(clientInfo, `Info.source`));
        break;
      case "$AUTH_TOKEN$":
        set(requestObject, key, get(clientInfo, `Info.authToken`));
        break;
      case "$INST$":
        let instValue = getApplicationFormCriteriaValue(userDetails,masterData) || OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL);
        !!instValue
          ? set(
              requestObject,
              key,
              parseInt(instValue)
            )
          : unset(requestObject, key);
        break;
      case "$UKC$":
        let ukcValue = getUniqueKeyCriteriaValue(userDetails,masterData) || OfflineStorage.getItem(STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,STORAGE_TYPE.LOCAL);
        !!ukcValue
          ? set(
              requestObject,
              key,
              parseInt(ukcValue)
            )
          : unset(requestObject, key);
        break;
      case "$LEAD_ID$":
        set(requestObject, key, leadId);
      break;
      case "$IS_PAYMENT_DONE$":
        set(requestObject, key, IsPaymentDone);
      break;
      case "$IS_EMAIL_SEND$":
        set(requestObject, key, IsEmailSend);
      break;
      case "$FEES_TYPE$":
        set(requestObject, key, feesType);
      break;
      case "$TEMPLATE_HTML$":
        set(requestObject, key, templateHTMLFileName);
      break;
      case "$IS_DEBUG_MODE$":
        set(requestObject, key, isDebugMode);
      break;
      case "Payment_Receipt_$RECEIPT_NUMBER$":
        const receiptNumberValue = !!receiptNumber?receiptNumber.replace(/[,\/!@#$%^&*'\\;():|[\]+ ]/g,'_'):receiptNumber;
        set(requestObject, key, `Payment_Receipt_${receiptNumberValue}`);
      break;
      case "$RECEIPT_NUMBER$":
        set(requestObject, key, `${receiptNumber}`);
      break;
      default: {
      }
    }
  });
  return requestObject;
  /**
   * Sample object
   * 
   * "apiRequest": {
        "AuthToken": "$AUTH_TOKEN$",
        "IsEmailSend": true,
        "IsPaymentDone": false,
        "Source": "$SOURCE$",
        "inst": "$INST$",
        "leadId": "$LEAD_ID$",
        "ukc": "$UKC$",
        "pdfNameToken": "OFFER_LETTER",
        "templateFileName": "offerLetter_template.html",
        "emailTemplateName": "ApplicationFormEmailPdf"
    }
   */
}

export const addURLParametersAndRoute = (params, history) => {
  if (!!history && params && Object.keys(params).length) {
    const cid = OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ID,STORAGE_TYPE.LOCAL);
    const lid = !!cid ? OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID,STORAGE_TYPE.LOCAL) : null;
    let searchParams = {
      [STORAGE_KEY.PRN]: !!OfflineStorage.getItem(
        STORAGE_KEY.PRN,
        STORAGE_TYPE.LOCAL
      )
        ? OfflineStorage.getItem(STORAGE_KEY.PRN, STORAGE_TYPE.LOCAL)
        : undefined,
      [STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY]: !!OfflineStorage.getItem(
        STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
        STORAGE_TYPE.LOCAL
      )
        ? OfflineStorage.getItem(
            STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
            STORAGE_TYPE.LOCAL
          )
        : undefined,
      [STORAGE_KEY.INST]: !!OfflineStorage.getItem(
        STORAGE_KEY.INST,
        STORAGE_TYPE.LOCAL
      )
        ? OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)
        : undefined,
      [STORAGE_KEY.COUNSELOR_ID]: cid || undefined,
      [STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID]: lid || undefined,
    };
    if(!!OfflineStorage.getItem(STORAGE_KEY.ID, STORAGE_TYPE.LOCAL)) {
      searchParams = {
        ...searchParams,
        [STORAGE_KEY.ID]: OfflineStorage.getItem(STORAGE_KEY.ID, STORAGE_TYPE.LOCAL)
      }
    }
    forEach(searchParams, (value, key) => {
      !value && unset(searchParams, key);
    });

    Object.keys(searchParams).length &&
      Object.assign(params, {
        search: "?" + convertObjectToSearchParamString(searchParams),
      });
  }
  try {
    history.push(params);
  } catch (error) {
    console.log('Error in addURLParametersAndRoute', error);
  }
};
const replaceTokenInObject= (requestParams,userDetail,masterData,masterDataMapping,requestParamsDataType)=>{
  requestParams=forIn(requestParams,(requestValue,requestKey)=>{
    if(has(userDetail,requestValue)){
     set(requestParams,requestKey,!!get(userDetail,requestValue)?get(userDetail,requestValue):"");
     if(!!get(userDetail,requestValue) && !!masterDataMapping && !isNil(get(masterDataMapping,requestKey))){
        const collection = !isNil(masterData) && getMasterDataByKey(masterDataMapping[requestKey],masterData);
        if(checkIfArray(collection)){
          const mappedMasterDataItem=find(collection,{id:requestParams[requestKey]});
          !!mappedMasterDataItem && !isNil(mappedMasterDataItem.name) && set(requestParams,requestKey,mappedMasterDataItem.name);
        }
     }
    }
    if(!isNil(requestParamsDataType) && has(requestParamsDataType,requestKey)  && !isEmpty(requestParamsDataType,requestKey)&& !isNil(requestParamsDataType,requestKey) && requestParamsDataType[requestKey]=== "jsonString"){
      const nestedObject=replaceTokenInObject(requestParams[requestKey],userDetail,masterData,masterDataMapping,requestParamsDataType);
      !!nestedObject && Object.keys(nestedObject).length>0 &&  set(requestParams,requestKey,JSON.stringify(nestedObject));     
     }
  });
  return requestParams;
}
export const callAPIActions =  async (afterPaymentActions, isPaymentSucces, masterData, userDetail, clientInfo, IsPaymentDone) => {
 await afterPaymentActions.forEach(async (element) => {
    if(!!element.action &&  !!element.actionType && !element.triggerOnlyOnSuccess || (!!element.triggerOnlyOnSuccess && !!isPaymentSucces)){
      const API_Instance=!!element.is_API_Third_Party?THIRD_PARTY_API:API;
      switch(element.actionType){
        case "api":{
          let requestParams=!!element.requestParams && Object.keys(element.requestParams).length>0?element.requestParams:null;
          if(!!requestParams){
            requestParams=replaceTokenInObject(requestParams,userDetail,masterData,element.masterDataMapping,element.requestParamsDataType);
            requestParams = replaceTokenOfPDFAPIRequest(
              requestParams,
              get(userDetail,`lead.id`),
              clientInfo,
              IsPaymentDone || isPaymentSucces
            );
            let config = {
              method: !!element.type && !!REQUEST_TYPE[element.type]?element.type:REQUEST_TYPE.get,
              url: element.action,
              data : JSON.stringify(requestParams),
              mode:"no-cors",
              headers:{
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin': '*',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
                withCredentials: true
              }
            };
            if(!!element.contentType){
              set(config.headers,'Content-Type',element.contentType)
            }
            if(!!config && !!config.method && !!config.url){

              return await API_Instance(config).then((response) => {
                console.log(response);
                return response;
              }, (error) => {
                console.log(error);
                return error;
              });
            }
          }
        }break;
        default:{
          return true;
        }
      }
    }
  });
}

export const callAPIButtonAction =  async (actionConfig, isPaymentSucces, masterData, userDetail, clientInfo, IsPaymentDone) => {
  if(!!actionConfig.action &&  !!actionConfig.actionType && !actionConfig.triggerOnlyOnSuccess || (!!actionConfig.triggerOnlyOnSuccess && !!isPaymentSucces)){
    const API_Instance=!!actionConfig.is_API_Third_Party?THIRD_PARTY_API:API;
    switch(actionConfig.actionType){
      case "api":
      case "hyperLinkThroughAPI":
      case "form":{
        let requestParams=!!actionConfig.requestParams && Object.keys(actionConfig.requestParams).length>0?actionConfig.requestParams:null;
        if(!!requestParams){
          forIn(requestParams,(requestValue,requestKey)=>{
            if(has(userDetail,requestValue)){
              set(requestParams,requestKey,!!get(userDetail,requestValue)?get(userDetail,requestValue):"");            
              if(actionConfig?.masterDataMapping && actionConfig.masterDataMapping[requestKey] != undefined){
                const collection = !isNil(masterData) && getMasterDataByKey(actionConfig.masterDataMapping[requestKey],masterData);
                if(checkIfArray(collection)){
                  const mappedMasterDataItem=find(collection,{id:Number(requestParams[requestKey].toString())});
                  const masterDataValueFieldMapping = get(actionConfig, `masterDataValueFieldMapping.${requestKey}`)
                  if(masterDataValueFieldMapping){
                    const masterValues = requestParams[requestKey];
                    if(checkIfArray(masterValues) && masterValues.length > 1){
                      let mappedMasterDataItem = reduce(collection, (result, item) => {
                        if(masterValues.includs(item.id)){
                          result.push(item);
                        }
                        return result
                      })
                      mappedMasterDataItem  = mappedMasterDataItem.map((ele) => {
                        return ele[masterDataValueFieldMapping[0]]
                      })
                      if(checkIfArray(masterDataValueFieldMapping)){
                        !isNil(mappedMasterDataItem[masterDataValueFieldMapping]) && set(requestParams,requestKey,mappedMasterDataItem);
                      }else{
                        !isNil(mappedMasterDataItem[masterDataValueFieldMapping]) && set(requestParams,requestKey,mappedMasterDataItem.toString());
                      }
                    }else{
                      if(checkIfArray(masterDataValueFieldMapping)){
                        !isNil(mappedMasterDataItem[masterDataValueFieldMapping]) && set(requestParams,requestKey,[mappedMasterDataItem[masterDataValueFieldMapping[0]]]);
                      }else{
                        !isNil(mappedMasterDataItem[masterDataValueFieldMapping]) && set(requestParams,requestKey,mappedMasterDataItem[masterDataValueFieldMapping]);
                      }
                    }
                  }else{
                    !!mappedMasterDataItem && !isNil(mappedMasterDataItem.name) && set(requestParams,requestKey,mappedMasterDataItem.name);
                  }
              }
            }
            }
          });
          requestParams = replaceTokenOfPDFAPIRequest(
            requestParams,
            get(userDetail,`lead.id`),
            clientInfo,
            IsPaymentDone,
            null,
            null,
            null,
            null,
            null,
            userDetail,
            masterData
          )
          //actionConfig.action=`${actionConfig.action}1`;
          if(actionConfig.actionType==="api"){
            if(!actionConfig.type || actionConfig.type==="get"){
              return await API_Instance.get(actionConfig.action,{params:requestParams}).then((response) => {
                console.log(response);
                return response;
              }, (error) => {
                console.log(error);
                return error;
              });
            }else if(actionConfig.type==="post"){
              return await API_Instance.post(actionConfig.action,requestParams).then((response) => {
                console.log(response);
                return response;
              }, (error) => {
                console.log(error);
                return error;
              });
            }
          }else if(!!requestParams && Object.keys(requestParams).length>0 && !!actionConfig.action && actionConfig.actionType==="form"){
            generateFormAndSubmit({
              id: "custom-form",
              type:actionConfig.type,
              url: actionConfig.action,
              formData: requestParams,
            });
          } else if(actionConfig.actionType === "hyperLinkThroughAPI"){
            if(!actionConfig.type || actionConfig.type==="get"){
              return await API_Instance.get(actionConfig.action,{params:requestParams}).then((response) => {
                const {status} = response || {};
                if(status===201 || status===200){
                  const url = get(response, `data.${actionConfig.URLApiKey}`);
                  url && createAnchorTag(url, "_blank", "", true)
                  return false;
                }else{
                  console.log("URL not generated.");
                  return true;
                }
              }, (error) => {
                console.log("URL not generated.");
                return true;
              });
            }else if(actionConfig.type==="post"){
              return await API_Instance.post(actionConfig.action,requestParams).then((response) => {
                const {status} = response || {};
                if(status===201 || status===200){
                  const url = get(response, `data.${actionConfig.URLApiKey}`);
                  url && createAnchorTag(url, "_blank", "", true)
                  return false;
                }else{
                  console.log("URL not generated.");
                  return true;
                }
              }, (error) => {
                console.log("URL not generated.");
                return true;
              });
            }
          }         
        }
      }break;
      case "hyperlink":{
        let requestParams=!!actionConfig.requestParams && Object.keys(actionConfig.requestParams).length>0?actionConfig.requestParams:null;
        if(!!requestParams){
          forIn(requestParams,(requestValue,requestKey)=>{
            if(has(userDetail,requestValue)){
              set(requestParams,requestKey,!!get(userDetail,requestValue)?get(userDetail,requestValue):"");            
              if(actionConfig?.masterDataMapping && actionConfig.masterDataMapping[requestKey] != undefined){
                const collection = !isNil(masterData) && getMasterDataByKey(actionConfig.masterDataMapping[requestKey],masterData);
                if(checkIfArray(collection)){
                  const mappedMasterDataItem=find(collection,{id:Number(requestParams[requestKey].toString())});
                  !!mappedMasterDataItem && !isNil(mappedMasterDataItem.name) && set(requestParams,requestKey,mappedMasterDataItem.name);
                }
              }
            }
          });
        }
        let url = actionConfig.action && actionConfig.action; 
        if(url){
          if(actionConfig.type && actionConfig.type === "multipleParamsShowInURL"){
            let newUrl = new URL(url);
            for (const [key, value] of Object.entries(requestParams)) {
              newUrl.searchParams.set(key, value);
            }
            createAnchorTag(newUrl.href, "_blank", "", true)
          }else{
            const encodedBase64 = checkIfObject(requestParams) && btoa(JSON.stringify(requestParams));
            url = url + encodedBase64;
            createAnchorTag(url, "_blank", "", true)
          }
          
        }else{
          console.error("Url is not provided in the formConfig");
        }
        
      }break;
      default:{
        return true;
      }
    }
  }
 }

 export const generateTempAnchorTagAndClick=(params)=>{
    // Create anchor element. 
    var a = document.createElement('a');  
            
    // Set the href property. 
    a.href = params.href;  
    // Set the href property. 
    a.target = !!params && !!params.target?params.target:"_blank";  
      
    // Append the anchor element to the body. 
    document.body.appendChild(a);  
    setTimeout(function(aTag){ aTag.click();aTag.remove(); }, 500, a);
 }

 /**
 * @author Krushna Sharma
 * @creationDate 03 Apr 2021
 * @description Checks if the passed parameter is not null, is Array and has length > 0
 * @param {Array} arrayParam Array parameter to be checked
 */
 export const checkIfArray = (arrayParam) => {
  return !!(arrayParam && isArray(arrayParam) && arrayParam.length > 0 && !isNil(arrayParam[0]));
 }

 /**
 * @author Krushna Sharma
 * @creationDate 03 Apr 2021
 * @description Removes those slot from the slots list which has expired. 
 * @param {Array} slotsList array of slots
 */
  export const removeExpiredSlot = (slotsList,selectedSlotId=null) => {
    let validSlots = []
     if(checkIfArray(slotsList)){
       let today = moment();
       validSlots=slotsList.filter(slots=>{
         return (today.isBefore(moment(ConvertToLocalUsingMoment(slots.expiryDate,'YYYY-MM-DDTHH:mm:ss.SSSS'))) && (!!slots.availableSlotCount) && (isNil(slots.isActive) || !!slots.isActive)) || (!!selectedSlotId && slots.id===selectedSlotId)
       })
     }
     return validSlots
  }
  
  /**
  * @author Pradip Udavant
  * @creationDate 09 Apr 2021
  * @description Check slot is expired
  * @param slot object
  */
  export const isSlotExpired = (slot) => {
     if(!isNil(slot)){
       let today = moment();
       return !today.isBefore(moment(ConvertToLocalUsingMoment(slot.expiryDate,'YYYY-MM-DDTHH:mm:ss.SSSS')));
     }
     return true;
  }

  
  export const getReceiptURLByReceiptNumber=(receiptNumber,templatePdfs, mappedClientAlias = STORAGE_KEY.ALIAS)=>{   
    let url="";    
    receiptNumber=!!receiptNumber ? receiptNumber.replace(/[,\/!@#$%^&*'\\;():|[\]+ ]/g,'_') : receiptNumber;//replace all special char to _
    if(!!receiptNumber &&  !!templatePdfs && (!!get(templatePdfs,`Payment_Receipt_${receiptNumber}`) || !!get(templatePdfs,`${receiptNumber}`))){
      url=getMediaLiveURL(templatePdfs[`Payment_Receipt_${receiptNumber}`] || templatePdfs[`${receiptNumber}`], mappedClientAlias);
   }    
    return !!url?url:undefined;
  }
  export const checkAndGetDefaultPromocode = (
    userDetail = null,
    masterData = null,
    feesType = null
  ) => {
    if (isNil(userDetail) || isNil(masterData) || isNil(feesType)) {
      return null;
    }
    const indexedKeyValueAttributes =
      userDetail?.metaData?.indexedKeyValueAttributes;
    if (
      checkIfArray(indexedKeyValueAttributes)
    ) {
      const promoCodeAppliedOn = DEFAULT_PROMO_CODE_MASTER_KEY[feesType];
      const masterKeysCollection = !isNil(masterData) && getMasterDataByKey(`MasterKeys`,masterData);
      let promoCodeAppliedOnMasterKeyObj = checkIfArray(masterKeysCollection) 
          ? find(masterKeysCollection, { name: promoCodeAppliedOn })
          : null;
  
      const indexedMetaData =
        !isNil(promoCodeAppliedOnMasterKeyObj) &&
        !!get(promoCodeAppliedOnMasterKeyObj, `id`)
          ? indexedKeyValueAttributes.find(
              (ele) => ele.keyId === promoCodeAppliedOnMasterKeyObj.id
            )
          : null;
      if (!isNil(indexedMetaData) && !!indexedMetaData.textValue) {
        return indexedMetaData?.textValue;
      }
    }
    return null;
  };

  export const checkIfObject = (obj) => {
    return !!(
      obj &&
      typeof obj === "object" &&
      Object.keys(obj) &&
      !Array.isArray(obj) &&
      Object.keys(obj).length
    );
  }

/**
 * @author Arnab Das
 * @creationDate 24 Feb 2022
 * @description Checks if the passed values are truly equal
 * @param {val1} any Array or Object or String or Number
 * @param {val2} any Array or Object or String or Number
 */
  export const checkIfEquals = (val1, val2) => {
    if(Array.isArray(val1) && Array.isArray(val2)) return isEqual(val1.sort(), val2.sort())
    else return isEqual(val1, val2)
  }

  export const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  export const metaDataUpdate = (masterData, userDetail, metaDataType, masterKey, metaDataValue, newMetaRequestObject) => {
    const metaDataObj = get(userDetail,`metaData.${metaDataType}`) || [];
    const masterKeysCollection = !isNil(masterData) && getMasterDataByKey(`MasterKeys`,masterData);
    const masterKeyObj=checkIfArray(masterKeysCollection) ? find(masterKeysCollection,item=>{return item.name===masterKey;}):null;
    if(Array.isArray(metaDataObj)){
      let keyMetaData=find(metaDataObj,{keyId:masterKeyObj.id});
      if(metaDataType === META_DATA_TYPE.INDEX_META_TYPE) {
        let valueType = checkMasterKeyValueType(masterKeyObj.type)
        if(isNil(keyMetaData)){
          keyMetaData = {
            keyId: masterKeyObj.id,
            leadId: userDetail.lead.id,
            [valueType]: metaDataValue,
            id: 0
          }
          metaDataObj.push(keyMetaData)
          set(userDetail,`metaData.${metaDataType}`,metaDataObj);
        }
        else {
          // edit value of indexedKeyValueAttributes
          
          let keyObj = metaDataObj.findIndex(item => item.keyId === masterKeyObj.id)
          metaDataObj[keyObj][valueType] = metaDataValue
          if(newMetaRequestObject) {
            newMetaRequestObject[META_DATA_TYPE.INDEX_META_TYPE][keyObj][valueType] = metaDataValue
            return newMetaRequestObject
          }
        }
      }
      else if (metaDataType === META_DATA_TYPE.NON_INDEX_META_TYPE) {
        if(isNil(keyMetaData)) {
          keyMetaData = {
            keyId: masterKeyObj.id,
            leadId: userDetail.lead.id,
            value: metaDataValue,
            id: 0
          }
          metaDataObj.push(keyMetaData)
          set(userDetail,`metaData.${metaDataType}`,metaDataObj);
        }
        else {
          // edit value of nonIndexedKeyValueAttributes
          let keyObj = metaDataObj.findIndex(item => item.keyId === masterKeyObj.id)
          let keyValueObj = isJsonString(metaDataObj[keyObj].value) && JSON.parse(metaDataObj[keyObj].value)
          keyValueObj && Object.assign(keyValueObj, {masterKey: metaDataValue})
          metaDataObj[keyObj].value = JSON.stringify(keyValueObj)
        }
      }
    }

  }

  export const checkMasterKeyValueType = (type) => {
    switch (type) {
      case MASTER_KEYS.TYPE.DOUBLE: return META_DATA_INDEXED_VALUE_TYPE.DOUBLE //"doubleValue"
      case MASTER_KEYS.TYPE.INTEGER: return META_DATA_INDEXED_VALUE_TYPE.INTEGER //"integerValue"
      case MASTER_KEYS.TYPE.BOOLEAN: return META_DATA_INDEXED_VALUE_TYPE.BOOLEAN //"boolValue"
      case MASTER_KEYS.TYPE.TEXT: return META_DATA_INDEXED_VALUE_TYPE.STRING //"textValue"
      case MASTER_KEYS.TYPE.DATE: return META_DATA_INDEXED_VALUE_TYPE.DATE //"dateValue"
      default: 
    }
  }

  export const checkIfMasterKeyPresent = (masterData, masterKeyName) => {
    const masterKeysCollection = !isNil(masterData) && getMasterDataByKey(`MasterKeys`,masterData);
    return checkIfArray(masterKeysCollection) ? find(masterKeysCollection,item=>{return item.name===masterKeyName}):null;
  }
/**
 * 
 * @param {Array} arr 
 * @returns true/false - true if given array will in 0,1,2,3,4 and false if 0,3 
 */
  export const isArrayValueInSequence=(arr)=>{
    if(!!arr && Array.isArray(arr) && arr.length>0)
    {
      arr.sort();
      return !arr.some((value,index,collection)=>{ return index===0 && value>0 || index>0 && value-collection[index-1]!==1 })
    }
    return false;
  }


  export const getStepWiseMandatoryFields=(formConfig,clientInfo,masterData,formData)=>{
    let output = {};
    const leadStatuses = !isNil(masterData) && getMasterDataByKey(`LeadStatuses`,masterData);
    const stepwiseMandatoryFields = checkMandatorySteps(formConfig, true);
    formConfig && clientInfo && masterData && forEach(get(formConfig, "steps"), function (step,stepIndex) {
        const statusMappingConfig=find(clientInfo.LeadstatusDetails.applicationFormStatusMapping,{key:step.lead_status_key});
        const mappedStatusObject= !!statusMappingConfig?.LeadStatusTitle && !!masterData && checkIfArray(leadStatuses) && find(leadStatuses,{name:statusMappingConfig.LeadStatusTitle});
      const mappedStatusId= !!mappedStatusObject && mappedStatusObject?.id
      let mandatoryFields=[];
      if(step?.id && stepwiseMandatoryFields[step.id]) {
        mandatoryFields = stepwiseMandatoryFields[step.id]
      } else {
        forEach(get(step, "config.panels"), function (panel) {
          let isFormConfigChanged = false;
          let conditionallyRemovedFields = {};
          let {required} = panel;
          required=uniq(required);
          if(required && Array.isArray(required) && required.length>0){
              mandatoryFields=reduce(required,(result, item)=>{
                if(get(panel,`fields[${item}]`)){
                  /*console.log(`getStepWiseMandatoryFields - item`,item);
                  let formConfigChangedOutput = updateDependenciesRefComponents(
                    get(panel,`fields[${item}]`),
                    formData && get(formData,item),
                    get(step, "config")
                  );
                  isFormConfigChanged =
                    !isNil(formConfigChangedOutput) &&
                    !isNil(get(formConfigChangedOutput, "isFormConfigChanged"))
                      ? get(formConfigChangedOutput, "isFormConfigChanged")
                      : isFormConfigChanged;
                  conditionallyRemovedFields =
                    !isNil(formConfigChangedOutput) &&
                    !isNil(get(formConfigChangedOutput, "conditionallyRemovedFields"))
                      ? get(formConfigChangedOutput, "conditionallyRemovedFields")
                      : conditionallyRemovedFields;*/
                  /*if(!get(conditionallyRemovedFields,item) && get(panel,`fields[${item}].visible`)){
                    result.push({key:item,title:get(panel,`fields[${item}].title`)});
                  }*/
                  if(get(panel,`fields[${item}].visible`)){
                    result.push({key:item,title:get(panel,`fields[${item}].title`)});
                  }
                }
                return result;
              },mandatoryFields);        
          }
        });
      }
      !!mandatoryFields && Array.isArray(mandatoryFields) && mandatoryFields.length>0 && set(output,mappedStatusId,{mandatoryFields,stepIndex,stepHeading:get(step,`config.heading`)});
    });
    return !!output && Object.keys(output).length>0 ? output : null;
  }

  export const checkMandatorySteps = (config, isForMandatoryPopup = false) => { // checks all mandatory fields in config
    return reduce(config.steps,function(stepResult,stepData){
      let selectConditionlRefFieldsArr = [];
      let fieldKeyMappedWithTitle = {};
      let stepItems = reduce(stepData.config.panels,function(panelResult, panelData){
        let hideRequiredPanelsFieldArr = [];
        const panelDataRequired = panelData.required;
        let panelDataFields = checkIfObject(panelData.fields) && Object.keys(panelData.fields);
        if(checkIfArray(panelDataFields)){
          reduce(panelDataFields,function(requiredResult,fieldKey){
            const title = get(panelData,`fields[${fieldKey}].title`);
            fieldKeyMappedWithTitle = {
              ...fieldKeyMappedWithTitle,
              [fieldKey]: title
            }
            const selectRefFields = get(panelData,`fields.${fieldKey}.ref.showFields`);
            const conditionlRefFieldsArr = get(panelData,`fields.${fieldKey}.ref.conditional`);
            const conditionlRefHidePanels = get(panelData,`fields.${fieldKey}.ref.hidePanels`);
            const conditionlRefShowPanels = get(panelData,`fields.${fieldKey}.ref.showPanels`);
            let selectConditionlRefFields = [];
            checkIfArray(conditionlRefFieldsArr) && conditionlRefFieldsArr.forEach(ele => {
              if(checkIfArray(ele.showFields)){
                selectConditionlRefFields = [...selectConditionlRefFields, ...ele.showFields]
              }
              if(checkIfArray(ele.showPanels)){
                hideRequiredPanelsFieldArr = [...hideRequiredPanelsFieldArr, ...ele.showPanels]
              }
            })
            if(checkIfArray(selectConditionlRefFields)){
              selectConditionlRefFieldsArr = [...selectConditionlRefFieldsArr,...selectConditionlRefFields];
            }
            if(checkIfArray(selectRefFields)){
              selectConditionlRefFieldsArr = [...selectConditionlRefFieldsArr,...selectRefFields];
            }
            if(checkIfArray(conditionlRefShowPanels)){
              hideRequiredPanelsFieldArr = [...hideRequiredPanelsFieldArr, ...conditionlRefShowPanels]
            }
            if(checkIfArray(conditionlRefHidePanels)){
              hideRequiredPanelsFieldArr = [...hideRequiredPanelsFieldArr, ...conditionlRefHidePanels]
            }
            if(checkIfArray(panelDataRequired) && !!panelDataRequired.includes(fieldKey) && get(panelData,`fields.${fieldKey}.type`) !== FORM_INPUT_UI.BOOLEAN && get(panelData,`fields.${fieldKey}.visible`) && (get(panelData,`fields.${fieldKey}.conditionShow`) === undefined ||
              get(panelData,`fields.${fieldKey}.conditionShow`) === true)
              ){
                panelResult.push(fieldKey)
              } 
            return requiredResult;
          },[])
        }
        if(checkIfArray(hideRequiredPanelsFieldArr)){
          hideRequiredPanelsFieldArr.forEach(ele => {
            const hideElePanelsField = stepData.config.panels && stepData.config.panels[ele] && stepData.config.panels[ele].required;
            if(checkIfArray(hideElePanelsField)){
              selectConditionlRefFieldsArr = [...selectConditionlRefFieldsArr, ...hideElePanelsField]
            }
          })
        }
        return panelResult; 
      },[]);
      if(checkIfArray(selectConditionlRefFieldsArr)){
        stepItems = stepItems.filter(ele => !selectConditionlRefFieldsArr.includes(ele))
      }
      if(isForMandatoryPopup) {
        stepItems = reduce(stepItems, function(stepResult, fieldKey) {
          let fieldTitle = ""
          if(fieldKeyMappedWithTitle[fieldKey]) {
            fieldTitle = fieldKeyMappedWithTitle[fieldKey]
          } else {
            fieldTitle = `form.fields.${fieldKey}.title`;
          }
          stepResult.push({key: fieldKey, title: fieldTitle});
          return stepResult;
        }, [])
      }
      set(stepResult,`${stepData.id}`,stepItems)
      return stepResult; 
    },{});
  }
  
/**
 * @author Chintan Patel
 * @creationDate 29 Sep 2022
 * @description Replace special charater with replaceValue
 * @param {stringValue} String to be replaced
 * @param {replaceValue} any Array or Object or String or Number
 */
  export const replaceSpecialCharacter = (stringValue, replaceValue="_") => {
    return typeof(stringValue) === 'string' ? stringValue.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, replaceValue) : stringValue;
  }
  export function getPaidEntitiesIds(userDetail, clientInfo){
    const { paymentDetails } = userDetail;
    let payment_txn_success_status =
      clientInfo &&
      !isNil(get(clientInfo, "paymentDetails.payment_success_status")) &&
      Array.isArray(get(clientInfo, "paymentDetails.payment_success_status")) &&
      get(clientInfo, "paymentDetails.payment_success_status").length
        ? get(clientInfo, "paymentDetails.payment_success_status")
        : PAYMENT_SUCCESS_STATUS;

    return (
      paymentDetails &&
      map(
        filter(paymentDetails, item => {
          return (
            (isNil(get(item, "paymentDetailsModel.feesType")) ||
              isEmpty(get(item, "paymentDetailsModel.feesType")) ||
              get(item, "paymentDetailsModel.feesType") ===
                FEES_TYPE.APPLICATION_FORM_FEES) &&
            payment_txn_success_status.includes(
              get(item, "paymentDetailsModel.transactionStatus")
            )
          );
        }),
        item => {
          set(
            get(item, "paymentDetailsModel"),
            "entityId",
            get(item, "enrolledLeadModel.entityId")
          );
          /*UKC code - COMMENTED FOR FUTHER USE  */
          !isNil(get(item, "enrolledLeadModel.amount")) && set(
            get(item, "paymentDetailsModel"),
            "amount",
            get(item, "enrolledLeadModel.amount")
          );
          return get(item, "paymentDetailsModel");
        }
      )
    );
}

export function getEnrolledLeadModel(paymentDetails){
  let returnVal = [];
  if(checkIfArray(paymentDetails)){
    paymentDetails.forEach(ele => {
      if(ele?.enrolledLeadModel){
        returnVal.push(ele.enrolledLeadModel);
      } 
    })
  }
  return returnVal;
}

export function getFieldFromConfig(formConfig, field){
  const { steps } = formConfig || {};
  let result = {};
  let flag = false;
  if(checkIfArray(steps)){
    steps.every(step => {
      const panels = get(step, "config.panels") || {};
      if(checkIfObject(panels)){
        const panelValue = Object.values(panels);
        if(checkIfArray(panelValue)){
          panelValue.every(panel => {
            const { fields } = panel || {};
            const fieldKeys = Object.keys(fields);
            fieldKeys.every(key => {
              if(key === field){
                result = fields[key];
                flag = true;
                return false;
              }
              return true;
            })
            if(flag) return false;
            else return true;
          })
        }
      }
      if(flag) return false;
      else return true;
      
    })
  }
  return result;
}
export const isMasterDataFrom_GZIP_OR_JSON=(clientConfig)=>{
  if(clientConfig){    
    if(!!clientConfig[STORAGE_KEY.IS_MASTER_DATA_FROM_GZIP_FEATURE_ACTIVE] && !!clientConfig[STORAGE_KEY.REQUEST_MASTERDATA_FROM_GZIP]){
      return true;
    }else if(!!clientConfig[STORAGE_KEY.IS_MASTER_DATA_FROM_JSON_FEATURE_ACTIVE] && !!clientConfig[STORAGE_KEY.REQUEST_MASTERDATA_FROM_JSON]){
      return true;
    }
  }
  return false;
}

export const createAnchorTag = (url, target = "", className = "", isClicked) => {
  if (url) {
    let updatedUrl = url;
    if(isClicked){
      updatedUrl = (url.startsWith("http://") || url.startsWith("https://")) 
      ? url 
      : `https://${url}`;
    }
    const anchorTag = document.createElement("A");
    anchorTag.setAttribute("href", updatedUrl);
    anchorTag.setAttribute("class", className)
    anchorTag.setAttribute("target", target);
    document.body.appendChild(anchorTag);
    isClicked && anchorTag.click();
  }
}
export const downloadAs = (url, name) => {
  Axios.get(url, {
    headers: {
      "Content-Type": "application/octet-stream",
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0'
    },
    responseType: "blob"
  })
    .then(response => {
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      a.download = name;
      a.click();
    })
    .catch(err => {
      console.log("error", err);
    });
};

//replace with stars
export function replaceEmailWithStars(email) {
  var text = email ? email.split("@") : [];
  if (text.length == 2) {
    if (text[0].length == 2) {
      //ex. hr@company.com => **@company.com
      return text[0].replace(/./g, "*") + "@" + text[1];
    }
    let lastIndex = text[0].length - 1;
    let middleString = text[0].slice(1, lastIndex); // string between first and last letter
    let stars = middleString.replace(/./g, "*"); //replce characters with *;
    return (
      text[0].charAt(0) + stars + text[0].charAt(lastIndex) + "@" + text[1]
    );
  }
  return email;
}
// replace phnNumber with stars---- replace all digits to star except last 4 digits
export function replaceMobileWithStars(phnNumber, isMobile2Type) {
  phnNumber = phnNumber ? phnNumber.toString() : "";
  let len = phnNumber.length;
  let numberVisibleLen = isMobile2Type ? 2 : 4
  let preDigits = phnNumber.slice(0, len - 4); //get all digits other than last 4 digits
  let stars = preDigits.replace(/./g, "*");
  return stars + phnNumber.slice(len - numberVisibleLen, len);
}

// replace uniqueCheckFieldValue with stars---- replace all valie to star except last 4 
export function replaceUniqueCheckFieldValueWithStars(uniqueCheckFieldValue) {
  uniqueCheckFieldValue = uniqueCheckFieldValue ? uniqueCheckFieldValue.toString() : "";
  let len = uniqueCheckFieldValue.length;
  let preValues = uniqueCheckFieldValue.slice(0, len - 4); //get all value other than last 4 
  let stars = preValues.replace(/./g, "*");
  return stars + uniqueCheckFieldValue.slice(len - 4, len);
}

export const getUnpaidCourseCurrencyList = (formData, masterData, userDetail, clientInfo, selectedCurrency=false, feesType=1, selectedCourseIds=false) => {
  let returnVal = [], unPaidPaymentsIds, allPaidPaymentDetails;
  if(checkIfArray(masterData?.currencies)){
    if(!selectedCourseIds){
      selectedCourseIds = getSelectedCourseIds(formData, masterData);
    }
    if(feesType === 2){
      allPaidPaymentDetails = getCourseFeesPaidEnttiesIds(userDetail, clientInfo);
      unPaidPaymentsIds = checkIfArray(allPaidPaymentDetails) && checkIfArray(selectedCourseIds) ? selectedCourseIds.filter(ele => !allPaidPaymentDetails.some(entry => entry.entityId == ele)) : [...(selectedCourseIds || [])];
    }else{
      allPaidPaymentDetails = getPaidEntitiesIds(userDetail, clientInfo);
      unPaidPaymentsIds = checkIfArray(allPaidPaymentDetails) && checkIfArray(selectedCourseIds) ? selectedCourseIds.filter(ele => !allPaidPaymentDetails.some(entry => entry.entityId == ele)) : [...(selectedCourseIds || [])];
    }
    const pricingCriterion2 = masterData.pricingCriterion2;
    const pricingCriterion2Val = (checkIfArray(pricingCriterion2) && pricingCriterion2[0].dataKey && formData[pricingCriterion2[0].dataKey] && formData[pricingCriterion2[0].dataKey].toString()) || null;
    const pricingListCollection = !isNil(masterData) && getMasterDataByKey(`PricingList`,masterData);
    const unPaidSelectedPricingList = pricingListCollection.filter(ele => unPaidPaymentsIds.includes(ele.entityId) && ele.criterionValue == pricingCriterion2Val);
    if(!selectedCurrency){
      
    const currenciesCollection = !isNil(masterData) && getMasterDataByKey(`currencies`,masterData);
      returnVal = unPaidSelectedPricingList.map(item => {
        if(item.currency){
          const currencyObj =checkIfArray(currenciesCollection) && currenciesCollection.find(ele => ele.ISOCode === item.currency);
          return currencyObj;
        }else{
          return DEFAULT_CURRENCY;
        }
      })
      returnVal = uniqBy(returnVal, "ISOCode");
    }else{
      if(checkIfObject(selectedCurrency) && !Array.isArray(selectedCurrency)){
        returnVal = unPaidSelectedPricingList.filter(item => !item.currency);
      }else{
        selectedCurrency = selectedCurrency && selectedCurrency.toString();
        if(selectedCurrency && selectedCurrency !== DEFAULT_CURRENCY.ISOCode){
          //used filter instead of find method as there could be multiple courses
          returnVal = unPaidSelectedPricingList.filter(item => selectedCurrency === item.currency)
        }else{
          returnVal = unPaidSelectedPricingList.filter(item => !item.currency);
        }
      }
    }
  }
  return returnVal;
}

export const getTotalDiscountedAmountToPay=(amount, promoCodeDetails)=> {
  if (parseFloat(amount) <= 0) {
    return 0;
  }
  if(checkIfObject(promoCodeDetails)) {
    switch (get(promoCodeDetails, "discountType")) {
      case PROMOCODE_DISCOUNT_TYPE.AMOUNT:
        amount =
          parseFloat(amount) -
          parseFloat(get(promoCodeDetails, "discount"));
        break;
      case PROMOCODE_DISCOUNT_TYPE.PERCENTAGE:
        amount = (
          parseFloat(amount) -
          (parseFloat(amount) *
            parseFloat(get(promoCodeDetails, "discount"))) /
            100
        ).toFixed(2);
        break;
      default: {
      }
    }
  }
  return amount < 0 ? 0 : amount;
}

export const setCurrencyListAsPerUnPaidCourses = (selectedCourseToPay, masterData, formData) => {
  let { pricingCriterion2 } = masterData || {};
  const currenciesCollection = !isNil(masterData) && getMasterDataByKey(`currencies`,masterData);
  const pricingListCollection = !isNil(masterData) && getMasterDataByKey(`PricingList`,masterData);
  const pricingCriterion2Val = (checkIfArray(pricingCriterion2) && pricingCriterion2[0].dataKey && formData[pricingCriterion2[0].dataKey] && formData[pricingCriterion2[0].dataKey].toString()) || null;
  const unPaidSelectedPricingList = pricingListCollection.filter(ele => selectedCourseToPay.includes(ele.entityId) && ele.criterionValue == pricingCriterion2Val);
  let updatedCurrencyList = unPaidSelectedPricingList.map(item => {
    if(item.currency){
      const currencyObj = checkIfArray(currenciesCollection) && currenciesCollection.find(ele => ele.ISOCode === item.currency);
      return currencyObj;
    }else{
      return DEFAULT_CURRENCY;
    }
  });
  return updatedCurrencyList;
}

export const getCourseFeesPaidEnttiesIds = (userDetail, clientInfo) => {
  let payment_txn_success_status =
      clientInfo &&
      !isNil(get(clientInfo, "paymentDetails.payment_success_status")) &&
      Array.isArray(get(clientInfo, "paymentDetails.payment_success_status")) &&
      get(clientInfo, "paymentDetails.payment_success_status").length
        ? get(clientInfo, "paymentDetails.payment_success_status")
        : PAYMENT_SUCCESS_STATUS;
  return (
    userDetail &&
    userDetail.paymentDetails &&
    map(
      filter(userDetail.paymentDetails, item => {
        return (
          !isNil(
            get(
              item,
              "paymentDetailsModel." + HARD_CODED_FIELD_KEYS.FEES_TYPE
            )
          ) &&
          get(
            item,
            "paymentDetailsModel." + HARD_CODED_FIELD_KEYS.FEES_TYPE
          ) === FEES_TYPE.COURSE_FEES &&
          payment_txn_success_status.includes(
            get(item, "paymentDetailsModel.transactionStatus")
          )
        );
      }),
      item => {
        set(
          get(item, "paymentDetailsModel"),
          "entityId",
          get(item, "enrolledLeadModel.entityId")
        );   
        /*UKC code - COMMENTED FOR FUTHER USE  */       
        !isNil(get(item, "enrolledLeadModel.amount")) && 
        set(get(item, "paymentDetailsModel"),
          "amount",
          get(item, "enrolledLeadModel.amount")
        );
        return get(item, "paymentDetailsModel");
      }
    )
  );
}



/**
 * Get a collection of entities based on the entity type.
 *
 * @param {string} entity - The type of entity for which to retrieve the collection.
 * @param {Array} masterData - The master data containing collections of entities.
 * @returns {Array} An array of entities of the specified type.
 */
export const getEntityCollection = (entity, masterData) => {
  let dataCollectionKey = entity;
  for (const [key, value] of Object.entries(ENTITY_FIELD_COLLECTION)) {
    // Check if the current entity type is in the array of values.
    if (value.includes(entity)) {
      // If found, update the dataCollectionKey with the key and exit the loop.
      dataCollectionKey = key;
      break;
    }
  }

  // Retrieve the collection of entities based on the dataCollectionKey.
  return getMasterDataByKey(dataCollectionKey, masterData);
}

export const getValue = (formConfig, key) => {
  return !isNil(formConfig) &&
    !isNil(key) &&
    !isEmpty(key) &&
    !isNil(get(formConfig, key))
    ? get(formConfig, key)
    : null;
};


export function sortListByDate(records, key, sortBy) {
  if (!!records && records.length > 0) {
    records.sort(function (a, b) {
      var key1 = new Date(a[key]);
      var key2 = new Date(b[key]);
      if (sortBy == "asc") {
        if (key1 < key2) {
          return -1;
        } else if (key1 == key2) {
          return 0;
        } else {
          return 1;
        }
      } else if (sortBy == "desc") {
        if (key1 > key2) {
          return -1;
        } else if (key1 == key2) {
          return 0;
        } else {
          return 1;
        }
      }
    });
  }
  return records;
}

export function getGroupActivityTypeByActivityId(id, collection) {
  return (
    find(collection, (item) => {
      return item.id === parseInt(id);
    }) || {}
  );
}

/**
* @author Suyash Balshetwar
* @creationDate 12 Sept 2023
* @description  Convert Underscore to space and capitalize First letter of each word
* @param string hello_world
* @returns Hello World
*/
export const convertUnderScoreToTitleCase = (str) => {
  return str && str.replace(/\_/g, " ").replace(/\w\S*/g, (txt) => {
    return txt && txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/**
* @author Suyash Balshetwar
* @creationDate 12 Sept 2023
* @description  Convert CamelCase to TitleCase
* @param string helloWorld
* @returns Hello World
*/
export const convertCamelCaseToTitleCase = (string) => {
  return string && string.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
   return str && str.toUpperCase();
 });
}

export const getFieldConfig = (formConfig, fieldKey) => {
  let foundFieldConfig = {};
  if (checkIfObject(formConfig) && fieldKey) {
    checkIfArray(formConfig?.steps) &&
      formConfig.steps.some((step) => {
        if (step.config.panels) {
          for (const panelName in step.config.panels) {
            if (
              step.config.panels[panelName].fields &&
              step.config.panels[panelName].fields[fieldKey]
            ) {
              foundFieldConfig =
                step.config.panels[panelName].fields[fieldKey];
              return true; // Stop the iteration as soon as the field config is found
            }
          }
        }
        return false; // Continue the iteration
      });
  }
  return foundFieldConfig;
};


export const generateUniqueId = () => {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base36
  const randomChars = Math.random().toString(36).substring(2, 8); // Generate random characters
  return `${timestamp}-${randomChars}`;
}

export const getURLqueryParameters = (fetchFromURL = false) => {
  let urlQueryParameters = {};
  if(isAccessTokenTableKeyEnabled() && !fetchFromURL) {
    return OfflineStorage.getAllItem();
  } else if(get(window, "location.search")) {
    let params = new URLSearchParams(
      window.location.search.replace(/&amp;/g, "&")
    );
    params && params.forEach(function (value, key) {
      urlQueryParameters[key] = value;
    });
  }
  return urlQueryParameters;
}

export const updateURLqueryParameters = (key, updatedValue, reploadPage = false, state) => {
  if ("URLSearchParams" in window) {
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, updatedValue);
  
    if(reploadPage) {
      window.location.search = searchParams.toString();
    } else {
      var newURL = window.location.pathname + "?" + searchParams.toString();
      // Use pushState to update the URL without a page reload
      window.history.pushState({ path: newURL, state: state || "" }, "", newURL);
    }
  }
}

export const arrayToIndexedQueryString = (paramName, paramValue) => {
  if (!checkIfArray(paramValue)) {
    return `${paramName}=${paramValue}`;
  }

  const queryString = paramValue
    .map((value, index) => `${paramName}[${index}]=${encodeURIComponent(value)}`)
    .join('&');

  return queryString;
}


/**
 * Replaces tokens in a text string with corresponding values from data, masterData, and leadDetails.
 * @author Suyash Balshetwar
 * @param {string} text - The text string containing tokens to replace.
 * @param {object} data - Data containing lead and user information.
 * @param {object} masterData - Master data collection.
 * @param {object} leadDetails - Details related to the lead.
 * @param {string} tokenValuesSeparator - Optional. Separator for token values.
 * @param {object} clientInfo - Client information including enableMasking and Info.
 * @returns {string} - The text string with tokens replaced by their values.
 */
export const replaceTemplateTokens = (
  text,
  data,
  masterData,
  leadDetails,
  tokenValuesSepeator,
  clientInfo
) => {
  // Check if the required parameters are provided.
  if (text && checkIfObject(data) && masterData) {
    // Create a combined lead object from data.
    const lead = {
      ...data,
      ...(data.lead || {}),
      ...(data.user || {}),
    };
    const { enableMasking, Info } = clientInfo || {};
    // Try to find counselor details using createdBy or createdForUserId.
    let counselorDetails =
      !!leadDetails &&
      masterData.counselors?.find((counselor) => {
        if (
          counselor.id === leadDetails.createdBy ||
          counselor.id === leadDetails.createdForUserId
        ) {
          return counselor;
        }
      });

    // Try to find counselorEntityDetails using createdBy.
    const counselorEntityDetails =
      !!leadDetails?.createdBy &&
      checkIfArray(masterData.userReportingToMapping) &&
      masterData.userReportingToMapping.find((user) => {
        if (user.userId === leadDetails.createdBy) {
          return user;
        }
      });

    // Use a regular expression with the global (g) flag to find all occurrences
    // Define a regular expression to find tokens in the text.
    const regex = /%(\w+|\w+\.\w+)%/g;
    const tokens = text.match(regex) || [];
    let replacedText = cloneDeep(text);
    const replaceTokensWithMultipleValues = get(
      masterData,
      "replaceTokensWithMultipleValues"
    );
    const mappedApplicationFormAlias = Info?.alias || "";

    let activationUrl = "";
    let appFormJourneyURL = "";
    let applicationFormURL_SearchParams = {};
    let appFormJourneyURL_SearchParams = {};
    if (!!get(masterData, `uniqueKeyCriteria`)) {
      //UKC found then add in search param of applicaiton form URL
      set(
        applicationFormURL_SearchParams,
        `ukc`,
        getCriterionId(masterData.applicationFormCriteria.toLowerCase(), lead)
      );
    }

    if (!!get(masterData, `applicationFormCriteria`)) {
      //INST found then add in search param of applicaiton form URL
      const inst = getCriterionId(
        masterData.applicationFormCriteria.toLowerCase(),
        lead
      );
      set(applicationFormURL_SearchParams, `inst`, inst);
    }

    if (!!get(lead, `prn`)) {
      //PRN found then add in search param of applicaiton form URL
      set(applicationFormURL_SearchParams, `prn`, lead.prn);
      set(appFormJourneyURL_SearchParams, `prn`, lead.prn);
    }
    applicationFormURL_SearchParams = Object.keys(
      applicationFormURL_SearchParams
    ).length
      ? convertObjectToSearchParamString(applicationFormURL_SearchParams)
      : null;
    appFormJourneyURL_SearchParams = Object.keys(appFormJourneyURL_SearchParams)
      .length
      ? convertObjectToSearchParamString(appFormJourneyURL_SearchParams)
      : null;
    activationUrl = get(masterData, `applicationFormUrl`);
    activationUrl = !!activationUrl
      ? !!applicationFormURL_SearchParams
        ? `${activationUrl}?${applicationFormURL_SearchParams}`
        : activationUrl
      : "";
    lead &&
      checkIfArray(tokens) &&
      tokens.forEach((token) => {
        // Extract parentDataKey and childDataKey from the token.
        const parts = token.replaceAll("%", "").split(".");
        let parentDataKey = token;
        let childDataKey = null;
        if (parts.length === 2) {
          parentDataKey = parts[0];
          childDataKey = parts[1];
        }
        if (parentDataKey) {
          let replaceValue = "";
          switch (parentDataKey) {
            // Handle cases for Counselor, Lead, Portal, Root, UnsubscribeLink, etc.
            case "Counselor":
              if (
                TOKEN_MAPPING_WITH_MULTI_SELECT_FIELD_DATA_KEY[childDataKey]
              ) {
                const { dataKey, masterDataCollectionName } =
                  TOKEN_MAPPING_WITH_MULTI_SELECT_FIELD_DATA_KEY[childDataKey];
                replaceValue =
                  getValue(counselorEntityDetails, dataKey) &&
                  getNameById(
                    counselorEntityDetails[dataKey],
                    masterData[masterDataCollectionName]
                  );
              } else {
                switch (childDataKey) {
                  case "ContactNumber":
                    replaceValue = counselorDetails.mobileNumber || "";
                    break;
                  case "FullName":
                    replaceValue = counselorDetails.name || "";

                    break;
                  default:
                    replaceValue =
                      (counselorDetails &&
                        counselorDetails[toLowerFirstLetter(childDataKey)]) ||
                      counselorDetails[childDataKey] ||
                      "";
                    break;
                }
              }
              break;

            case "Lead":
              {
                if (
                  TOKEN_MAPPING_WITH_MULTI_SELECT_FIELD_DATA_KEY[childDataKey]
                ) {
                  const { dataKey, masterDataCollectionName } =
                    TOKEN_MAPPING_WITH_MULTI_SELECT_FIELD_DATA_KEY[
                      childDataKey
                    ];
                  replaceValue =
                    (lead[dataKey] &&
                      getNameById(
                        replaceTokensWithMultipleValues
                          ? lead[dataKey]
                          : lead[dataKey][0],
                        masterData[masterDataCollectionName],
                        "name",
                        "name",
                        tokenValuesSepeator || TOKEN_VALUES_SEPEARTOR
                      )) ||
                    "";
                } else if (
                  TOKEN_MAPPING_WITH_MASKING_FIELD_DATA_KEY[childDataKey]
                ) {
                  const { dataKey, maskingConfigKey, customFunction } =
                    TOKEN_MAPPING_WITH_MASKING_FIELD_DATA_KEY[childDataKey];
                  replaceValue =
                    (lead[dataKey] &&
                      (get(enableMasking, maskingConfigKey) &&
                      typeof customFunction === "function"
                        ? customFunction(lead[dataKey])
                        : lead[dataKey])) ||
                    "";
                } else if (
                  TOKEN_MAPPING_WITH_SINGLE_SELECT_FIELD_DATA_KEY[childDataKey]
                ) {
                  const { dataKey, masterDataCollectionName } =
                    TOKEN_MAPPING_WITH_SINGLE_SELECT_FIELD_DATA_KEY[
                      childDataKey
                    ];
                  replaceValue =
                    (lead[dataKey] &&
                      getNameById(
                        lead[dataKey],
                        masterData[masterDataCollectionName]
                      )) ||
                    "";
                } else {
                  switch (childDataKey) {
                    case "FathersMobile":
                      replaceValue =
                        lead.fathersMobile || lead.fathersPhone || "";
                      break;
                    case "MothersMobile":
                      replaceValue =
                        lead.mothersMobile || lead.mothersPhone || "";
                      break;
                    case "FullName":
                      replaceValue =
                        lead.firstName +
                        " " +
                        (!!lead.lastName ? lead.lastName : "");
                      break;
                    case "LastFollowUpDateTime":
                      replaceValue = !!lead.nextActionDate
                        ? ConvertToLocalUsingMoment(
                            lead.nextActionDate,
                            FORM_INPUT_VALUE_FORMAT.DATE_TIME
                          )
                        : "";
                      break;
                    case "PRN":
                      replaceValue = !!lead.prn ? lead.prn : "";
                      break;
                    case "AccountActivationURL":
                      replaceValue = activationUrl;
                      break;
                    case "AppFormJourneyURL":
                      replaceValue = replaceAliasNameInURL(
                        appFormJourneyURL,
                        mappedApplicationFormAlias
                      );
                      break;
                    case "ApplicationFormURL":
                      replaceValue = replaceAliasNameInURL(
                        activationUrl,
                        mappedApplicationFormAlias
                      );
                      break;
                    case "PdfAttachments":
                      replaceValue = generateApplicationFormDocumentPath(
                        masterData,
                        lead.pdfAttachments || lead.pdfAttachment,
                        mappedApplicationFormAlias
                      );
                      break;
                    case "Password":
                      replaceValue = "*********";
                      break;
                    default:
                      {
                        replaceValue = lead[childDataKey]
                          ? lead[childDataKey]
                          : lead[toLowerFirstLetter(childDataKey)]
                          ? lead[toLowerFirstLetter(childDataKey)]
                          : "";
                      }
                      break;
                  }
                }
              }
              break;
            case "Portal": {
              if (masterData.portalTokenModel) {
                if (childDataKey === "Logo") {
                  replaceValue = get(masterData, "portalTokenModel.LogoLink")
                    ? masterData.portalTokenModel.LogoLink
                    : "";
                } else {
                  replaceValue =
                    masterData.portalTokenModel[`portal${childDataKey}`] ||
                    masterData.portalTokenModel[`${childDataKey}`] ||
                    "";
                }
              }
            }
            case "Root": {
              replaceValue = masterData?.portalTokenModel?.rootURL || "";
            }
            case "UnsubscribeLink": {
              replaceValue = masterData.portalTokenModel
                ? masterData.portalTokenModel.rootURL +
                  "?Unsubscribe=" +
                  lead.email
                : "";
            }
            case "LeadPaymentReceipt":
              {
                generatePaymentReceiptsPath(
                  masterData,
                  lead,
                  mappedApplicationFormAlias
                );
              }
              break;
            case "Current":
              {
                if (childDataKey === "DateTime") {
                  replaceValue = moment().format(
                    !!masterData.pDF_DATETIME_FORMAT
                      ? masterData.pDF_DATETIME_FORMAT
                          .replace(/dd/gi, "DD")
                          .replace(/tt/gi, "A")
                          .replace(/yyyy/gi, "YYYY")
                      : `DD-MMM-YYYY hh:mm a`
                  );
                } else if (childDataKey === "Date") {
                  replaceValue = moment().format(
                    !!masterData.pDF_DATE_FORMAT
                      ? masterData.pDF_DATE_FORMAT
                          .replace(/dd/gi, "DD")
                          .replace(/tt/gi, "A")
                          .replace(/yyyy/gi, "YYYY")
                      : `DD-MMM-YYYY`
                  );
                }
              }
              break;
            case "Copyright":
              replaceValue = new Date().getFullYear().toString();
              break;
            case "Institute":
              if (childDataKey === "Website") {
                replaceValue =
                  !!masterData.portalTokenModel &&
                  !!masterData.portalTokenModel.instituteWebsite
                    ? masterData.portalTokenModel.instituteWebsite
                    : "";
              } else if (childDataKey === "Address") {
                replaceValue =
                  !!masterData.portalTokenModel &&
                  !!masterData.portalTokenModel.instituteAddress
                    ? masterData.portalTokenModel.instituteAddress
                    : "";
              }
              break;
            default:
              break;
          }
          replacedText = replacedText.replace(`${token}`, replaceValue || "");
        }
      });
    return replacedText;
  }
  return text;
};


export function toLowerFirstLetter(input) {
  if (input) {
    if (input.length === 1) {
      return input.charAt(0).toLowerCase();
    } else if (input.length > 1) {
      return input.charAt(0).toLowerCase() + input.slice(1);
    }
  }

  return input;
}

export const getCriterionId = (criterianValue, lead) => {
  if (criterianValue && lead) {
    const entityFieldNameByCriteriaValue =
      EntityFieldNameByCriteriaValue[criterianValue];
    if (lead[entityFieldNameByCriteriaValue]) {
      return lead[entityFieldNameByCriteriaValue];
    }
  }
  return null;
};

/**
 * Replaces the first part of a URL's hostname with a mapped alias name.
 * @author Suyash Balshetwar
 * @param {string} url - The URL to modify.
 * @param {string} mappedAlias - The alias name to replace the first part of the URL's hostname.
 * @returns {string} - The modified URL.
 */
export function replaceAliasNameInURL(url, mappedAlias) {
  // Check if both url and mappedAlias are not null or empty.
  if (
    !isNil(url) &&
    !isNil(mappedAlias) &&
    !isEmpty(url) &&
    !isEmpty(mappedAlias)
  ) {
    // Split the hostname of the URL into parts using '.' as the separator.
    let parts = new URL(url).hostname.split(".");
    // Check if there are at least three parts (subdomains and domain).
    if (parts.length >= 3) {
      // Replace the first part of the hostname with the mapped alias.
      url = url.replace(parts[0], mappedAlias);
    }
  }
  return url;
}

/**
 * @author Suyash Balshetwar
 * @description Get payments receipts paths.
 * @param {Object} masterData
 * @param {Object} lead
 * @param {String} mappedApplicationFormAlias Inst specific application form alias name
 */
export const generatePaymentReceiptsPath = (
  masterData,
  lead,
  mappedApplicationFormAlias,
  templetePdfsMasterKey,
  paymentReceiptNumber
) => {
  const masterKeys = get(masterData, "masterKeys");
  if (checkIfArray(masterKeys)) {
    let templatePdfs = null;
    const templetePdfsMasterKeyObject = templetePdfsMasterKey || find(masterKeys, {
      name: STORAGE_KEY.TEMPLATE_PDF_MASTER_KEY_NAME,
    }); //Get object from masterKeys where name is equal to templatePdfs
    const leadKeyValueAttributesData = get(
      lead?.metaData,
      META_DATA_TYPE.NON_INDEX_META_TYPE
    ); //Get meta data key value attribute
    if (
      checkIfObject(templetePdfsMasterKeyObject) &&
      checkIfArray(leadKeyValueAttributesData)
    ) {
      const templatePDFsMetaData = find(leadKeyValueAttributesData, {
        keyId: templetePdfsMasterKeyObject.id,
      });
      if (get(templatePDFsMetaData, "value")) {
        try {
          templatePdfs = JSON.parse(templatePDFsMetaData.value);
          if(templatePdfs && templatePdfs[paymentReceiptNumber]) {
            return getReceiptURLByReceiptNumber(paymentReceiptNumber, templatePdfs);
          }
        } catch (error) {
          console.log("Parse Error: - ", error);
        }
      }
    }
  }
  return "";
};

/**
 * @author Suyash Balshetwar
 * @description Generate Application Form Document Path.
 * @param {Object} masterData
 * @param {String/Array} documentName Document name it can be single string or array of multiple strings.
 * @param {String} mappedApplicationFormAlias Inst specific application form alias name
 */
export const generateApplicationFormDocumentPath = (
  masterData,
  documentName,
  mappedApplicationFormAlias
) => {
  let applicaitonFormDocPath = "";
  if (documentName && mappedApplicationFormAlias) {
    const applicationFormDocumentsPath = get(
      masterData,
      "applicationFormDocumentsPath"
    );
    if (checkIfArray(documentName)) {
      const documentsPathURL = [];
      uniq(documentName).map((name) => {
        const documentURL = applicationFormDocumentsPath
          ? `${applicationFormDocumentsPath}${name}`
          : `${BLOB_URL}${mappedApplicationFormAlias}/EmailAttachments/${name}`;
        documentsPathURL.push(documentURL ? encodeURI(documentURL) : "");
      });
      applicaitonFormDocPath = documentsPathURL.join(",");
    } else {
      applicaitonFormDocPath = applicationFormDocumentsPath
        ? `${applicationFormDocumentsPath}${documentName}`
        : `${BLOB_URL}${mappedApplicationFormAlias}/EmailAttachments/${documentName}`;
      applicaitonFormDocPath = applicaitonFormDocPath
        ? encodeURI(applicaitonFormDocPath)
        : "";
    }
  }
  return applicaitonFormDocPath;
};


export const getMileStonePaymentReceiptURLs = (functionParams) => {
  const {masterData, lead, templetePdfsMasterObj, mileStonePaymentReceiptObj, mileStonePaymentReceiptNumber} = functionParams || {}
  let masterKeys = get(masterData, "masterKeys");
  if (checkIfArray(masterKeys)) {
    masterKeys = sortBy(masterKeys, "id");
    let templatePdfs = null;
    const templetePdfsMasterKeyObject = templetePdfsMasterObj || find(masterKeys, {
      name: STORAGE_KEY.TEMPLATE_PDF_MASTER_KEY_NAME,
    }); //Get object from masterKeys where name is equal to templatePdfs
    const mileStonePaymentReceiptObject = mileStonePaymentReceiptObj || find(masterKeys, {
      name: STORAGE_KEY.MILE_STONE_PAYMENT_RECEIPT_SEQUENCE_MASTER_KEY,
    }); //Get object from masterKeys where name is equal to milestone payment receipt
    const leadKeyValueAttributesData = get(
      lead?.metaData,
      META_DATA_TYPE.NON_INDEX_META_TYPE
    ); //Get meta data key value attribute
    const indexKeyValueAttributesData = get(
      lead?.metaData,
      META_DATA_TYPE.INDEX_META_TYPE
    ); //Get meta data key value attribute
    if (
      checkIfObject(templetePdfsMasterKeyObject) &&
      checkIfObject(mileStonePaymentReceiptObject) &&
      checkIfArray(leadKeyValueAttributesData) &&
      checkIfArray(indexKeyValueAttributesData) 
    ) {
      let mileStonePaymentIndexKeyValuAttributes = filter(indexKeyValueAttributesData, {
        keyId: mileStonePaymentReceiptObject.id
      })
      if(checkIfArray(mileStonePaymentIndexKeyValuAttributes)) {
        const templatePDFsMetaData = find(leadKeyValueAttributesData, {
          keyId: templetePdfsMasterKeyObject.id,
        });
        if (get(templatePDFsMetaData, "value")) {
          mileStonePaymentIndexKeyValuAttributes = sortBy(mileStonePaymentIndexKeyValuAttributes, "id")
          try {
            const mileStonePaymentReceiptUrls = [];
            templatePdfs = JSON.parse(templatePDFsMetaData.value);
            checkIfObject(templatePdfs) && mileStonePaymentIndexKeyValuAttributes.forEach(({textValue}) => {
              if(templatePdfs[textValue]) {
                const receiptUrl = getReceiptURLByReceiptNumber(textValue, templatePdfs, STORAGE_KEY.CRM_ALIAS);
                receiptUrl && mileStonePaymentReceiptUrls.push(receiptUrl);
              }
            });
            return mileStonePaymentReceiptUrls;
          } catch (error) {
            console.log("Parse Error: - ", error);
            return "";
          }
        }
      }
    }
  }
}

/**
 * Retrieves group status data based on a given status ID from a master data collection.
 * @author Suyash Balshetwar
 * @param {string} statusId - The status ID to search for.
 * @param {object} masterData - The master data collection containing status information.
 * @param {string} groupStatusDataKey - Optional. A key to specify which group status data to retrieve for example if we want to fetch only group status name in that case it is name.
 * @returns {string|object} - The group status data corresponding to the given status ID, or an empty string if not found.
 */
export const getGroupStatusDataByStatusId = (
  statusId,
  masterData,
  groupStatusDataKey
) => {
  // Check if both statusId and masterData are provided.
  if (statusId && masterData) {
    // Get the leadStatuses array from master data.

    const leadStatuses = getMasterDataByKey(`LeadStatuses`, masterData);
    let groupStatusId = null;
    checkIfArray(leadStatuses) &&
      leadStatuses.some((masterObj) => {
        if (masterObj.id === statusId) {
          groupStatusId = masterObj.groupStatusId;
          return true;
        }
      });
    if (groupStatusId) {
      const groupStatusesMasterCollection = getMasterDataByKey(
        `leadStatusGroup`,
        masterData
      );
      const groupStatusobj =
        checkIfArray(groupStatusesMasterCollection) &&
        groupStatusesMasterCollection.find(
          (masterObj) => masterObj.id === groupStatusId
        );
      // If groupStatusobj is found, return the desired data (or the entire object if groupStatusDataKey is not specified).
      if (groupStatusobj) {
        return (
          (groupStatusDataKey && groupStatusobj[groupStatusDataKey]) ||
          groupStatusobj
        );
      }
    }
  }
  return "";
}

export const getAppliedCourse = (pricingEntityValues, masterData) => {
  if(checkIfArray(pricingEntityValues) && masterData) {
    let entityDataCollection = getEntityCollection(
      get(masterData, "pricingCriterion"),
      masterData
    );
    if (
      checkIfArray(entityDataCollection) 
    ) {
      let selectedItems = [];
      map(pricingEntityValues, (item) => {
        const entityValue = find(entityDataCollection, { id: item });
        !isNil(entityValue) && selectedItems.push(entityValue);
      });
      return selectedItems;
    }
  } 
  return [{}];
}

export function findAliasWhereFileExists(
  aliasList,
  fileName,
  aTag,
  isDownload,
  collectionOfCorrectPath
) {
  if (checkIfArray(aliasList)) {
    //loop to all alias name and hit ajax to check header exists of file.
    for (var i = 0; i < aliasList.length; i++) {
      LinkCheck(
        `${BLOB_URL}${aliasList[i]}/${BLOB_FOLDERS.EMAIL_ATTACHMENTS}/${fileName}`,
        aTag,
        !!isDownload,
        collectionOfCorrectPath
      );
    }
  }
}

export async function LinkCheck(
  url,
  aTag,
  isDownload,
  collectionOfCorrectPath = null
) {
  var http = new XMLHttpRequest();
  http.open("HEAD", url, false);
  await http.send();

  if (http.status != 404) {
    if (isDownload) {
      if (collectionOfCorrectPath) {
        collectionOfCorrectPath.push(url);
      } else {
        window.location.href = url;
      }
      if (aTag) {
        aTag.classList.remove("de-active-link");
      }
    } else {
      window.open(url, "_blank");
    }
  }

}

export const getUniqueKeyCriteriaValue = (userDetail, masterData) => {
  if (userDetail && masterData) {
    const uniqueKeyCriteria = get(
      masterData,
      STORAGE_KEY.UNIQUE_KEY_CRITERIA
    );
    const uniqueKeyCriteriaValue = get(
      userDetail,
      `lead.${EntityFieldNameByCriteriaValue[uniqueKeyCriteria]}`
    ) 
    return checkIfArray(uniqueKeyCriteriaValue) ? uniqueKeyCriteriaValue[0] : uniqueKeyCriteriaValue;
  }
  return null;
};

export const getApplicationFormCriteriaValue = (userDetail, masterData) => {
  if (userDetail && masterData) {
    const applicationFormCriteria = get(
      masterData,
      STORAGE_KEY.APPLICATION_FORM_CRIETRIA
    );
    const applicationFormCriteriaValue = get(
      userDetail,
      `lead.${EntityFieldNameByCriteriaValue[applicationFormCriteria]}`
    ) 
    return checkIfArray(applicationFormCriteriaValue) ? applicationFormCriteriaValue[0] : applicationFormCriteriaValue;
  }
  return null;
};
/**
 * 
 * @param {*} userDetail 
 * @param {*} masterData 
 * @returns Value of Pricing Criteria's value from userDetail
 */
export const getPricingCriteriaValue = (userDetail, masterData) => {
  if (userDetail && masterData) {
    const criteriaEntityName = get(
      masterData,
      STORAGE_KEY.PRICING_CRITERIA
    );
    const criteriaValue = get(
      userDetail,
      `lead.${EntityFieldNameByCriteriaValue[criteriaEntityName]}`
    ) 
    return checkIfArray(criteriaValue) ? criteriaValue[0] : criteriaValue;
  }
  return null;
};
export const getCurrentStepIndex = (
  appFormCompletedStepIndex,
  dashboardConfig,
  formConfig
) => {
  if (checkIfArray(appFormCompletedStepIndex)) {
    let currentStepIndex = Math.max(...appFormCompletedStepIndex);
    const steps = dashboardConfig?.steps || formConfig?.steps;
    if (checkIfArray(steps)) {
      currentStepIndex =
        currentStepIndex > steps.length
          ? currentStepIndex
          : currentStepIndex + 1;
      currentStepIndex =
        currentStepIndex > 0 ? currentStepIndex - 1 : currentStepIndex;
      return currentStepIndex;
    }
  }
  return 0;
};

export const getOpenApplicationFormBtnText = (functionParams) => {
  const {
    userDetail: userDetails,
    appFormCompletedStepIndex,
    dashboardConfig,
    formConfig,
    domainConfig,
    formData
  } = functionParams;
  if (userDetails && (dashboardConfig || formConfig)) {
    const openApplicationFormBtnMapping =
      getValue(
        domainConfig,
        "dashboard.openApplicationFormBtnTextMappingWithStepIndex"
      ) || {};
    let { defaultText } = openApplicationFormBtnMapping || {};
    let currentLeadStatusKey = "";
    if (checkIfArray(appFormCompletedStepIndex)) {
      const steps = dashboardConfig?.steps || formConfig?.steps || [];
      const currentStepIndex = getCurrentStepIndex(
        appFormCompletedStepIndex,
        dashboardConfig,
        formConfig
      );
      if (currentStepIndex && steps.length > currentStepIndex) {
        let activeStepIndex = currentStepIndex + 1;
        const fieldEnableConfig = get(formConfig, `steps[${currentStepIndex}].config.ref`);
        const fieldContionToEnableStep = checkHideAndShowConfig(fieldEnableConfig, formData, true);
        if(!fieldContionToEnableStep){
          activeStepIndex = activeStepIndex - 1;
        }
        const { steps } = formConfig || {};
        if (checkIfArray(steps)) {
          steps.some(({ index, lead_status_key }) => {
            if (index === activeStepIndex) {
              currentLeadStatusKey = lead_status_key;
              return true;
            }
          });
        }
        defaultText = defaultText
          ? defaultText
          : getLang(
              "dashboard.myApplications.applicationCards.continueApplicationFormBtnText",
              DEFAULT_OPEN_APPLICATION_BTN_TEXT
            );
        return openApplicationFormBtnMapping[currentLeadStatusKey]
          ? openApplicationFormBtnMapping[currentLeadStatusKey]
          : defaultText.replace("%STEP_INDEX%", activeStepIndex);
      } else if (steps.length === currentStepIndex) {
        return getLang(
          "dashboard.myApplications.applicationCards.openApplicationFormBtnText",
          DEFAULT_OPEN_APPLICATION_BTN_TEXT
        );
      }
    }
    defaultText = defaultText
      ? defaultText
      : getLang(
          "dashboard.myApplications.applicationCards.startApplicationFormBtnText",
          DEFAULT_OPEN_APPLICATION_BTN_TEXT
        );
    const { steps } = formConfig || {};
    if (checkIfArray(steps)) {
      steps.some(({ index, lead_status_key }) => {
        if (index === 1) {
          currentLeadStatusKey = lead_status_key;
          return true;
        }
      });
    }
    return openApplicationFormBtnMapping[currentLeadStatusKey]
      ? openApplicationFormBtnMapping[currentLeadStatusKey]
      : defaultText.replace("%STEP_INDEX%", 1);
  }
  return getLang(
    "dashboard.myApplications.applicationCards.openApplicationFormBtnText",
    DEFAULT_OPEN_APPLICATION_BTN_TEXT
  );
};

export const checkLeadStatusConditionToEnableStep = (functionParams) => {
  const { stepParams, userDetail, clientInfo, masterData } = functionParams;
  let LEAD_STATUS_CONDITION_TO_ENABLE_STEP =
    stepParams &&
    userDetail &&
    !isNil(get(userDetail, "lead.statusId")) &&
    checkIfArray(
      get(
        clientInfo,
        "LeadstatusDetails.leadStatusConditionToEnableStep." +
          get(stepParams, "lead_status_key")
      )
    )
      ? get(
          clientInfo,
          "LeadstatusDetails.leadStatusConditionToEnableStep." +
            get(stepParams, "lead_status_key")
        )
      : null;

  const leadStatuses =
    !isNil(masterData) && getMasterDataByKey(`LeadStatuses`, masterData);
  if (
    !isNil(LEAD_STATUS_CONDITION_TO_ENABLE_STEP) &&
    checkIfArray(leadStatuses) &&
    !isNil(get(userDetail, "lead.statusId"))
  ) {
    let currentLeadStatusDetails = find(leadStatuses, {
      id: userDetail.lead.statusId,
    });
    return !isNil(currentLeadStatusDetails) &&
      !isNil(get(currentLeadStatusDetails, "name"))
      ? LEAD_STATUS_CONDITION_TO_ENABLE_STEP.includes(
          get(currentLeadStatusDetails, "name")
        )
      : true;
  }
  return true;
};

/**
 * Opens the application form based on specified parameters and updates the current step in the process.
 * @param {Object} functionParams - Parameters for the function.
 * @param {number} functionParams.openApplicationFormStep - The step at which to open the application form.
 * @param {Object} functionParams.clientInfo - Information about the client.
 * @param {Object} functionParams.userDetail - Details of the user.
 * @param {Object} functionParams.masterData - Master data for additional information.
 * @param {Object} functionParams.history - The history object for navigation.
 * @param {Object} functionParams.dashboardConfig - Configuration for the dashboard.
 * @param {Object} functionParams.formConfig - Configuration for the form.
 * @param {number[]} functionParams.appFormCompletedStepIndex - Array of completed form steps.
 */
export const openApplicationForm = (functionParams) => {
  const {
    openApplicationFormStep,
    clientInfo,
    userDetail,
    masterData,
    history,
    dashboardConfig,
    formConfig,
    appFormCompletedStepIndex,
    historyStates,
    formData
  } = functionParams;
  // Default history state with a flag indicating the navigation is from the dashboard
  let historyState = { fromDashboard: true, ...(historyStates || {}) };
  // Check if the form should be opened at the first step or in a non-linear manner
  if (
    !openApplicationFormStep ||
    openApplicationFormStep === OPEN_APPLICATION_STEP_POSITION.FIRST ||
    clientInfo?.LeadstatusDetails?.stepNavigation ===
      STEP_NAVIGATION_TYPE.NON_LINEAR
  ) {
    OfflineStorage.setItem(
      STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP,
      1,
      STORAGE_TYPE.LOCAL
    );
    historyState = { ...historyState , currentStep: 1};
    updateCurrentStep(1);
    const mainDiv = document.querySelector(".right-content"); // Assuming the main div has a class "main-div"
    if (mainDiv) {
      mainDiv.classList.add("moveInLeftNegativeAnimation");
    }
    setTimeout(() => {
      history &&
        addURLParametersAndRoute(
          { pathname: PAGES.FORM, state: historyState },
          history
        );
    }, MOVE_LEFT_RIGHT_ANIMATION_TIME);
  } else if (
    openApplicationFormStep === OPEN_APPLICATION_STEP_POSITION.CURRENT
  ) {
    // Calculate the next step index based on completed steps
    let currentStepIndex = Math.max(...(appFormCompletedStepIndex || []));
    const fieldEnableConfig = get(formConfig, `steps[${currentStepIndex}].config.ref`);
    const fieldContionToEnableStep = checkHideAndShowConfig(fieldEnableConfig, formData, true);
    currentStepIndex = currentStepIndex + 1;
    // Get the steps configuration from either the dashboard or form config
    const steps = dashboardConfig?.steps || formConfig?.steps;
    if (
      checkLeadStatusConditionToEnableStep({
        stepParams: checkIfArray(steps) && steps[currentStepIndex - 1],
        userDetail,
        clientInfo,
        masterData,
      })
    ) {
      OfflineStorage.setItem(
        STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP,
        currentStepIndex,
        STORAGE_TYPE.LOCAL
      );
      historyState = {
        ...historyState,
        currentStep:
        (currentStepIndex > steps.length || !fieldContionToEnableStep)
          ? currentStepIndex - 1
          : currentStepIndex,
      };
      updateCurrentStep(currentStepIndex);
      const mainDiv = document.querySelector(".right-content"); // Assuming the main div has a class "main-div"
      if (mainDiv) {
        mainDiv.classList.add("moveInLeftNegativeAnimation");
      }
      setTimeout(() => {
        history &&
          addURLParametersAndRoute(
            {
              pathname: PAGES.FORM,
              state: historyState,
            },
            history
          );
      }, MOVE_LEFT_RIGHT_ANIMATION_TIME);
    }
  }
};

/**
 * Replaces custom tokens in HTML content with corresponding values based on configuration and user details.
 * @param {Object} functionParams - Parameters for the function.
 * @param {string} functionParams.htmlContent - The HTML content with custom tokens to be replaced.
 * @param {Object} functionParams.config - Configuration object containing token mappings.
 * @param {string} functionParams.configPath - Path to the token configurations in the config object.
 * @param {Object} functionParams.userDetail - User details object containing data to replace tokens.
 * @param {Object} functionParams.masterData - Master data object for additional data if needed.
 * @returns {string} Updated HTML content with replaced tokens.
 */
export const replaceCustomTokensInHtml = (functionParams) => {
  const { htmlContent, config, configPath, userDetail, masterData } =
    functionParams || {};
  if (htmlContent) {
    if (config && configPath && userDetail) {
      let updatedHtmlContent = cloneDeep(htmlContent);
      // Extract tokens from the HTML content using a regular expression
      const tokens = (
        (updatedHtmlContent && updatedHtmlContent.match(REGEX.TOKEN_REGEX)) ||
        []
      ).map((match) => match.slice(1, -1));
      // Remove duplicate tokens
      checkIfArray(tokens) &&
        uniq(tokens).forEach((token) => {
          // Get token configuration from the provided config
          const tokenConfig = get(config, `${configPath}.${token}`);
          let tokenValue = "";
          const { dataFetchPath, masterDataCollectionName, listView } =
            tokenConfig || {};
          // Get master data collection based on the master data collection name
          const masterDataCollection =
            masterData &&
            masterDataCollectionName &&
            getMasterDataByKey(masterDataCollectionName, masterData);
          if (checkIfArray(masterDataCollection) && dataFetchPath) {
            const masterDataCollectionId = get(userDetail, `${dataFetchPath}`);
            if (checkIfArray(masterDataCollectionId)) {
              let liTags = [];
              const replaceValues = [];
              let totalLiTagsInDiv = 0;
              masterDataCollection.forEach(({ id, name }) => {
                if (masterDataCollectionId.includes(id)) {
                  if(listView) {
                    liTags.push(`<li>${name}</li>`);
                    totalLiTagsInDiv++;
                    // Break liTags into div blocks after every 3 li tags
                    if(totalLiTagsInDiv % 3 === 0) {
                      replaceValues.push(`<div class='li-div-block'>${liTags.join("")}</div>`);
                      liTags = [];
                      totalLiTagsInDiv = 0;
                    }
                  } else {
                    replaceValues.push(name);
                  }
                }
              });
              // If there are remaining liTags, add them to replaceValues
              if(checkIfArray(liTags)) {
                replaceValues.push(`<div class='li-div-block'>${liTags.join("")}</div>`);
              }
              // Join replaceValues based on listView option
              tokenValue = listView
                ? replaceValues.join("")
                : replaceValues.join(", ");
            } else {
              // If masterDataCollectionId is not an array, find the corresponding value
              const masterDataValue = find(masterDataCollection, {
                id: masterDataCollectionId,
              });
              if (checkIfObject(masterDataValue)) {
                tokenValue = get(masterDataValue, `name`) || "";
              }
            }
          } else if (dataFetchPath) {
            // If dataFetchPath is provided, get the value from userDetail
            tokenValue = get(userDetail, `${dataFetchPath}`);
          }
          updatedHtmlContent = updatedHtmlContent.replaceAll(`%${token}%`, tokenValue || "");
        });
      // Return the updated HTML content
      return updatedHtmlContent;
    }
    // Return the original HTML content if config, configPath, or userDetail is missing
    return htmlContent;
  }
  // Return an empty string if HTML content is not provided
  return "";
};

export const getProfilePicture = (userDetail) => {
  let loggedInUserInfo = userDetail;
  return get(loggedInUserInfo, "user.avatarUrl")
    ? getAvatarLiveURL(loggedInUserInfo.user.avatarUrl)
    : DefaultAvatarImage;
};

/**s
 * Fetches a document from the provided URL, creates a Blob, and opens a new window to preview the document.
 * Displays a notification if pop-ups are blocked or an error occurs during the process.
 * @param {string} url - The URL of the document to preview.
 * @param {string} fileExtension - The file extension of the document (e.g., 'pdf', 'docx').
 */
export const previewDocuments = (url, fileExtention) => {
  // Check if both URL and file extension are provided
  if (url && fileExtention) {
    // Make a GET request to the provided URL with a response type of 'blob'
    Axios.get(url, {
      responseType: "blob",
    })
      .then((response) => {
        // Create a Blob from the response data with the appropriate file type
        const file = new Blob(
          [response.data],
          getResponseTypeByFileExtension(fileExtention)
        );

        // Create a URL for the Blob
        const fileURL = URL.createObjectURL(file);

        const newWindow = window.open(fileURL, "_blank");
        
        // Check if the new window was successfully opened
        if (!newWindow || newWindow.closed) {
          notify("Pop-ups are blocked in your browser. Please allow pop-ups to view the document. ", {
            type: ToastType.INFO,
            autoClose: true
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    toast.error("Invalid URL or file extension")
  }
};

export const getResponseTypeByFileExtension = (extension) => {
  // Define a mapping of file extensions to content types
  const contentTypeMapping = {
    pdf: "application/pdf",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    // Add more extensions and content types as needed
  };

  // Convert the extension to lowercase (e.g., 'PDF' becomes 'pdf')
  const lowercaseExtension = extension.toLowerCase();

  // Check if the extension exists in the mapping
  if (contentTypeMapping[lowercaseExtension]) {
    return { type: contentTypeMapping[lowercaseExtension] };
  } else {
    // Return a default type or an error message if the extension is not recognized
    return { type: "application/octet-stream" }; // Default type for unknown extensions
  }
};

/**
 * Detects the current web browser based on the user agent string.
 * Returns the name of the detected browser, or "Other" if the browser is not recognized.
 * Browser detection is performed by checking for specific keywords in the user agent string.
 * Supported browsers include Firefox, Chrome, Safari, Internet Explorer, Microsoft Edge, and Opera.
 * @returns {string} The name of the detected browser.
 */
export function detectBrowser() {
  const userAgent = navigator.userAgent;

  if (userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (
    userAgent.indexOf("MSIE") !== -1 ||
    userAgent.indexOf("Trident/") !== -1
  ) {
    return "Internet Explorer";
  } else if (userAgent.indexOf("Edge") !== -1) {
    return "Microsoft Edge";
  } else if (
    userAgent.indexOf("Opera") !== -1 ||
    userAgent.indexOf("OPR") !== -1
  ) {
    return "Opera";
  } else {
    return "Other";
  }
}

/**
 * 
 * @param {*} pricingItems 
 * @param {*} amountFieldKey 
 * @param {*} comboDiscountConfiguration 
 * @returns pricingItems by added new property as Combo
 */
export const applyComboDiscounts=(pricingItems, amountFieldKey, comboDiscountConfiguration)=>
{
  if(!checkIfArray(pricingItems) && pricingItems.length>1){
    return null;
  }
  if(!checkIfObject(comboDiscountConfiguration) || !comboDiscountConfiguration.isActive || !checkIfObject(get(comboDiscountConfiguration,`comboDiscounts`))){
    return null;
  }
  const {comboDiscounts,comboDiscountsType} = comboDiscountConfiguration;
  let comboDiscountsKeys = keys(comboDiscounts);
  comboDiscountsKeys = values(comboDiscountsKeys);
  let discount = 0;
  if(parseFloat(comboDiscountsKeys[comboDiscountsKeys.length])<pricingItems.length){
    discount = parseFloat(comboDiscountsKeys[comboDiscountsKeys.length])
  }else{
    discount = find(comboDiscountsKeys,(val)=>{
      const intVal = parseFloat(val);
      return intVal===pricingItems.length || intVal>pricingItems.length;
    });
  }
  const COMBO_DISCOUNT_TYPE = {
    PERCENTAGE:"percentage",
    AMOUNT:"amount"
  }
  if(!!comboDiscounts[discount]){
    pricingItems = map(pricingItems,item=>{      
      const amount = parseFloat(item[amountFieldKey]);
      const discountValue = parseFloat(comboDiscounts[discount]);
      let discountAmount = 0;
      if(comboDiscountsType === COMBO_DISCOUNT_TYPE.PERCENTAGE){
        discountAmount = amount - (amount*discountValue)/100;
        discountAmount = parseFloat(discountAmount).toFixed(2);
      }
      if(comboDiscountsType === COMBO_DISCOUNT_TYPE.AMOUNT){
        discountAmount = amount - discountValue;
      }
      if(!!discountAmount){
        set(item,`combo${amountFieldKey}`,parseFloat(discountAmount));
      }
      return item;
    });
    return parseFloat(parseFloat(sumBy(pricingItems,`combo${amountFieldKey}`)).toFixed(2));
  }
  return null;
}

/**
 * 
 * @param {*} excludeFields 
 * @param {*} addExcludeFieldsByValues 
 * @param {*} initialFormData 
 * @returns exclude fields according to intial form data
 */
export const updateExcludeByFieldsValue=(excludeFields=[], addExcludeFieldsByValues={}, initialFormData={})=>{
  if(checkIfObject(addExcludeFieldsByValues) && checkIfObject(initialFormData)){
    let newExcludeFields = checkIfArray(excludeFields) ? [...excludeFields] : [];
    for(const fieldKey in addExcludeFieldsByValues){
      let fieldValue = get(initialFormData, fieldKey);
      fieldValue = checkIfArray(fieldValue) ? fieldValue : fieldValue ? [fieldValue] : [];
      let skipValues = get(addExcludeFieldsByValues, fieldKey);
      skipValues = checkIfArray(skipValues) ? skipValues : skipValues ? [skipValues] : [];
      if(checkIfArray(fieldValue) && checkIfArray(skipValues) && intersection(fieldValue,skipValues).length){
        newExcludeFields.push(fieldKey);
      }else if(excludeFields.includes(fieldKey)){
        newExcludeFields = remove(newExcludeFields, (n) => n !== fieldKey)
      }
    }
    return newExcludeFields
  }
  return excludeFields;
}

/**
 * 
 * @param {*} value1 
 * @param {*} value2 
 * @returns checks (true or false) if value2 is present in value2
 */
export const checkIfValuePresent = (array1 , array2) => {
    if(!array1 || !array2) return null;
    array1 = checkIfArray(array1) ? array1 : array1 ? [array1 ] : null;
    array2 = checkIfArray(array2) ? array2 : array2 ? [array2] : null;
    return !!array1 && !!array2 && intersection(array1,array2).length;
}
/**
 * 
 * @param {*} ref 
 * @param {*} formData 
 * @param {*} canDisplay 
 * @returns checks if we need hide or show component based on formData
 */
export const checkHideAndShowConfig = (ref, formData, canDisplay = false,)=> {
  if(checkIfObject(ref)){
    const {showFields, hideFields} = ref || {};
    if(checkIfObject(showFields)){
      for(const fieldKey in showFields){
        canDisplay = checkIfValuePresent(get(formData, fieldKey), get(showFields, fieldKey))
      }
    }
    if(checkIfObject(hideFields)){
      for(const fieldKey in hideFields){
        canDisplay = !checkIfValuePresent(get(formData, fieldKey), get(hideFields, fieldKey))
      }
    }
  }
  return canDisplay;
}

export const getAllFields = (stepConfig)=> {
  const panels = get(stepConfig,`config.panels`);
  const fields = checkIfObject(panels) && Object.keys(panels).reduce((newFields, panel)=>{
    const field = get(panels,`${panel}.fields`);
    const fieldArray = checkIfObject(field) && Object.keys(field);
    fieldArray && newFields.push(...fieldArray);
    return newFields;
  },[]);
  return fields;
}


export const isPreferenceSequencesSequential = (
  preferenceSequences,
  enteredValue,
  incorrectPreferenceSequence = {}
) => {
  const sequentialSingleDigitArr = Array.from(
    { length: 9 },
    (_, index) => index + 1
  );
  const sortedArray = enteredValue ? [...new Set(preferenceSequences.sort((a, b) => a - b))] : preferenceSequences.sort((a, b) => a - b);

  for (let i = 0; i <= sortedArray.length; i++) {
    const sequence = i + 1;
    if(!enteredValue && sortedArray[i] && sortedArray[i] === sortedArray[sequence]){
      incorrectPreferenceSequence.current = sortedArray[i];
      incorrectPreferenceSequence.isDuplicate = true;
      return false;
    } else if (sortedArray[i] !== sequence) {
      const isSingleDigitArr = sequentialSingleDigitArr.every((value) =>
        preferenceSequences.includes(value)
      );
      if(enteredValue && isSingleDigitArr){
        if(enteredValue.toString().length !== sequence.toString().length){
           if(enteredValue.toString()[0] !== sequence.toString()[0]){
            incorrectPreferenceSequence.current = sequence;
            return false;
           }else{
            return true;
           }
        }else if(sequence !== enteredValue){
          incorrectPreferenceSequence.current = sequence;
          return false;
        }else{
          return true;
        }
      } else if (
        enteredValue &&
        sequence !== enteredValue
      ) {
        incorrectPreferenceSequence.current = sequence;
        return false;
      } else if (enteredValue) {
        return true;
      } else if (i !== sortedArray.length) {
        incorrectPreferenceSequence.current = sequence;
        return false;
      }
    }
  }

  return true;
};


/**
* @creationDate 05 May 2021
* @description Check into the string if it contains an email or url then it replace with anchor tag containing that email or url. 
*/
export const replaceUrlOrEmailToHyperlink = (
  msg,
  isReplaceEmail = true,
  replacedWithStr = false
) => {
  const regExForUrl =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const regExForEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
  if (!regExForUrl.test(msg) && !regExForEmail.test(msg)) return msg;
  let extractedUrl = checkIfArray(replacedWithStr)
    ? msg.match(regExForUrl)
    : [...new Set(msg.match(regExForUrl))];
  let extractedEmail = [...new Set(msg.match(regExForEmail))];
  let updatedMsg = msg;
  let anchorTagInit = `<a target="_blank" rel="noopener noreferrer" style="color:#0645AD !important"`;
  let anchorTagForLastInit = `<a target="_blank" rel="noopener noreferrer" style="color:#0645AD !important ;margin-right: 65px; line-height: initial; display: inherit; word-break: break-word;"`;
  const isArrayExtractedEmail = checkIfArray(extractedEmail);
  if (isArrayExtractedEmail && isReplaceEmail) {
    let extractedUrlLength = extractedEmail.length;
    for (let i = 0; i < extractedUrlLength; i++) {
      let checkEmailAtLastPostion =
        updatedMsg.length - updatedMsg.lastIndexOf(extractedEmail[i]) ===
        extractedEmail[i].length;
      if (i === extractedUrlLength - 1 && checkEmailAtLastPostion) {
        updatedMsg = updatedMsg.replaceAll(
          extractedEmail[i],
          `${anchorTagForLastInit} title="mailto:${extractedEmail[i]}" href="mailto:${extractedEmail[i]}">${extractedEmail[i]}</a>`
        );
      } else {
        updatedMsg = updatedMsg.replaceAll(
          extractedEmail[i],
          `${anchorTagInit} title="mailto:${extractedEmail[i]}" href="mailto:${extractedEmail[i]}">${extractedEmail[i]}</a>`
        );
      }
    }
  }
  if (checkIfArray(extractedUrl)) {
    let extractedUrlLength = extractedUrl.length;
    for (let i = 0; i < extractedUrlLength; i++) {
      if (isArrayExtractedEmail && extractedEmail.includes(extractedUrl[i]))
        continue;
      if (checkIfArray(replacedWithStr)) {
        let smsBodyTokens = extractedUrl[i].split("%");
        if (checkIfArray(smsBodyTokens)) {
          smsBodyTokens = smsBodyTokens.map((ele) => {
            if (ele) {
              ele = "%" + ele + "%";
            }
            return ele;
          });
          smsBodyTokens = smsBodyTokens.filter((ele) =>
            replacedWithStr.find(
              (token) => token.tokenValue && ele && token.tokenValue === ele
            )
          );
          if (checkIfArray(smsBodyTokens)) {
            const replacedWith = replacedWithStr[i]
              ? replacedWithStr[i].tokenValue + " ."
              : "%Lead.Email% .";
            updatedMsg = updatedMsg.replace(extractedUrl[i], replacedWith);
          }
        }
      } else {
        let checkUrlAtLastPostion =
          msg.length - msg.lastIndexOf(extractedUrl[i]) ===
          extractedUrl[i].length;
        if (
          extractedUrl[i].indexOf("http") === -1 &&
          extractedUrl[i].indexOf("https") === -1
        ) {
          if (i === extractedUrlLength - 1 && checkUrlAtLastPostion) {
            updatedMsg = updatedMsg.replaceAll(
              extractedUrl[i],
              `${anchorTagForLastInit} title="http://${extractedUrl[i]}" href="http://${extractedUrl[i]}">${extractedUrl[i]}</a>`
            );
          } else {
            updatedMsg = updatedMsg.replaceAll(
              extractedUrl[i],
              `${anchorTagInit} title="http://${extractedUrl[i]}" href="http://${extractedUrl[i]}">${extractedUrl[i]}</a>`
            );
          }
        } else {
          if (i === extractedUrlLength - 1 && checkUrlAtLastPostion) {
            updatedMsg = updatedMsg.replaceAll(
              extractedUrl[i],
              `${anchorTagForLastInit} title="${extractedUrl[i]}" href="${extractedUrl[i]}">${extractedUrl[i]}</a>`
            );
          } else {
            updatedMsg = updatedMsg.replaceAll(
              extractedUrl[i],
              `${anchorTagInit} title="${extractedUrl[i]}" href="${extractedUrl[i]}">${extractedUrl[i]}</a>`
            );
          }
        }
      }
    }
  }
  return updatedMsg;
};


export const validateDateRange = (newEntry, gridData, config, dateFormat = FORM_INPUT_VALUE_FORMAT.date.toUpperCase() ) => {
  const { outsideDateRange} = config || {};
  if(checkIfArray(gridData) && checkIfObject(outsideDateRange)){
    const {id} = newEntry || {};
    const {fromDateFieldKey, toDateFieldKey} = outsideDateRange;
    if(newEntry && toDateFieldKey && fromDateFieldKey && newEntry[fromDateFieldKey] && newEntry[toDateFieldKey]){
      const currentFrom = moment(newEntry[fromDateFieldKey], dateFormat);
      const currentTo = moment(newEntry[toDateFieldKey], dateFormat);
      if(currentFrom && currentTo){
        for(let i = 0; i < gridData.length; i++){
          const data = gridData[i];
          if(data.id == id) continue;
          const previousFrom = moment(data[fromDateFieldKey], dateFormat);
          const previousTo = moment(data[toDateFieldKey], dateFormat);
          const isInBetween = moment(previousFrom).isBetween(currentFrom, currentTo) || moment(previousTo).isBetween(currentFrom, currentTo) || moment(currentFrom).isBetween(previousFrom, previousTo) || moment(currentTo).isBetween(previousFrom, previousTo) || (moment(currentTo).isSame(previousTo) || moment(currentFrom).isSame(previousFrom) || moment(currentTo).isSame(previousFrom) || moment(currentFrom).isSame(previousTo))
          if(isInBetween){
            return false;
          }
        }
      }
    }
    //commented for future use
    // else if(insideDateRange){
    //   const {fromDateFieldKey, toDateFieldKey} = insideDateRange;
    //   const currentFrom = moment(newEntry[fromDateFieldKey], dateFormat);
    //   const currentTo = moment(newEntry[toDateFieldKey], dateFormat);
    //   if(toDateFieldKey && fromDateFieldKey && currentFrom && currentTo){
    //     for(let i = 0; i < gridData.length; i++){
    //       if(i == id - 1) continue;
    //       const data = gridData[i] || {};
    //       const previousFrom = data[fromDateFieldKey] && moment(data[fromDateFieldKey], dateFormat);
    //       const previousTo = moment(data[toDateFieldKey], dateFormat);
    //       const isInBetween = moment(previousFrom).isBetween(currentFrom, currentTo) || moment(previousTo).isBetween(currentFrom, currentTo) || moment(currentFrom).isBetween(previousFrom, previousTo) || moment(currentTo).isBetween(previousFrom, previousTo)
    //       if(!isInBetween){
    //         return false;
    //       }
    //     }
    //   }
    // }
  }
  return true;
}

/**
 * Dynamically generates a mathematical expression and validates it based on provided data.
 *
 * @param {string} cal - The mathematical expression template to be generated and validated.
 * @param {Object} gridRowData - The data object used to replace variables in the expression.
 * @param {Array} headerItems - The array of header items containing meta information.
 * @returns {number|boolean} - The result of the validated mathematical expression if valid, otherwise false.
 */
export const generateMathExpressionDynamicallyAndValidate = (headerItems, cal, gridRowData) => {
  let updatedGridRowData = cloneDeep(omitBy(gridRowData, isEmpty));

  // Validate input parameters and update the expression with data values
  !!cal &&
    checkIfObject(updatedGridRowData) &&
    checkIfArray(headerItems) &&
    headerItems.forEach((metaItem) => {
      const { metaKey } = metaItem || {};
      cal = replace(
        cal,
        new RegExp(metaKey, "g"),
        cal.indexOf(metaKey) > -1
          ? updatedGridRowData[metaKey] || "NotFound"
          : metaKey
      );
    });

  try {
    // Validate and return the result of the mathematical expression
    if (!isNaN(eval(cal)) && eval(cal) !== "NaN") {
      return eval(cal);
    }
  } catch (error) {
    return false; // Return false if an error occurs during evaluation
  }
  
  return false; // Return false if the expression is invalid or cannot be evaluated
};

/**
 * Sets the maximum height of the ".right-content" element to ensure it fits within the viewport.
 * The calculated maximum height is determined as 100% of the viewport height minus 3.5rem.
 * This function is designed to be used in scenarios where the height of an element needs
 * to be constrained to prevent it from exceeding the available viewport space.
 */
export const setMaxHeight = () => {
  // Get a reference to the .right-content element
  const rightContent = document.querySelector(".right-content");

  // Calculate the maximum height (100% of the viewport height - 3.5rem)
  const viewportHeight = window.innerHeight;
  const maxHeight =
    viewportHeight -
    3.5 * parseFloat(getComputedStyle(document.documentElement).fontSize);

  // Set the maximum height on the element
  rightContent.style.maxHeight = `${maxHeight}px`;
}

/**
* @author Chintan Patel
* @creationDate 29 November 2023
* @description  check if id is to be exluded or included in the list
* @param id id to be checked
* @param exludeArray Fields to be Exluded
* @param includeArray Fields to be  Included
* @returns boolean
*/
export const isItemIncluded = (id, exludeArray = null, includeArray = ["-1"]) => {
  includeArray = checkIfArray(includeArray) ? includeArray : ["-1"];
  const includeAll = includeArray.includes(-1) || includeArray.includes("-1") ;
  const excludeAll = checkIfArray(exludeArray) && (exludeArray.includes(-1) || exludeArray.includes("-1")) ;
  // if excludeArray is null or empty then include all
  if(id && includeAll && !exludeArray)
    return true;
  // if excludeArray is not null and id is in excludeArray then return false
  else if (id && checkIfArray(exludeArray) && exludeArray.includes(id))
    return false;
  // if excludeAll is given and id is present in include Array true
  if(excludeAll && includeArray.includes(id))
   return true;
  // priority will be given to excludeArray and return false
  if(excludeAll && includeAll)
    return false;
  // if includeAll is given or id is present in include Array return true
  else if(includeArray.includes(id) || includeAll)
    return true; 
  else
    return false;
}

export const parseJsonSafely = (settingJson, sectionId ="", tabId = "") => {
  let newJson = "";
  try{
    newJson = JSON.parse(settingJson);
    return newJson
  }catch(e){
    console.error(`Error in Json ${sectionId}: ${tabId}`);
    console.error(e);
    return false;
  }
}

/**
 * @description Sorts a collection of dropdown options based on provided sorting configuration.
 * 
 * @param {Array} dropDownCollection - The collection of dropdown options to be sorted.
 * @param {Object} sortingConfig - Configuration object specifying sortBy and orderBy parameters.
 * @returns {void} - Mutates the 'dropDownCollection' in place based on the sorting configuration.
 */
export const sortOptions = (dropDownCollection, sortingConfig) => {
  const { sortBy:sortingSortBy, orderBy } = sortingConfig || {};
  let updatedDropDownCollection = cloneDeep(dropDownCollection);

  // Ensure 'dropDownCollection' is an array, otherwise return early
  if (!checkIfArray(dropDownCollection)) return;

  if (!!sortingSortBy) {
    // Sort by the specified property
    const sortingOrderBy = orderBy || SORT_ORDER.ASC;

    updatedDropDownCollection.sort((a, b) => {
      const first = a[sortingSortBy] ? a[sortingSortBy] : a["name"];
      const second = b[sortingSortBy] ? b[sortingSortBy] : b["name"];
      if (first < second) return -1;
      if (first > second) return 1;
      return 0;
    });

    // Reverse the order if 'orderBy' is 'desc'
    if (sortingOrderBy === SORT_ORDER.DESC) {
      updatedDropDownCollection.reverse();
    }
  } else {
    // Sort by default property ('name' in this case)
    updatedDropDownCollection = sortBy(updatedDropDownCollection, ["name"]);
  }
  return updatedDropDownCollection;
}


export async function isLinkExist(url) {
  try{
    var http = new XMLHttpRequest();
    http.open("HEAD", url, false);
    await http.send();
    return http.status != 404;
  }catch(e){
    return false;
  }
}

/**
 * @author Suyash Balshetwar
 * @creationDate 07 Dec 2023
 * @description  Retrieves the lead status key associated with the given step index from an array of steps.
 * @param {Array} steps - An array of steps containing information about each step.
 * @param {number} stepIndex - The index of the step for which to retrieve the lead status key.
 * @returns {string} - The lead status key associated with the specified step index.
 */
export const getCurrentLeadStatusKey = (steps, stepIndex) => {
  let currentLeadStatusKey = ''
  if (checkIfArray(steps)) {
    steps.some(({ index, lead_status_key }) => {
      // Check if the current step's index matches the specified 'stepIndex'
      if (index === stepIndex) {
        // Assign the lead status key of the matching step to the 'currentLeadStatusKey'
        currentLeadStatusKey = lead_status_key;
        return true;
      }
    });
  }
  return currentLeadStatusKey;
}

/**
 * @author Suyash Balshetwar
 * @creationDate 07 Dec 2023
 * @description  Retrieves the step index associated with a given lead status key from an array of steps.
 * @param {Array} steps - An array of steps containing information about each step.
 * @param {string} leadStatusKey - The lead status key for which to retrieve the step index.
 * @returns {number|null} - The step index associated with the specified lead status key, or null if not found.
 */
export const getStepIndexAccordingToLeadStatusKey = (steps, leadStatusKey) => {
  let stepIndex = ''
  if (checkIfArray(steps)) {
    steps.some(({ index, lead_status_key }) => {
      // Check if the current step's lead status key matches the specified 'leadStatusKey'
      if (leadStatusKey === lead_status_key) {
        // Assign the step index of the matching step to the 'stepIndex'
        stepIndex = index;
        return true;
      }
    });
  }
  return stepIndex;
}
/**
 * This function will return same values in `group_key` and `restrict_same_value_group_key` wise mapping to avoid unwanted looping multiple time.
 * @param {*} fontConfig 
 * @returns 
 */
export const getGroupKeyMappingObject = (fontConfig) =>{
  let sameGroupFieldsKey = {};
  let restrictSameValueGroupFieldskey = {};
  if(fontConfig && fontConfig.steps && checkIfArray(fontConfig.steps)){
    const {steps} = fontConfig;
    steps.forEach(step => {
      const panels = get(step, "config.panels");
      if(checkIfObject(panels)){
        for(const [panelKey, panelValue] of Object.entries(panels)){
          const fields = get(panelValue, "fields");
          if(checkIfObject(fields)){
            for(const [fieldsKey, fieldsValue] of Object.entries(fields)){
              const {dataCollectionName,group_key,restrict_same_value_group_key} =fieldsValue; 
              if(!!group_key && !!dataCollectionName){
                  const mappedObjectKey = `${group_key}_${dataCollectionName}`;
                  if(!has(sameGroupFieldsKey,mappedObjectKey)){
                    set(sameGroupFieldsKey,mappedObjectKey,[]);
                  } 
                  if(!((sameGroupFieldsKey[mappedObjectKey]).includes(fieldsKey))){                    
                    (sameGroupFieldsKey[mappedObjectKey]).push(fieldsKey);
                  }
              }
              if(!!restrict_same_value_group_key && !!dataCollectionName){
                const mappedObjectKey = `${restrict_same_value_group_key}_${dataCollectionName}`;
                if(!has(restrictSameValueGroupFieldskey,mappedObjectKey)){
                  restrictSameValueGroupFieldskey[mappedObjectKey]=[];
                } 
                if(!(restrictSameValueGroupFieldskey[mappedObjectKey]).includes(fieldsKey)){
                  (restrictSameValueGroupFieldskey[mappedObjectKey]).push(fieldsKey)
                }
              }               
            }
          } 
        }
      }
    });
    return {restrictSameValueGroupFieldskey,sameGroupFieldsKey};
  }
  return {restrictSameValueGroupFieldskey:{},sameGroupFieldsKey:{}};
}

export const isAccessTokenTableKeyEnabled = () => {
  return get(window.extraaedge, "accessTokenTableKey")
}

export const logoutApplicationForm = (funcParams) => {
  const {logout, clientInfo, history} = funcParams || {};
  logout && logout();
  let searchParams = {};
  !!OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL) &&
    set(
      searchParams,
      STORAGE_KEY.INST,
      OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)
    );

  !!OfflineStorage.getItem(
    STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
    STORAGE_TYPE.LOCAL
  ) &&
    set(
      searchParams,
      STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
      OfflineStorage.getItem(
        STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
        STORAGE_TYPE.LOCAL
      )
    );

  const allOfflineStorageData = OfflineStorage.getAllItem();

  if (checkIfObject(allOfflineStorageData)) {
    Object.keys(allOfflineStorageData).forEach((lsKey) => {
      if (
        isNil(get(searchParams, lsKey)) &&
        !isNil(OfflineStorage.getItem(lsKey, STORAGE_TYPE.LOCAL))
      )
        OfflineStorage.deleteItem(lsKey, STORAGE_TYPE.LOCAL);
    });
  } else {
    OfflineStorage.clear(STORAGE_TYPE.LOCAL);
    OfflineStorage.clear();
  }
  const logoutActive = !!get(
    clientInfo,
    `afterLogoutRedirect.isActive`
  );
  const logoutRedirectUrl = get(
    clientInfo,
    `afterLogoutRedirect.href`
  );

  if (logoutActive && !!logoutRedirectUrl) {
    window.location.href = logoutRedirectUrl;
    return;
  } else if (checkIfObject(searchParams)) {
    history.push({
      pathname: PAGES.ROOT,
      search: "?" + convertObjectToSearchParamString(searchParams),
    });
  } else {
    history.push({ pathname: PAGES.ROOT });
  }
  window.location.reload();
};


export function queryStringToObject(queryString) {
  const params = {};
  queryString = queryString.replace(/&amp;/g, "&")
  const keyValuePairs = queryString.split('&');

  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    params[decodeURIComponent(key)] = decodeURIComponent(value || '');
  });

  return params;
}

export const onApplyNowClick = (funcParams) => {
  const {userDetail, clientInfo, widgetFormConfig, webhookAddLead, onSucceessAddlead} = funcParams || {}
  if(userDetail && widgetFormConfig) {
    const {
      fields: widgetFormFields,
      apiRequestDefaultParameter,
    } = widgetFormConfig || {};
    const {
      Info: { authToken, source },
    } = clientInfo || {};
    const {lead, user} = userDetail
    let updatedEntityObj = {};
    Object.keys(EntityFieldNameByCriteriaValue).map((entityKey) => {
      const widgetFormFieldConfig =
        widgetFormFields[entityKey.replace("Id", "")] || {};
      let { apiKey } = widgetFormFieldConfig;
      apiKey = apiKey
        ? apiKey.includes("Ids")
          ? apiKey
          : `${apiKey}Ids`
        : apiKey;
      if (apiKey && lead[EntityFieldNameByCriteriaValue[entityKey]]) {
        updatedEntityObj = {
          ...updatedEntityObj,
          [apiKey]: lead[EntityFieldNameByCriteriaValue[entityKey]],
        };
      }
    });
    const { mobileNumber: MobileNumber, email: Email, firstName: FirstName } = user || {};
    webhookAddLead({
      apiParams: {
        ...(apiRequestDefaultParameter || {}),
        AuthToken: authToken,
        Source: source,
        Email,
        FirstName,
        MobileNumber,
        eesourceid: 16,
        IsGetAllLeadDetails: true,
        ...updatedEntityObj,
      },
      onSucceessAddlead,
      webhookAddLeadCallFrom: "dashboardApplyNow"
    });
  } else {
    console.error("Function Params is mission to apply");
    console.error("Lead data: ", userDetail);
    console.error("widgetFormConfig", widgetFormConfig);
  }
};

/**
 * @author Shriyash Balshetwar
 * @description Retrieves the lead status key associated with the given step index from an array of steps.
 * @param {Array} steps - An array of steps containing information about each step.
 * @param {number} stepIndex - The index of the step for which to retrieve the lead status key.
 * @returns {string} - The lead status key associated with the specified step index.
 */
export const getInstallmentPaymentDetails = (paymentDetails, feesType, payment_txn_success_status) => {
  if(checkIfArray(paymentDetails)){
    return paymentDetails.reduce((result,item)=>{
      const {paymentDetailsModel:{feesType:_feesType,transactionStatus="",partPaymentKey=null}={},partPaymentDetails=null} = item || {}; 
      const {balanceAmount=0} = partPaymentDetails || {};
      if(_feesType === feesType && payment_txn_success_status.includes(transactionStatus) && !!partPaymentKey && checkIfObject(partPaymentDetails) && balanceAmount>0){
          result.push(partPaymentDetails)
        }
      return result;
    },[]);
  }
  return [];
};

/**
 * @description Retrieves mandatory payment parameters from the given payment parameters object.
 * @param {Object} paymentParams - An object containing payment parameters.
 * @returns {Object} - An object containing only the mandatory payment parameters.
 */
export const  getMandatoryPaymentParams = (paymentParams) => {
  return checkIfObject(paymentParams)
  ? MANDATORY_PAYMENT_PARAM_KEYS.reduce((result, key) => {
      if (paymentParams.hasOwnProperty(key)) {
        result[key] = paymentParams[key];
      }
      return result;
    }, {})
  : {};  
}

/**
 * @description Retrieves the payment transaction success status from the client information.
 * @param {Object} clientInfo - Client information object.
 * @returns {Array} - An array containing the payment transaction success statuses.
 */
export const getPaymentTransactionSuccessStatus = (clientInfo) => {
  const payment_success_status = get(clientInfo, "paymentDetails.payment_success_status");
    return checkIfArray(payment_success_status)
    ? payment_success_status
    : PAYMENT_SUCCESS_STATUS;
}

/**
 * @description Encodes the given string using Base64 encoding.
 * @param {string} string - The string to be encoded.
 * @returns {string} - The Base64-encoded string.
 */
export const base64Encode = (string) => {
  const stringBytes = new TextEncoder().encode(string);
  return btoa(String.fromCharCode.apply(null, stringBytes));
};

/**
 * @description Decodes the given Base64-encoded data.
 * @param {string} base64EncodedData - The Base64-encoded data.
 * @returns {string} - The decoded string.
 */
export const base64Decode = (base64EncodedData) => {
  const decodedBytes = new Uint8Array(
    atob(base64EncodedData)
      .split("")
      .map((c) => {
        return c.charCodeAt(0);
      })
  );
  return new TextDecoder().decode(decodedBytes);
};

/**
 * @description Retrieves the user role from the decoded access token stored in offline storage.
 * @returns {string} - The user role.
 */
export const getRole = () => {
  const accessToken = OfflineStorage.getItem(STORAGE_KEY.ACCESS_TOKEN, true);
  const decodedToken = jwtDecode(accessToken);
  return get(decodedToken, `${ACCESS_TOKEN_CLAIMS[STORAGE_KEY.ROLE]}`);
}

/**
 * @description Checks if all installment amounts are paid for a given user detail, payment transaction success statuses, and fee type.
 * @param {Object} userDetail - User details object.
 * @param {Array} payment_txn_success_status - Payment transaction success statuses.
 * @param {string} feeType - Fee type.
 * @returns {boolean} - True if all installment amounts are paid; otherwise, false.
 */
export const checkIfAllInstallmentAmountPaid = (userDetail, payment_txn_success_status, feeType) => {
  const {paymentDetails} = userDetail || {};
  const feesType = parseInt(feeType || "");
  const installmentPaymentDetails = feesType && getInstallmentPaymentDetails(paymentDetails, feesType,payment_txn_success_status);
  if(checkIfArray(installmentPaymentDetails)){
    const balanceAmount = get(installmentPaymentDetails[0], "balanceAmount");
    return balanceAmount > 0
  }
  return false;
}

export const filterMasterDataByPricingCriteria = (funcParams) => {
  const {
    masterData,
    isPricingCriteriaActive,
    visibleFieldKeys,
    widgetFormFields,
    fields,
  } = funcParams || {};
  const pricingCriterion = get(masterData, `${STORAGE_KEY.PRICING_CRITERIA}`);
  const masterDataCollection = getMasterDataByKey(pricingCriterion, masterData);
  let pricingList = getMasterDataByKey(`PricingList`, masterData) || [];
  pricingList = uniq(map(pricingList, "entityId"));
  let dropdownOptions = {};
  let visibleDropdownOptions = {};
  if (checkIfArray(pricingList) && checkIfArray(masterDataCollection)) {
    if (isPricingCriteriaActive) {
      let entityNumber = parseInt(pricingCriterion.replace(/[^0-9]/g, ""));
      const updatedMasterDataCollection = filter(masterDataCollection, (o) => {
        return pricingList.includes(o.id);
      });
      dropdownOptions = {
        [upperFirst(pricingCriterion)]: updatedMasterDataCollection,
      };
      while (entityNumber > 1) {
        let currentArrayName = "Entity" + entityNumber;
        let parentArrayName = "Entity" + (entityNumber - 1);
        let parentFieldName = "entity" + (entityNumber - 1) + "Id";
        const currentArrayNameCollection = dropdownOptions[currentArrayName];
        let currentIds = checkIfArray(currentArrayNameCollection)
          ? uniq(compact(map(currentArrayNameCollection, parentFieldName)))
          : [];
        let parentArrayNameCollection =
          getMasterDataByKey(parentArrayName, masterData) || [];
        parentArrayNameCollection = filter(parentArrayNameCollection, (o) => {
          return currentIds.includes(o.id);
        });
        dropdownOptions = {
          ...dropdownOptions,
          [parentArrayName]: parentArrayNameCollection,
        };
        entityNumber--;
      }
    }

    if (checkIfArray(visibleFieldKeys)) {
      visibleFieldKeys.forEach((fieldKey) => {
        const widgetFormFieldConfig = widgetFormFields[fieldKey];
        const fieldConfig = {
          ...widgetFormFieldConfig,
          ...fields[fieldKey],
        };
        const {
          dataCollectionFilterOptions,
          dataCollection,
          mapping,
          sorting,
        } = fieldConfig;
        const { fieldKey: mappingfieldkey, datakey } = mapping?.parent || {};
        const { isActive, excludeIds, includeIds } =
          dataCollectionFilterOptions || {};
        const masterDataCollection = checkIfArray(
          dropdownOptions[dataCollection]
        )
          ? dropdownOptions[dataCollection]
          : getMasterDataByKey(dataCollection, masterData);
        const mappedDataCollection = get(
          widgetFormFields,
          `${mappingfieldkey}.dataCollection`
        );
        let parentArrayNameCollection =
          mappedDataCollection &&
          checkIfArray(dropdownOptions[mappedDataCollection])
            ? dropdownOptions[mappedDataCollection]
            : getMasterDataByKey(mappingfieldkey, masterData) || [];
        const parentValues = checkIfArray(parentArrayNameCollection)
          ? uniq(compact(map(parentArrayNameCollection, "id")))
          : [];
        if (isActive && checkIfArray(masterDataCollection)) {
          if (
            checkIfArray(excludeIds) &&
            excludeIds.includes(-1) &&
            checkIfArray(includeIds)
          ) {
            dropdownOptions = {
              ...dropdownOptions,
              [dataCollection]: filter(masterDataCollection, (o) => {
                return (
                  includeIds.includes(o.id) &&
                  (!mappingfieldkey ||
                    (Array.isArray(parentValues) &&
                      parentValues.includes(get(o, datakey))))
                );
              }),
            };
          } else if (checkIfArray(excludeIds) && !excludeIds.includes(-1)) {
            dropdownOptions = {
              ...dropdownOptions,
              [dataCollection]: filter(masterDataCollection, (o) => {
                return (
                  !excludeIds.includes(o.id) &&
                  (!mappingfieldkey ||
                    (Array.isArray(parentValues) &&
                      parentValues.includes(get(o, datakey))))
                );
              }),
            };
          }
        } else {
          dropdownOptions = {
            ...dropdownOptions,
            [dataCollection]: filter(masterDataCollection, (o) => {
              return (
                !mappingfieldkey ||
                (Array.isArray(parentValues) &&
                  parentValues.includes(get(o, datakey)))
              );
            }),
          };
        }
        dropdownOptions = {
          ...dropdownOptions,
          [dataCollection]:
            checkIfArray(dropdownOptions[dataCollection]) && sorting
              ? sortOptions(dropdownOptions[dataCollection], sorting)
              : dropdownOptions[dataCollection],
        };
        visibleDropdownOptions = {
          ...visibleDropdownOptions,
          [dataCollection]: dropdownOptions[dataCollection],
        };
      });
    }
  }
  return { visibleDropdownOptions, dropdownOptions };
};

export const getEntityIdBasedOnUKC = () => {
  const newEntity = OfflineStorage.getItem(
    STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
    STORAGE_TYPE.LOCAL
  );
  return newEntity || 0;
}

export const convertDataIntoArray = (data) => {
  return Array.isArray(data) ? data: data ? [data]: []
}

export const getIsLeadOnlineAPIParams = (allApplicationsData,onlineMasterKey) => {
  let multipleLeadEvents = [];
  if(checkIfObject(allApplicationsData)){
    for (const newId in allApplicationsData){
      const indexedKeyValueAttributes = get(allApplicationsData,`${newId}.metaData.indexedKeyValueAttributes`);
      const metaData = find(indexedKeyValueAttributes,item=>item.keyId===onlineMasterKey.id);
      multipleLeadEvents.push({
        "LeadId": newId,
        "id": get(metaData,"id") || 0
      })
    }
  }
  if(multipleLeadEvents.length === 1) {
    multipleLeadEvents = [];
  }
  return multipleLeadEvents;
}

/**
 * @author Suyash Balshetwar
 * @description Retrieves unique transaction history by transaction ID from the given payment details.
 * @param {Array} paymentDetails - An array containing payment details.
 * @returns {Array} - An array containing unique transaction history.
 */
export const getUniqueTransactionHistoryByTransactionId = (paymentDetails) => {
  if(checkIfArray(paymentDetails)) {
    const uniqueTransByTransactionId = new Map(); //For grouping duplicate payment history
    paymentDetails.forEach(item => {
      const { transactionId, entityId } = item?.paymentDetailsModel || {};
      if (!uniqueTransByTransactionId.has(transactionId)) {
        uniqueTransByTransactionId.set(transactionId, {...item, entityId: [entityId]});
      } else {
        uniqueTransByTransactionId.get(transactionId).entityId.push(entityId);
      } 
    });
    return Array.from(uniqueTransByTransactionId.values());
  }
  return paymentDetails;
}

/**
 * @author Shriyash Balshetwar
 * @description Clears the form data for a specified field if the selected value is not present in the provided options.
 * @param {string} fieldKey - The key of the field in the form data object.
 * @param {Array} options - An array of options to compare against the selected value.
 * @param {any} value - The selected value or an array of selected values.
 * @param {function} updateFormData - A function to update the form data.
 * @param {string} valueField - The field name within the options to compare against the selected value.
 */
export const emptyFormDataWhenSelectedValueNotInOptions = (fieldKey, options, value, updateFormData, valueField) => {
  if(checkIfArray(options)){
    let selectedValue = [];
    let isValueValid = false;
    if (checkIfArray(value)) {
      isValueValid = true;
      selectedValue = options.filter(item => value.includes(item[valueField]));
    } else if (!!value) {
      isValueValid = true;
      selectedValue = options.filter(
        item => toString(value) === toString(item[valueField])
      );
    }
    if(isValueValid && !checkIfArray(selectedValue)){
      updateFormData({
        [fieldKey]: ""
      });
    }
  }
}
/**
 * @description Checks if the combo discount feature is enabled in the given master data.
 * @param {Object} masterData - An object containing master data.
 * @returns {boolean} - True if the combo discount feature is enabled; otherwise, false.
 */
export const isComboDiscountFeatureEnabled = (masterData) => {
  const comboDiscountConfiguration = getMasterDataByKey("ComboDiscountConfiguration", masterData);
  return checkIfObject(comboDiscountConfiguration) && comboDiscountConfiguration.isActive && checkIfObject(get(comboDiscountConfiguration,`comboDiscounts`));
}

/**
 * @description Retrive value by key from access token stored in local stoage
 * @param {*} key 
 * @returns {string} - value of respective key stored in access token
 */
export const getValueFromAccessTokenByKey = (key) => {
  if(!!key){
    const accessToken = OfflineStorage.getItem(STORAGE_KEY.ACCESS_TOKEN, true);
    if(!!accessToken){
      try {
        const decodedToken = jwtDecode(accessToken);
        const value = get(decodedToken, key);
        if(!isNil(value)){
          return value;
        }else{          
          console.log(`VALUE NOT FOUND in ACCESS TOKEN for key: ${key}`);
        }        
      } catch (error) {
        console.log(`ERROR Decoding ACCESS TOKEN to get value of key: ${key}`);
      }      
    }else{
      console.log(`ACCESS TOKEN NOT FOUND to get value of key: ${key}`);
    }    
  }
}


/**
 * @description Retrive default country code for phone number
 * @param {*} key 
 * @returns {string} - value of default country code
 */
export const getDefaultCountryCode = (data, fieldKey, deafaultMobileNumber, countryCode, initialCountry, defaultCountryCodeNumber, countryCodeNumber) => {
  const phoneNumber = data || deafaultMobileNumber;
  const currentIsoCode = countryCode[fieldKey] || initialCountry || "";
  const currentDialCode = countryCodeNumber[fieldKey] || defaultCountryCodeNumber || "";
  const newValue = phoneNumber ? 
  (currentDialCode && phoneNumber.startsWith(currentDialCode)) ? 
  phoneNumber : (currentIsoCode && currentIsoCode).toUpperCase() === DEFAULT_COUNTRY_CODE.ISO_CODE ? 
  currentDialCode + phoneNumber : phoneNumber : deafaultMobileNumber;
  return newValue;
}

/**
 * Checks if all values from an array are available in another array.
 * @param {Array} availableValues - The array containing available values.
 * @param {Array} valuesToCheck - The array containing values to check.
 * @returns {boolean} - Returns true if all values from valuesToCheck are found in availableValues, otherwise false.
 */
export const isAllValuesAvailable=(availableValues, valuesToCheck)=> {
  for (let value of valuesToCheck) {
      if (!availableValues.includes(value)) {
          return false; // If any value is not found, return false
      }
  }
  return true; // If all values are found, return true
}
/**
 * @description Check mobileNumber is valid for the specific country code.
 * @param {string} phoneNumber -  mobileNumber to validate.
 * @param {string} countryCode - The country code of the phone number.
 * @param {string} countryCodeNumber - The country code number.
 * @returns {boolean} - returns true if the mobileNumber is valid or returns false otherwise.
 */
export const isValidMobileNumber = (phoneNumber, countryCode, countryCodeNumber) => {
  if (!phoneNumber || !countryCode || !countryCodeNumber) {
    return false;
  }
  const selectedCountryRegex = MOBILE_REGEX_COUNTRY_WISE.hasOwnProperty(countryCode) && MOBILE_REGEX_COUNTRY_WISE[countryCode.toUpperCase()];
  const inputValWithoutCountryCode = phoneNumber.startsWith(countryCodeNumber) ? phoneNumber.replace(countryCodeNumber, "") : phoneNumber;
  const isValid = selectedCountryRegex ? selectedCountryRegex.test(inputValWithoutCountryCode) : true;
  return isValid;
};

export const getWidgetOtpVerificationHtml = (widgetOtpConfig) => {
  if (!widgetOtpConfig || !get(widgetOtpConfig, `form.controls.resend`)) {
    return '';
  }
  const {
    sendotp: { label: sendOtpLabel } = {},
    form: { title, body, controls: { verify: { disabled: verifyOtpDisabled, title: verifyOtpTitle }, resend: { title: resendTitle, "resend-after-secs": resendAfterSecs } } } = widgetOtpConfig
  } = widgetOtpConfig;

  return {
    step_1: {
      title: `<h4>${title}</h4>`,
      description: `${body}`,
      body: [{
        id: "otp",
        label: sendOtpLabel,
        value: "",
        disabled: verifyOtpDisabled,
        type: "otpBox"
      }],
      footer: [{
        id: "resend",
        label: resendTitle,
        className: "button-secondary",
      },
      {
        id: "verifyOtp",
        label: verifyOtpTitle,
        className: "button-primary",
        disabled: true
      }]
    }
  };
};

export const getErrorMessageForExistAPI = (error)=>{
  const {response : { data: { message: dataMessage = ""} = {}} = {}, message = "" } = error || {};
  return dataMessage || message || EMAIL_AND_MOBILE_VERIFICATION_ERRORS.DEFAULT_ERROR;           
}



/**
 * Generates a Base64-encoded HMAC SHA256 signature.
 *
 * @param {string} pathname - The pathname of the request.
 * @param {string} queryString - The query string of the request.
 * @param {string} body - The body of the request.
 * @param {string} [sk="]{H?8hy}z5Q#ZL+(9mR+fsewed835*2dhHdfrhd!~sdgwe3453dghSEBSR"] - The secret key used for generating the HMAC. Defaults to a predefined string.
 * @returns {string} The Base64-encoded HMAC SHA256 signature of the input data.
 */
export function generateSignature(pathname,queryString,body,sk="]{H?8hy}z5Q#ZL+(9mR+fsewed835*2dhHdfrhd!~sdgwe3453dghSEBSR") {
  const dataToSign = `${pathname}${queryString}${body}`;
  const hash = CryptoJS.HmacSHA256(dataToSign, sk);
  return CryptoJS.enc.Base64.stringify(hash);
}
/**
 * Asynchronously encrypts a string using AES encryption with a provided secret key.
 *
 * @param {string} stringToEncrypt - The string to be encrypted.
 * @param {string} secretKey - The secret key used for encryption. Must be 128 or 256 bits in length.
 * @returns {Promise<string>} A promise that resolves to the Base64-encoded encrypted string.
 * @throws {Error} Throws an error if the secret key is not 128 or 256 bits in length.
 */
export const encryptStringWithKey = async (stringToEncrypt, secretKey) => {
  // Check if the key length is valid (128 or 256 bits)
  if (secretKey.length !== 16 && secretKey.length !== 32) {
      throw new Error('Length issue');
  }
  // Convert the secret key to an ArrayBuffer
  const secretKeyBuffer = await crypto.subtle.importKey('raw', new TextEncoder().encode(secretKey), 'AES-CBC', false, ['encrypt']);
  // Generate a random initialization vector (IV)
  const iv = crypto.getRandomValues(new Uint8Array(16));
  // Convert the string to encrypt to an ArrayBuffer
  const stringToEncryptBuffer = new TextEncoder().encode(stringToEncrypt);
  // Encrypt the string using AES encryption with the secret key and IV
  const encryptedBuffer = await crypto.subtle.encrypt({ name: 'AES-CBC', iv }, secretKeyBuffer, stringToEncryptBuffer);
  // Convert the encrypted ArrayBuffer to a Base64-encoded string
  return btoa(String.fromCharCode(...new Uint8Array(encryptedBuffer)));
}
/**
 * Asynchronously generates a value for API Key 1 by encrypting a string composed of a GUID and the current UTC time in ticks.
 *
 * @param {Object} params - The parameters for the function. Currently unused.
 * @returns {Promise<string>} A promise that resolves to the encrypted API Key 1 value.
 * @throws {Error} Throws an error if there's an issue generating the GUID.
 */
export const getAPIKey1Value = async (params) => {
  // Create a new Date object representing the current UTC date
  const currentDate = new Date(Date.UTC(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    new Date().getUTCHours(),
    new Date().getUTCMinutes(),
    new Date().getUTCSeconds()
  ));  
  // Add 2 minutes to the current UTC date
  currentDate.setUTCSeconds(currentDate.getUTCSeconds() + 30);   
  // Convert the UTC date to ticks
  const ticks = currentDate.getTime() * 10000 + 621355968000000000;
  let stringToEncrypt ="";
  try {    
    const guid = crypto.randomUUID();
    stringToEncrypt = `${guid}_${ticks}`;
  } catch (error) {
    //console.log(`error`,error);
  }
  const output = await encryptStringWithKey(stringToEncrypt, apiKey1s);
  return output;
}

export const convertSlotDateInString = (slotName) => {
  let textValue = null;
  try {
    //slotName - 30th April 11:30 PM UTC (Coordinated Universal Time)
    const parsedDate = moment.utc(slotName, "Do MMMM hh:mm A [UTC]");
    // Extract date
    const date = parsedDate.format("YYYY-MM-DD");//2024-04-30
    // Extract time
    const time = parsedDate.format("HH:mm:ss");//23:30:00
    textValue = `${date} ${time}+05:30`;
    //textValue: 2024-04-30 23:30:00+05:30
  } catch (error) {
    console.log(error);
  }
  return textValue;
};
/**
 * Asynchronously generates custom header parameters for a given URL and data.
 *
 * @param {string} url - The URL for which to generate the header parameters.
 * @param {Object|FormData} data - The data to be sent with the request. Can be an object or FormData.
 * @returns {Promise<Object|null>} A promise that resolves to an object containing the custom header parameters, or null if the parameters could not be generated.
 * @throws {Error} Throws an error if there's an issue generating the API Key 1 value or the signature.
 */
export const getCustomHeaderParams = async (url, data) => {
  let params = {};  
  try {
    let Correlation_id = await getAPIKey1Value();
    params[`${apiKey1Name}`] = Correlation_id;
  } catch (error) {
    params[`${apiKey1Name}`] = `UNABLE_TO_GENERATE`;    
  }
  
  try {
    let formDataString = "";
    if (checkIfObject(data) && data instanceof FormData) {
      let formDataArr = [];
      for (let pair of data.entries()) {
        formDataArr.push(`${pair[0]}:${pair[1]}`)
      }
      if(checkIfArray(formDataArr)){
        formDataString = formDataArr.join("");
      }
    }
    let searchQuery="",urlPathName = "";
    try {
      const urlObj = new URL(url);
      const {search="",pathname=""} = urlObj;
      searchQuery = search;
      urlPathName = pathname;
    } catch (error) {
      urlPathName = url;
    }
    const signature = await generateSignature(urlPathName,decodeURIComponent(!searchQuery?"":searchQuery.substring(1,searchQuery.length)),!!formDataString?formDataString:!data?"":JSON.stringify(data));
    if(signature){
      params[`${apiKey2Name}`] = `${signature}`;        
    }
  } catch (error) {
    params[`${apiKey2Name}`] = `UNABLE_TO_GENERATE`;    
  }
  return checkIfObject(params) ? params : null;
}

/**
 * This function returns an array of unique selected course IDs based on their names.
 * If a course name is already selected, its ID is not included in the result.
 * If no unique IDs are found, the original selectedCoursesIds array is returned.
 *
 * @param {Array} selectedCoursesIds - An array of selected course IDs.
 * @param {Array} entityDataCollection - An array of course objects, each with an id and name property.
 * @return {Array} An array of unique selected course IDs or the original selectedCoursesIds array.
 */
export const getUniqueSelctedCoursesForPayment = (selectedCoursesIds, entityDataCollection) => {
  // A Set to store the names of the selected courses. Used to check for uniqueness.
  const selectedCourseNames = new Set();
  // An array to store the IDs of the unique selected courses.
  const uniqueSelectedIds = [];

  if (
    Array.isArray(selectedCoursesIds) &&
    Array.isArray(entityDataCollection)
  ) {
    // Create a Map where the keys are course IDs and the values are the course name.
    const coursesNameById = new Map(
      entityDataCollection.map((course) => [course.id, course.name])
    );

    selectedCoursesIds.forEach((id) => {
      // Get the course name that corresponds to the current ID.
      let courseName = coursesNameById.get(id);
      courseName = courseName ? courseName.toLowerCase() : "";
      // If the course exists and its name hasn't been added to selectedCourseNames yet...
      if (courseName && !selectedCourseNames.has(courseName)) {
        selectedCourseNames.add(courseName);
        uniqueSelectedIds.push(id);
      }
    });
  }

  return uniqueSelectedIds.length > 0 ? uniqueSelectedIds : selectedCoursesIds;
}
