import React, { useEffect, useState } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { CustomTextProps } from "../../CustomPropsType";
import { FORM_INPUT_UI } from "../../utilties/Constants";
const CustomText = ({
  id,
  type,
  placeholder,
  label,
  visible,
  handleChange,
  error,
  value,
  disabled,
  required,
  cols,
  cssClass,
  maxLength,
  pattern,
  labelStyle = { fontWeight: "bold" },
  fieldConfig,
  onBlur,
  onFocus,
  customCssClass,
  addAppformGroupClass = false
}) => {
  const [inputValue, setinputValue] = useState(value);
  const [className, setclassName] = useState(
    `col-12 ${addAppformGroupClass ? "ap-form-group" : ""} {!!cssClass ? " " + cssClass : ""}${
      !!disabled ? " disabled" : ""
    }${!!error ? " is-invalid" : ""} col-md-${
      !cssClass || cssClass.indexOf("full-width") === -1 ? cols : "12"
    }`
  );
  const onChange = (event) => {
    let val =!!pattern?(event.target.value).replace(pattern, ""):event.target.value;
    handleChange(id, event.target.value);
    //call props handle change
  };
  const onFocusHandle = (event) => {
    //call props handle change
    onFocus(id, event, fieldConfig);
  };
  const onBlurHandle = (event) => {
    //call props handle change
    onBlur(id, event, fieldConfig);
  };
  const onInput = (event) => {
    if (!!type && type === FORM_INPUT_UI.MOBILE) {
      event.target.value = event.target.value
        .replace(/[^0-9+]/g, "")
        .replace(/(\..*)\./g, "$1");
    }else{
      event.target.value = event.target.value        
        .replace(/(^\s*)/gi, "")
        .replace(/[ ]{2,}/gi, " ")
        .replace(/\s\s+/g, ' ')
        .replace(/\n +/, "\n");
    }
    //call props handle change
  };
  useEffect(() => {
    // domRef.current.focus();
    setinputValue(value);
  }, [value]);
  useEffect(() => {
    // domRef.current.focus();
    setclassName(`col-12 ${addAppformGroupClass ? "ap-form-group$" : ""} ${!!cssClass ? " " + cssClass : ""}${
      !!disabled ? " disabled" : ""
    }${!!error ? " is-invalid" : ""} col-md-${
      !cssClass || cssClass.indexOf("full-width") === -1 ? cols : "12"
    }`);
  }, [disabled]);
  return visible ? (
    <FormGroup key={id} className={`${className} ${customCssClass || ""}`}>
      <Label style={labelStyle} for={id}>
        {!!label ? label : ""}
        {required && <span className="required-notation">{" *"}</span>}
      </Label>
      <Input
        name={id}
        id={id}
        maxLength={!!maxLength ? maxLength : undefined}
        placeholder={!!placeholder ? placeholder : undefined}
        disabled={!!disabled}
        invalid={!!error}
        className={
          !!disabled ? "custom-react-input disabled" : "custom-react-input"
        }
        onChange={onChange}
        value={inputValue}
        onBlur={onBlurHandle}
        onFocus={onFocusHandle}
        onInput={onInput}
      />
      {error && (
        <FormFeedback className="input-validation-error-messsage">
          {error}
        </FormFeedback>
      )}
    </FormGroup>
  ) : null;
};
CustomText.prototype = CustomTextProps;

export default CustomText;
